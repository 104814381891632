import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../../AppContextProvider ';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { ApiCall_GET } from '../../../Redux/Generic/action';
import { connect } from 'react-redux';

function CreateCouple({ ApiCall_GET }) {

    const theme = useAppContext().themeDetails;
    const [searchText, setsearchText] = useState(false);
    const [person, setperson] = useState([]);
    useEffect(() => {
        // ApiCall_GET(`TreatmentPlan/SeacrhCFCCouple/1/` + searchText?.trim() + `/` + 1 + `/` + 25, true).then(function (payload) {
        //     // person(payload);
        // });
    }, [])
    const testfun = () => {
        ApiCall_GET(`TreatmentPlan/SeacrhCFCCouple/1/` + searchText + `/` + 1 + `/` + 25, true).then(function (payload) {
            setperson([payload]);
        });
    }
    let navigate = useNavigate();
    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: '26px!important', // Set your desired height for the Select component
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '10px', // Set your desired font size for the options 
            // height:'12px'
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '10px', // Set your desired font size for the single value
        }),
        multiValue: (provided) => ({
            ...provided,
            fontSize: '10px', // Set your desired font size for the multi value
            margin: '0px',
            padding: '0px'
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: '10px', // Set your desired font size for the placeholder
            // height: '20px', // Set your desired height for the placeholder
        }),
        input: (provided) => ({
            ...provided,
            // fontSize: '10px', // Set your desired font size for the placeholder
            height: '20px', // Set your desired height for the placeholder
            margin: '0px'
        }),

    };
    return (
        <div className='w-full  h-full p-1 '>
            <div className={'w-full h-full p-2 bg-white rounded '}>
                <div className={theme.bg_Light + ' flex items-between p-0.5  pl-2 rounded shadow '}>
                    <div className='w-2/5 text-left'>
                        <label className={theme.txt_color + ' pl-2text-base font-medium '}>Couple Management</label>
                        <label className={theme.txt_color + ' ml-2 text-base font-medium '}> </label>
                    </div>
                    <div className='w-3/5 text-right flex justify-end'>
                        <button onClick={() => navigate('/home')} className="flex items-center cursor-pointer justify-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:text-black hover:bg-yellow-400 focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2">
                            <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.15716 0.607836C5.26072 0.711393 5.3125 0.834005 5.3125 0.975672C5.3125 1.11734 5.26072 1.23981 5.15716 1.34309L2.12293 4.37732L5.16752 7.42191C5.26417 7.51856 5.3125 7.63938 5.3125 7.78436C5.3125 7.92934 5.26072 8.05361 5.15716 8.15716C5.05361 8.26072 4.93099 8.3125 4.78933 8.3125C4.64766 8.3125 4.52519 8.26072 4.42191 8.15716L0.942381 4.66728C0.900958 4.62586 0.871548 4.58098 0.85415 4.53266C0.836753 4.48433 0.828192 4.43255 0.828468 4.37732C0.828468 4.32209 0.837167 4.27031 0.854564 4.22199C0.871962 4.17366 0.901234 4.12878 0.942381 4.08736L4.43226 0.59748C4.52892 0.500827 4.64808 0.4525 4.78974 0.4525C4.93141 0.4525 5.05388 0.504279 5.15716 0.607836Z" fill="#52575C" />
                            </svg>
                            <label className='pl-2 cursor-pointer'> Back to ART</label>
                        </button>
                    </div>
                </div>

                {/* Tab bar Section */}
                <div className={' p-2 mt-1 flex items-center bg-white'}>
                    <div className={' w-1/2 mr-4 font-bold cursor-pointer '}> <label className=' border-b-[3px] border-b-amber-500'>Add Relationship</label> </div>
                    <div className={' w-1/2 mr-4 font-bold cursor-pointer '}><label className=' border-b-[3px] border-b-amber-500'>Person's Details </label></div>
                </div>
                {/* Tab Body  */}
                <div className='flex p-2 gap-2'>
                    <div className={theme.tableBg + ` w-1/2 flex justify-between items-center  mb-1 py-4 px-2 border rounded-md`}>
                        <div className='w-2/5 text-left pr-2 gap-y-2  justify-between items-center'>
                            <div className='w-full text-left py-2   justify-between'>
                                <p className={" text-sm font-medium  "} >Person 1 User Code</p>
                                <Select
                                    // defaultValue={selectedOptions}
                                    name="colors"
                                    // options={adminTemplateList}
                                    className="w-full rounded-lg pt-2 px-2"
                                    classNamePrefix="select"
                                    placeholder="Select Default Template"
                                    // onChange={(e) => handleddlChange(e, true)}
                                    styles={customStyles}
                                />
                                {/* <input type='text' name='patient'
                                    // onChange={(e) => handleInputChange(  e.target.value)}  
                                    className={theme.cheqColor + ' shadow border w-full rounded rounded-md  py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} /> */}
                            </div>
                            <div className='w-full   justify-between'>
                                <p className={" text-sm font-medium  "} >Person 1 User Code</p>
                                <Select
                                    // defaultValue={selectedOptions}
                                    name="colors"
                                    // options={adminTemplateList}
                                    className="w-full rounded-lg pt-2 px-2"
                                    classNamePrefix="select"
                                    placeholder="Select Default Template"
                                    // onChange={(e) => handleddlChange(e, true)}
                                    styles={customStyles}
                                />
                                {/* <input type='text' name='partner'
                                    // onChange={(e) => handleInputChange(  e.target.value)}
                                    //   
                                    className={theme.cheqColor + ' shadow border w-full rounded rounded-md  py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                            */}
                            </div>
                        </div >
                        <div className='w-1/5  pr-2 mt-4 pt-4  items-center justify-between'>
                            {/* <svg width="73" height="55" viewBox="0 0 73 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M59.8862 42.7764L73 29.6605L73 25.3395L59.8862 12.2175L55.5658 16.5385L62.2388 23.2187L56.0119 23.2187L56.0119 23.0965L53.2253 23.0965L53.2192 23.2492L51.2699 23.2859C50.1251 23.3063 48.9559 23.4082 47.7623 23.5915C46.5686 23.7749 45.375 24.0132 44.1813 24.3066C42.9877 24.6 41.7105 25.0074 40.3498 25.5289C38.0359 26.4742 35.9358 27.6253 34.0496 28.9821C32.1633 30.3389 30.3973 31.7956 28.7515 33.352C27.1056 34.9085 25.4822 36.4568 23.8811 37.997C22.2801 39.529 20.5731 40.9164 18.7602 42.1591C16.9474 43.4019 14.9654 44.4083 12.8144 45.1783C10.6715 45.9444 8.20073 46.3274 5.40197 46.3274L-2.02503e-06 46.3274L-2.40413e-06 55L5.40197 55C8.2632 54.9945 11.114 54.6539 13.896 53.9854C16.2394 53.4078 18.4986 52.5306 20.6179 51.3757C22.5525 50.3127 24.387 49.0772 26.0994 47.6842C27.7452 46.3274 29.3218 44.9155 30.8291 43.4487C32.3324 41.9819 33.8357 40.5701 35.3389 39.2132C36.8402 37.8606 38.444 36.6264 40.1359 35.5217C41.8715 34.3946 43.7499 33.5046 45.7212 32.8753C47.9586 32.1941 50.288 31.8643 52.6265 31.8974L56.0119 31.8974L56.0119 31.8913L62.1227 31.8913L55.5597 38.4554L59.8862 42.7764ZM28.7515 21.6418C29.4822 22.3352 30.2342 23.0058 31.0064 23.6526C33.204 22.1375 35.5604 20.8668 38.0338 19.8633L38.1683 19.8205C38.7001 19.6163 39.2359 19.4227 39.7754 19.2399C38.211 18.2009 36.7322 17.0499 35.3389 15.7868C33.8357 14.4299 32.3324 13.0181 30.8291 11.5513C29.3218 10.0845 27.7452 8.67263 26.0994 7.31581C24.4535 5.95899 22.6284 4.72849 20.6241 3.62429C18.5009 2.46839 16.2375 1.59125 13.8899 1.01456C11.1098 0.346523 8.26116 0.00602229 5.40197 -2.9548e-06L0 -3.19093e-06L-3.7936e-07 8.67874L5.40197 8.67874C8.19666 8.67874 10.6675 9.06175 12.8144 9.82776C14.9654 10.5938 16.9474 11.5981 18.7602 12.8409C20.5731 14.0795 22.2801 15.4689 23.8811 17.0091L28.7515 21.6541L28.7515 21.6418Z" fill="#3A7555" />
                            </svg> */}
                            <svg width="87" height="66" viewBox="0 0 87 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M71.3712 51.3317L87 35.5926L87 30.4074L71.3712 14.661L66.2222 19.8462L74.175 27.8624L66.7539 27.8624L66.7539 27.7157L63.4329 27.7157L63.4257 27.8991L61.1025 27.9431C59.7382 27.9675 58.3447 28.0898 56.9221 28.3098C55.4996 28.5298 54.077 28.8159 52.6544 29.1679C51.2319 29.5199 49.7098 30.0089 48.0881 30.6347C45.3304 31.7691 42.8276 33.1503 40.5796 34.7785C38.3317 36.4067 36.2269 38.1547 34.2654 40.0224C32.3039 41.8902 30.3692 43.7482 28.4611 45.5964C26.553 47.4348 24.5187 49.0997 22.3581 50.591C20.1975 52.0822 17.8355 53.2899 15.272 54.214C12.7181 55.1332 9.77348 55.5928 6.43797 55.5928L-3.9277e-06 55.5928L-4.38261e-06 66L6.43797 66C9.84793 65.9933 13.2454 65.5847 16.561 64.7825C19.3538 64.0893 22.0463 63.0367 24.5721 61.6508C26.8776 60.3753 29.064 58.8926 31.1047 57.221C33.0662 55.5928 34.9452 53.8986 36.7416 52.1385C38.5331 50.3783 40.3247 48.6841 42.1163 47.0559C43.9054 45.4328 45.8168 43.9517 47.8332 42.6261C49.9016 41.2735 52.1403 40.2055 54.4897 39.4504C57.1561 38.633 59.9323 38.2371 62.7192 38.2769L66.7539 38.2769L66.7539 38.2696L74.0367 38.2696L66.215 46.1465L71.3712 51.3317ZM34.2654 25.9702C35.1363 26.8022 36.0325 27.6069 36.9528 28.3831C39.5718 26.5649 42.3802 25.0402 45.328 23.836L45.4882 23.7846C46.122 23.5396 46.7606 23.3073 47.4036 23.0879C45.5392 21.8411 43.7767 20.4598 42.1163 18.9441C40.3247 17.3159 38.5331 15.6217 36.7416 13.8615C34.9452 12.1013 33.0662 10.4072 31.1047 8.77897C29.1432 7.15079 26.9681 5.67418 24.5794 4.34915C22.049 2.96207 19.3515 1.9095 16.5537 1.21747C13.2405 0.415827 9.8455 0.00722677 6.43797 -3.52148e-06L-1.49766e-06 -3.80289e-06L-1.95289e-06 10.4145L6.43797 10.4145C9.76862 10.4145 12.7133 10.8741 15.272 11.7933C17.8355 12.7125 20.1976 13.9178 22.3581 15.409C24.5187 16.8954 26.553 18.5627 28.4611 20.4109L34.2654 25.9849L34.2654 25.9702Z" fill="#3A7555" />
                            </svg>
                        </div >
                        <div className='w-2/5 text-left pr-2   justify-between items-center'>
                            <p className={" text-sm font-medium py-1 "} >Couple Code</p>
                            <input type='text' name='patient'
                                disabled
                                // onChange={(e) => handleInputChange(  e.target.value)} 
                                className={theme.cheqColor + ' shadow border w-full rounded rounded-md  py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                        </div >
                    </div >
                    <div className={theme.tableBg + ` w-1/2 flex flex-wrap  mb-1 py-4 px-2 border rounded-md`}>
                        <input type='text' name='patient'
                            placeholder='Search'
                            onChange={(e) => setsearchText(e.target.value)}
                            className={theme.cheqColor + ' shadow border w-full rounded rounded-md  py-2 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />

                        {person && person.map((x) => {
                            <div className="w-full flex p-2 h-12 rounded-xl mt-0.5 justify-start items-center bg-white">
                                <div className="flex-none ">
                                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.07143 8.00558C7.11335 8.00558 8.1126 7.59168 8.84935 6.85493C9.5861 6.11818 10 5.11893 10 4.07701C10 3.03509 9.5861 2.03584 8.84935 1.29909C8.1126 0.56234 7.11335 0.148438 6.07143 0.148438C5.02951 0.148438 4.03026 0.56234 3.29351 1.29909C2.55676 2.03584 2.14286 3.03509 2.14286 4.07701C2.14286 5.11893 2.55676 6.11818 3.29351 6.85493C4.03026 7.59168 5.02951 8.00558 6.07143 8.00558ZM15 8.00558C15.7578 8.00558 16.4845 7.70456 17.0203 7.16874C17.5561 6.63293 17.8571 5.9062 17.8571 5.14844C17.8571 4.39068 17.5561 3.66395 17.0203 3.12813C16.4845 2.59231 15.7578 2.29129 15 2.29129C14.2422 2.29129 13.5155 2.59231 12.9797 3.12813C12.4439 3.66395 12.1429 4.39068 12.1429 5.14844C12.1429 5.9062 12.4439 6.63293 12.9797 7.16874C13.5155 7.70456 14.2422 8.00558 15 8.00558ZM2.14286 9.43415C1.57454 9.43415 1.02949 9.65992 0.627628 10.0618C0.225765 10.4636 0 11.0087 0 11.577V11.6842C0 11.6842 0 15.8627 6.07143 15.8627C12.1429 15.8627 12.1429 11.6842 12.1429 11.6842V11.577C12.1429 11.0087 11.9171 10.4636 11.5152 10.0618C11.1134 9.65992 10.5683 9.43415 10 9.43415H2.14286ZM15 14.4342C13.96 14.4342 13.1371 14.2927 12.485 14.0677C12.7936 13.5677 12.9707 13.0856 13.0707 12.6963C13.1436 12.4165 13.1898 12.1305 13.2086 11.842C13.21 11.8134 13.2114 11.7877 13.2114 11.7656L13.2121 11.7356V11.577C13.2134 10.7863 12.9221 10.023 12.3943 9.43415H18.2807C18.5065 9.43406 18.73 9.47844 18.9386 9.56477C19.1472 9.65109 19.3367 9.77767 19.4964 9.93726C19.656 10.0969 19.7827 10.2863 19.8691 10.4949C19.9555 10.7034 20 10.927 20 11.1527C20 11.1527 20 14.4342 15 14.4342Z" fill="#3e88ca">
                                        </path></svg>
                                </div>
                                <div className=" px-2">
                                    <p className="text-sm font-medium  leading-3 pb-1 text-black">{x.SurName}SurName100034, Addison</p>
                                    <p className="text-sm  font-medium leading-3 text-gray-500">SurName100035, Brooklyn</p>
                                </div>
                                <div className=" px-2 ">
                                    <p className="text-sm font-medium  leading-3 pt-1 text-black">
                                        <span className="text-gray-300">DOB </span> 10-Oct-2003</p>
                                    <p className="text-sm font-medium  leading-3 py-1 text-black ">
                                        <span className="text-gray-300">Person Code </span> 10-Oct-2003</p>
                                </div>
                                <div className="pl-4 justify-center ">
                                    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.9895 0.0724999V11H10.7545V4.715C10.7545 4.57 10.757 4.4125 10.762 4.2425C10.772 4.0725 10.787 3.9 10.807 3.725L7.90451 9.3425C7.81451 9.5175 7.69201 9.65 7.53701 9.74C7.38701 9.83 7.21451 9.875 7.01951 9.875H6.67451C6.47951 9.875 6.30451 9.83 6.14951 9.74C5.99951 9.65 5.87951 9.5175 5.78951 9.3425L2.88701 3.71C2.90201 3.89 2.91451 4.0675 2.92451 4.2425C2.93451 4.4125 2.93951 4.57 2.93951 4.715V11H0.70451V0.0724999H2.63951C2.74951 0.0724999 2.84451 0.0749997 2.92451 0.0799993C3.00951 0.0849996 3.08451 0.0999997 3.14951 0.125C3.21451 0.15 3.27201 0.19 3.32201 0.245C3.37701 0.295 3.42951 0.3675 3.47951 0.462499L6.29201 6.005C6.39201 6.195 6.48701 6.3925 6.57701 6.5975C6.67201 6.8025 6.76201 7.0125 6.84701 7.2275C6.93201 7.0075 7.01951 6.7925 7.10951 6.5825C7.20451 6.3725 7.30451 6.1725 7.40951 5.9825L10.2145 0.462499C10.2645 0.3675 10.3145 0.295 10.3645 0.245C10.4195 0.19 10.4795 0.15 10.5445 0.125C10.6095 0.0999997 10.682 0.0849996 10.762 0.0799993C10.847 0.0749997 10.9445 0.0724999 11.0545 0.0724999H12.9895Z" fill="#558EFF" fillOpacity="0.5" />
                                    </svg>
                                </div>
                            </div>
                        })}
                        {/* {person && <div className="w-full flex p-2 h-12 rounded-xl mt-0.5 justify-start items-center bg-white">
                            <div className="flex-none ">
                                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.07143 8.00558C7.11335 8.00558 8.1126 7.59168 8.84935 6.85493C9.5861 6.11818 10 5.11893 10 4.07701C10 3.03509 9.5861 2.03584 8.84935 1.29909C8.1126 0.56234 7.11335 0.148438 6.07143 0.148438C5.02951 0.148438 4.03026 0.56234 3.29351 1.29909C2.55676 2.03584 2.14286 3.03509 2.14286 4.07701C2.14286 5.11893 2.55676 6.11818 3.29351 6.85493C4.03026 7.59168 5.02951 8.00558 6.07143 8.00558ZM15 8.00558C15.7578 8.00558 16.4845 7.70456 17.0203 7.16874C17.5561 6.63293 17.8571 5.9062 17.8571 5.14844C17.8571 4.39068 17.5561 3.66395 17.0203 3.12813C16.4845 2.59231 15.7578 2.29129 15 2.29129C14.2422 2.29129 13.5155 2.59231 12.9797 3.12813C12.4439 3.66395 12.1429 4.39068 12.1429 5.14844C12.1429 5.9062 12.4439 6.63293 12.9797 7.16874C13.5155 7.70456 14.2422 8.00558 15 8.00558ZM2.14286 9.43415C1.57454 9.43415 1.02949 9.65992 0.627628 10.0618C0.225765 10.4636 0 11.0087 0 11.577V11.6842C0 11.6842 0 15.8627 6.07143 15.8627C12.1429 15.8627 12.1429 11.6842 12.1429 11.6842V11.577C12.1429 11.0087 11.9171 10.4636 11.5152 10.0618C11.1134 9.65992 10.5683 9.43415 10 9.43415H2.14286ZM15 14.4342C13.96 14.4342 13.1371 14.2927 12.485 14.0677C12.7936 13.5677 12.9707 13.0856 13.0707 12.6963C13.1436 12.4165 13.1898 12.1305 13.2086 11.842C13.21 11.8134 13.2114 11.7877 13.2114 11.7656L13.2121 11.7356V11.577C13.2134 10.7863 12.9221 10.023 12.3943 9.43415H18.2807C18.5065 9.43406 18.73 9.47844 18.9386 9.56477C19.1472 9.65109 19.3367 9.77767 19.4964 9.93726C19.656 10.0969 19.7827 10.2863 19.8691 10.4949C19.9555 10.7034 20 10.927 20 11.1527C20 11.1527 20 14.4342 15 14.4342Z" fill="#3e88ca">
                                    </path></svg>
                            </div>
                            <div className=" px-2">
                                <p className="text-sm font-medium  leading-3 pb-1 text-black">SurName100034, Addison</p>
                                <p className="text-sm  font-medium leading-3 text-gray-500">SurName100035, Brooklyn</p>
                            </div>
                            <div className=" px-2 ">
                                <p className="text-sm font-medium  leading-3 pt-1 text-black">
                                    <span className="text-gray-300">DOB </span> 10-Oct-2003</p>
                                <p className="text-sm font-medium  leading-3 py-1 text-black ">
                                    <span className="text-gray-300">Person Code </span> 10-Oct-2003</p>
                            </div>
                            <div className="pl-4 justify-center ">
                                <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.9895 0.0724999V11H10.7545V4.715C10.7545 4.57 10.757 4.4125 10.762 4.2425C10.772 4.0725 10.787 3.9 10.807 3.725L7.90451 9.3425C7.81451 9.5175 7.69201 9.65 7.53701 9.74C7.38701 9.83 7.21451 9.875 7.01951 9.875H6.67451C6.47951 9.875 6.30451 9.83 6.14951 9.74C5.99951 9.65 5.87951 9.5175 5.78951 9.3425L2.88701 3.71C2.90201 3.89 2.91451 4.0675 2.92451 4.2425C2.93451 4.4125 2.93951 4.57 2.93951 4.715V11H0.70451V0.0724999H2.63951C2.74951 0.0724999 2.84451 0.0749997 2.92451 0.0799993C3.00951 0.0849996 3.08451 0.0999997 3.14951 0.125C3.21451 0.15 3.27201 0.19 3.32201 0.245C3.37701 0.295 3.42951 0.3675 3.47951 0.462499L6.29201 6.005C6.39201 6.195 6.48701 6.3925 6.57701 6.5975C6.67201 6.8025 6.76201 7.0125 6.84701 7.2275C6.93201 7.0075 7.01951 6.7925 7.10951 6.5825C7.20451 6.3725 7.30451 6.1725 7.40951 5.9825L10.2145 0.462499C10.2645 0.3675 10.3145 0.295 10.3645 0.245C10.4195 0.19 10.4795 0.15 10.5445 0.125C10.6095 0.0999997 10.682 0.0849996 10.762 0.0799993C10.847 0.0749997 10.9445 0.0724999 11.0545 0.0724999H12.9895Z" fill="#558EFF" fillOpacity="0.5" />
                                </svg>
                            </div>
                        </div>} */}
                        <div className="w-full flex p-2 h-12 rounded-xl mt-0.5 justify-start items-center bg-white">
                            <div className="flex-none ">
                                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.07143 8.00558C7.11335 8.00558 8.1126 7.59168 8.84935 6.85493C9.5861 6.11818 10 5.11893 10 4.07701C10 3.03509 9.5861 2.03584 8.84935 1.29909C8.1126 0.56234 7.11335 0.148438 6.07143 0.148438C5.02951 0.148438 4.03026 0.56234 3.29351 1.29909C2.55676 2.03584 2.14286 3.03509 2.14286 4.07701C2.14286 5.11893 2.55676 6.11818 3.29351 6.85493C4.03026 7.59168 5.02951 8.00558 6.07143 8.00558ZM15 8.00558C15.7578 8.00558 16.4845 7.70456 17.0203 7.16874C17.5561 6.63293 17.8571 5.9062 17.8571 5.14844C17.8571 4.39068 17.5561 3.66395 17.0203 3.12813C16.4845 2.59231 15.7578 2.29129 15 2.29129C14.2422 2.29129 13.5155 2.59231 12.9797 3.12813C12.4439 3.66395 12.1429 4.39068 12.1429 5.14844C12.1429 5.9062 12.4439 6.63293 12.9797 7.16874C13.5155 7.70456 14.2422 8.00558 15 8.00558ZM2.14286 9.43415C1.57454 9.43415 1.02949 9.65992 0.627628 10.0618C0.225765 10.4636 0 11.0087 0 11.577V11.6842C0 11.6842 0 15.8627 6.07143 15.8627C12.1429 15.8627 12.1429 11.6842 12.1429 11.6842V11.577C12.1429 11.0087 11.9171 10.4636 11.5152 10.0618C11.1134 9.65992 10.5683 9.43415 10 9.43415H2.14286ZM15 14.4342C13.96 14.4342 13.1371 14.2927 12.485 14.0677C12.7936 13.5677 12.9707 13.0856 13.0707 12.6963C13.1436 12.4165 13.1898 12.1305 13.2086 11.842C13.21 11.8134 13.2114 11.7877 13.2114 11.7656L13.2121 11.7356V11.577C13.2134 10.7863 12.9221 10.023 12.3943 9.43415H18.2807C18.5065 9.43406 18.73 9.47844 18.9386 9.56477C19.1472 9.65109 19.3367 9.77767 19.4964 9.93726C19.656 10.0969 19.7827 10.2863 19.8691 10.4949C19.9555 10.7034 20 10.927 20 11.1527C20 11.1527 20 14.4342 15 14.4342Z" fill="#3e88ca">
                                    </path></svg>
                            </div>
                            <div className=" px-2">
                                <p className="text-sm font-medium  leading-3 pb-1 text-black">SurName100034, Addison</p>
                                <p className="text-sm  font-medium leading-3 text-gray-500">SurName100035, Brooklyn</p>
                            </div>
                            <div className=" px-2 ">
                                <p className="text-sm font-medium  leading-3 pt-1 text-black">
                                    <span className="text-gray-300">DOB </span> 10-Oct-2003</p>
                                <p className="text-sm font-medium  leading-3 py-1 text-black ">
                                    <span className="text-gray-300">Person Code </span> 10-Oct-2003</p>
                            </div>
                            <div className="pl-4 justify-center ">
                                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.20617 2.0225V4.79H6.94117V6.7475H3.20617V11H0.656171V0.0724999H7.69117V2.0225H3.20617Z" fill="#FE8787" />
                                </svg>
                            </div>
                        </div>
                        <div className="w-full flex p-2 h-12 rounded-xl mt-0.5 justify-start items-center bg-white">
                            <div className="flex-none ">
                                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.07143 8.00558C7.11335 8.00558 8.1126 7.59168 8.84935 6.85493C9.5861 6.11818 10 5.11893 10 4.07701C10 3.03509 9.5861 2.03584 8.84935 1.29909C8.1126 0.56234 7.11335 0.148438 6.07143 0.148438C5.02951 0.148438 4.03026 0.56234 3.29351 1.29909C2.55676 2.03584 2.14286 3.03509 2.14286 4.07701C2.14286 5.11893 2.55676 6.11818 3.29351 6.85493C4.03026 7.59168 5.02951 8.00558 6.07143 8.00558ZM15 8.00558C15.7578 8.00558 16.4845 7.70456 17.0203 7.16874C17.5561 6.63293 17.8571 5.9062 17.8571 5.14844C17.8571 4.39068 17.5561 3.66395 17.0203 3.12813C16.4845 2.59231 15.7578 2.29129 15 2.29129C14.2422 2.29129 13.5155 2.59231 12.9797 3.12813C12.4439 3.66395 12.1429 4.39068 12.1429 5.14844C12.1429 5.9062 12.4439 6.63293 12.9797 7.16874C13.5155 7.70456 14.2422 8.00558 15 8.00558ZM2.14286 9.43415C1.57454 9.43415 1.02949 9.65992 0.627628 10.0618C0.225765 10.4636 0 11.0087 0 11.577V11.6842C0 11.6842 0 15.8627 6.07143 15.8627C12.1429 15.8627 12.1429 11.6842 12.1429 11.6842V11.577C12.1429 11.0087 11.9171 10.4636 11.5152 10.0618C11.1134 9.65992 10.5683 9.43415 10 9.43415H2.14286ZM15 14.4342C13.96 14.4342 13.1371 14.2927 12.485 14.0677C12.7936 13.5677 12.9707 13.0856 13.0707 12.6963C13.1436 12.4165 13.1898 12.1305 13.2086 11.842C13.21 11.8134 13.2114 11.7877 13.2114 11.7656L13.2121 11.7356V11.577C13.2134 10.7863 12.9221 10.023 12.3943 9.43415H18.2807C18.5065 9.43406 18.73 9.47844 18.9386 9.56477C19.1472 9.65109 19.3367 9.77767 19.4964 9.93726C19.656 10.0969 19.7827 10.2863 19.8691 10.4949C19.9555 10.7034 20 10.927 20 11.1527C20 11.1527 20 14.4342 15 14.4342Z" fill="#3e88ca">
                                    </path></svg>
                            </div>
                            <div className=" px-2">
                                <p className="text-sm font-medium  leading-3 pb-1 text-black">SurName100034, Addison</p>
                                <p className="text-sm  font-medium leading-3 text-gray-500">SurName100035, Brooklyn</p>
                            </div>
                            <div className=" px-2 ">
                                <p className="text-sm font-medium  leading-3 pt-1 text-black">
                                    <span className="text-gray-300">DOB </span> 10-Oct-2003</p>
                                <p className="text-sm font-medium  leading-3 py-1 text-black ">
                                    <span className="text-gray-300">Person Code </span> 10-Oct-2003</p>
                            </div>
                            <div className="pl-4 justify-center ">
                                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.20617 2.0225V4.79H6.94117V6.7475H3.20617V11H0.656171V0.0724999H7.69117V2.0225H3.20617Z" fill="#FE8787" />
                                </svg>
                            </div>
                        </div>
                        <button onClick={() => testfun()}>test</button>
                    </div >


                </div >
                <div className=' py-2 rounded flex justify-end items-center border px-6'>
                    <button className="bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded"
                    > Cancel  </button>
                    <button className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded"
                    >  Confirm</button>
                </div>
            </div >
        </div >
    )
}

// export default CreateCouple

const mapStateToProps = (state) => ({
})
export default connect(mapStateToProps, {
    ApiCall_GET
})(CreateCouple);