import React from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router';

export default function CreateTemplate() {
    let navigate = useNavigate();
    const fromAddTemplate = "addTemplet"
    useEffect(() => {
        navigate('/updateTreatmentPlan', { state: { artid: null, coupleid: null, fromAddTemplate } });
    }, [])
    return (
        <div>Redirecting</div>
    )
}
