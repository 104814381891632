import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../AppContextProvider ';
import { getCorrectDate, getCorrectDateForInput, getCycleTypeColor, getTextColor, replaceEmptyObjectsWithNull } from '../../../../common';
import { getSession } from '../../../../session';
import { ApiCall_GET, ApiCall_POST } from '../../../../Redux/Generic/action';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import CoupleElectronicInfo from './CoupleElectronicInfo';

export const ElectronicForm = ({ ApiCall_GET, ApiCall_POST, coupleId }) => {
    const theme = useAppContext().themeDetails;
    const { formData, setFormData } = useState('')
    const [activeStep, setActiveStep] = useState(1);
    const [popUPImport, setPopUPImport] = useState(false);
    const [elecFormdata, setElecFormdata] = useState();
    const [patientPortalForm, setPatientPortalForm] = useState([]);
    const [electronicRegistrationForm, setElectronicRegistrationForm] = useState({});
    const sessionUser = getSession('user');
    const handleInputChange = () => {
        // GetElectronicRegistrationForm
        // GetPatientPortalForms
        // GetFormInformationAndRevisions
    }
    const [xmlData, setXmlData] = useState(null);
    useEffect(() => {
        ApiCall_GET('ElectronicForms/GetElectronicRegistrationForm/' + coupleId, true).then(function (payload) {
            setElecFormdata([replaceEmptyObjectsWithNull(payload)])
            setXmlData(payload);
            

        });
        ApiCall_GET('ElectronicForms/GetPatientPortalForms/' + coupleId, true).then(function (payload) {
            setPatientPortalForm(replaceEmptyObjectsWithNull(payload))

            

        });
        ApiCall_GET('ElectronicForms/GetFormInformationAndRevisions/' + coupleId, true).then(function (payload) {

            

        });
    }, [])
    const handleClick = (formData) => {


        setXmlData(formData);

    };

    

    return (
        <div className='w-full  h-full pb-3'>
            <div className=' relative  flex px-2 items-center bg-yellow-50 rounded'>
                <span className=" animate-ping  absolute inline-flex rounded-full h-4 w-4 bg-red-500"></span>
                <svg className={" "} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.28867 0.9255C6.94767 -0.3085 8.71567 -0.3085 9.37467 0.9255L15.4567 12.3035C15.5992 12.5701 15.6702 12.8691 15.6628 13.1713C15.6554 13.4735 15.5699 13.7687 15.4145 14.028C15.2591 14.2873 15.0392 14.502 14.7761 14.651C14.5131 14.8001 14.216 14.8784 13.9137 14.8785H1.74967C1.44736 14.8784 1.15021 14.8001 0.887198 14.651C0.624182 14.502 0.404264 14.2873 0.248873 14.028C0.0934829 13.7687 0.00792056 13.4735 0.000523976 13.1713C-0.00687261 12.8691 0.0641489 12.5701 0.206668 12.3035L6.28867 0.9255ZM8.05167 1.6325C8.03025 1.59281 7.99849 1.55966 7.95976 1.53655C7.92103 1.51344 7.87677 1.50124 7.83167 1.50124C7.78657 1.50124 7.74231 1.51344 7.70358 1.53655C7.66485 1.55966 7.63309 1.59281 7.61167 1.6325L1.52967 13.0105C1.50929 13.0486 1.49912 13.0912 1.50014 13.1344C1.50116 13.1776 1.51334 13.2197 1.53549 13.2568C1.55764 13.2938 1.58901 13.3245 1.62654 13.3459C1.66408 13.3672 1.7065 13.3784 1.74967 13.3785H13.9137C13.9568 13.3784 13.9993 13.3672 14.0368 13.3459C14.0743 13.3245 14.1057 13.2938 14.1278 13.2568C14.15 13.2197 14.1622 13.1776 14.1632 13.1344C14.1642 13.0912 14.154 13.0486 14.1337 13.0105L8.05167 1.6325ZM8.58167 5.6285V8.1285C8.58167 8.32741 8.50265 8.51818 8.362 8.65883C8.22134 8.79948 8.03058 8.8785 7.83167 8.8785C7.63276 8.8785 7.44199 8.79948 7.30134 8.65883C7.16069 8.51818 7.08167 8.32741 7.08167 8.1285V5.6285C7.08167 5.42959 7.16069 5.23882 7.30134 5.09817C7.44199 4.95752 7.63276 4.8785 7.83167 4.8785C8.03058 4.8785 8.22134 4.95752 8.362 5.09817C8.50265 5.23882 8.58167 5.42959 8.58167 5.6285ZM8.83167 10.8785C8.83167 11.1437 8.72631 11.3981 8.53877 11.5856C8.35124 11.7731 8.09688 11.8785 7.83167 11.8785C7.56645 11.8785 7.3121 11.7731 7.12456 11.5856C6.93703 11.3981 6.83167 11.1437 6.83167 10.8785C6.83167 10.6133 6.93703 10.3589 7.12456 10.1714C7.3121 9.98386 7.56645 9.8785 7.83167 9.8785C8.09688 9.8785 8.35124 9.98386 8.53877 10.1714C8.72631 10.3589 8.83167 10.6133 8.83167 10.8785Z" fill="#A91111" />
                </svg>
                <label className={'text-red-700 text-xl font-bold pl-2'}>Under Construction</label>

            </div>
            <div className='w-full flex h-full gap-x-2'>
                <div className={' w-1/5 flex flex-col items-center  rounded'}>
                    <div className='w-full '>
                        <label className={'ml-2 font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500'}>Electronic Forms</label>
                    </div>
                    <div className={' w-full flex flex-col items-center rounded-md border mt-1 '}>
                        <div className={' flex p-1 items-center text-white rounded w-full   ' + theme.navbar}>
                            <p className='text-sm font-bold px-2 '>In-tray</p>
                        </div>
                        <p className='text-sm font-bold p-2 '>Detected no forms with matching data</p>
                        <button onClick={() => setPopUPImport(true)} className="flex items-center my-2 cursor-pointer justify-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:text-black hover:bg-yellow-400 focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2">
                            {/* <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.15716 0.607836C5.26072 0.711393 5.3125 0.834005 5.3125 0.975672C5.3125 1.11734 5.26072 1.23981 5.15716 1.34309L2.12293 4.37732L5.16752 7.42191C5.26417 7.51856 5.3125 7.63938 5.3125 7.78436C5.3125 7.92934 5.26072 8.05361 5.15716 8.15716C5.05361 8.26072 4.93099 8.3125 4.78933 8.3125C4.64766 8.3125 4.52519 8.26072 4.42191 8.15716L0.942381 4.66728C0.900958 4.62586 0.871548 4.58098 0.85415 4.53266C0.836753 4.48433 0.828192 4.43255 0.828468 4.37732C0.828468 4.32209 0.837167 4.27031 0.854564 4.22199C0.871962 4.17366 0.901234 4.12878 0.942381 4.08736L4.43226 0.59748C4.52892 0.500827 4.64808 0.4525 4.78974 0.4525C4.93141 0.4525 5.05388 0.504279 5.15716 0.607836Z" fill="currentColor" />
                        </svg> */}
                            <label className='pl-2 cursor-pointer'>Import forms / registration</label>
                        </button>
                        <div className={`${theme.thumb} ${theme.track}  ${theme.thin} flex flex-col p-1 items-center text-white rounded w-full h-32 overflow-y-scroll scrollbar scrollbar-thin`}>
                            <div className={' flex p-2 items-center text-white rounded w-full  ' + theme.navbar}>
                                <p className='text-sm font-bold px-2 '>Electronic Registration Form</p>
                            </div>


                            {elecFormdata?.map((item, index) => (
                                < div key={index} onClick={() => { handleClick(item) }} className={"w-full flex justify-center items-center  px-2 gap-x-2 mt-0.5 rounded  bg-[#DBE3DF] "}>
                                    <div className={`flex flex-col h-8 w-8 shadow rounded items-center justify-center `} style={{ backgroundColor: (getCycleTypeColor(item?.cycletype?.split(' ')[0].trim()) || 'gray') }}>
                                        <label className={` text-sm  font-semibold   text-${getTextColor(getCycleTypeColor(item?.cycletype?.replaceAll(' ', '')?.split('-')[0]) || 'gray')}`}>
                                            {item?.cycletype ? item?.cycletype == 'Ovulation Induction' ? 'OI' : item?.cycletype == "Egg-donor IVF Cycle" ? 'ED' : item?.cycletype?.split(' ')[0].trim() : ' - '}
                                        </label>
                                        <div className="rounded bg-white flex  items-center justify-center h-4 w-6 mb-1" >
                                            {item.formVersion && <label className={`  text-sm3 font-bold   text-gray-600`}>{item.formVersion} </label>}
                                        </div>
                                    </div>
                                    <div className={"py-1"}>
                                        <p className="text-sm text-gray-800 font-medium">{item?.formtype ? 'Electronic Forms' : xmlData?.formTypeName}</p>
                                        <p className="text-sm text-gray-800 font-medium">{item.completedate}</p>
                                    </div>

                                    {/* <div style={{ width: "20%" }} className={""}>
                                    <div className={"text-left  cursor-pointer items-center gap-x-3 p-1 rounded "}>
                                        <label className={" text-sm font-semibold  "}>
                                            Patient: {'item.PatientFirstname'}
                                        </label>
                                        <p className="text-sm text-gray-600 font-medium">
                                            DOB : {`getCorrectDate('item.PatientDOB')`}
                                        </p>
                                    </div>
                                </div> */}
                                </div>
                            ))}
                        </div>

                        <div className={' flex p-2 items-center text-white rounded-md w-full mt-2 ' + theme.navbar}>
                            <p className='text-sm font-bold px-2 '>Patient Portal Forms</p>
                        </div>
                        <div className={`${theme.thumb} ${theme.track}  ${theme.thin} flex flex-col p-1 items-center text-white rounded w-full h-40 overflow-y-scroll scrollbar scrollbar-thin`}>
                            {patientPortalForm?.map((item, index) => (

                                < div onClick={() => { handleClick(item) }} className={"w-full flex justify-center items-center  px-2 gap-x-2 mt-0.5 rounded  bg-[#DBE3DF] "}>
                                    <div className={`flex flex-col h-8 w-8 shadow rounded items-center justify-center `} style={{ backgroundColor: (getCycleTypeColor(item?.cycletype?.split(' ')[0].trim()) || 'gray') }}>
                                        <label className={` text-sm  font-semibold   text-${getTextColor(getCycleTypeColor(item?.cycletype?.replaceAll(' ', '')?.split('-')[0]) || 'gray')}`}>
                                            {item?.cycletype ? item?.cycletype == 'Ovulation Induction' ? 'OI' : item?.cycletype == "Egg-donor IVF Cycle" ? 'ED' : item?.cycletype?.split(' ')[0].trim() : ' - '}
                                        </label>
                                        <div className="rounded bg-white flex  items-center justify-center h-4 w-6 mb-1" >
                                            {item.formVersion && <label className={`  text-sm3 font-bold   text-gray-600`}>{item.formVersion} </label>}
                                        </div>
                                    </div>
                                    <div className={"w-4/5 py-1"}>
                                        <p className="text-sm text-gray-800 font-medium">{item.formTypeName}</p>
                                        <p className=" text-sm text-gray-800 font-medium">{item.addedon}</p>
                                    </div>


                                </div>


                            ))}
                        </div>
                    </div>
                </div>
                <div className={` ${theme.thumb} ${theme.track}  ${theme.thin} w-4/5 overflow-y-scroll scrollbar scrollbar-thin h-[73vh] '`}>
                    <div className='w-full '>
                        <label className={'ml-2 font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500'}>{xmlData?.formtype ? 'REGISTRATION Form' : xmlData?.formTypeName}{"  v"}{xmlData?.formVersion}</label>
                    </div>

                    {xmlData && (


                        <div className="w-full flex mt-1 gap-x-2 ">
                            <div className="max-w-6xl gap-y-1 w-4/5 mx-auto  bg-gray-100 ">
                                {/* <CoupleElectronicInfo xmlData={xmlData} /> */}
                                {/* 'bg-yellow-50 ' : "bg-[#ffc3c340] "} */}
                                <section className={`${xmlData.formtype ? 'bg-yellow-50' : "bg-[#f0f5ff] "} w-full border-b mb-1 shadow `}>
                                    <h2 className={`${xmlData?.formtype ? 'bg-yellow-400' : 'bg-blue-400 '} text-sm  font-medium py-1 px-2 rounded-t-md`}>
                                        FSF REGISTER YOUR INTEREST FORM
                                    </h2>

                                    <div className="grid grid-cols-4 gap-1 mt-1 p-2">
                                        <div>
                                            <label className="block text-sm  font-medium">Name</label>
                                            <p className=" text-xss text-gray-500 font-medium">{xmlData?.psurname}{xmlData?.patientSurname}{', ' + xmlData?.Firstname}</p>
                                        </div>
                                        <div>
                                            <label className="block text-sm  font-medium">Date of Birth</label>
                                            <p className=" text-xss text-gray-500 font-medium">{xmlData?.dob}</p>
                                        </div>
                                        <div>
                                            <label className="block text-sm  font-medium">Residential Address</label>
                                            <p className=" text-xss text-gray-500 font-medium">{xmlData?.address}NORTH LAKES QLD 4509 </p>
                                            {/* address 5 Nuroan Avenue FERNY HILLS QLD 4055 */}
                                        </div>
                                        <div>
                                            <label className="block text-sm  font-medium">Postal Address</label>
                                            <p className=" text-xss text-gray-500 font-medium">{xmlData?.paddress}NORTH LAKES QLD 4509  </p>
                                            {/* FERNY HILLS QLD 4055 */}
                                        </div>
                                        <div>
                                            <label className="block text-sm  font-medium">Phone</label>
                                            <p className=" text-xss text-gray-500 font-medium">{xmlData?.homephone}</p>
                                        </div>
                                        <div>
                                            <label className="block text-sm  font-medium">Mobile</label>
                                            <p className=" text-xss text-gray-500 font-medium">{xmlData?.mobilephone}</p>
                                        </div>
                                        <div>
                                            <label className="block text-sm  font-medium">Email</label>
                                            <p className=" text-xss text-gray-500 font-medium">{xmlData?.emailaddress}</p>
                                        </div>
                                        <div>
                                            <label className="block text-sm  font-medium">Preferred Clinic</label>
                                            <p className=" text-xss text-gray-500 font-medium">{xmlData?.patientDOB} FSF Northlakes, Northlakes</p>
                                        </div>
                                    </div>
                                </section>
                                <section className={`${xmlData.formtype ? 'bg-yellow-50' : "bg-[#f0f5ff] "} w-full border-b mb-1 shadow`}>
                                    <h2 className={`${xmlData?.formtype ? 'bg-yellow-400' : 'bg-blue-400 '} text-sm font-medium py-1 px-2 rounded-t-md`}>FSF REGISTRATION FORM</h2>
                                    <div className="flex flex-wrap">
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Name</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.psurname}{xmlData?.patientSurname}{', ' + xmlData?.Firstname}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Name </label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.surname}{xmlData?.patientSurname}{', ' + xmlData?.pFirstname}</p>
                                        </div>

                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Middle Name</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.middlename}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Middle Name </label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.pmiddlename}</p>
                                        </div>

                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Maiden Name</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.Maiden}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Maiden Name </label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.Maiden}</p>
                                        </div>

                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">DOB</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.dob}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">DOB </label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.pdob}</p>
                                        </div>

                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Gender at Birth</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.sex}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Gender at Birth </label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.psex}</p>
                                        </div>
                                    </div>
                                </section>
                                <section className={`${xmlData.formtype ? 'bg-yellow-50' : "bg-[#f0f5ff] "} w-full border-b mb-1 shadow`}>
                                    <h2 className={`${xmlData?.formtype ? 'bg-yellow-400' : 'bg-blue-400 '} text-sm font-medium py-1 px-2 rounded-t-md`}>Referring Family Doctor (GP)</h2>
                                    <div className="w-1/2">
                                        <label className="block text-sm font-medium">Name </label>
                                        <p className="text-xss text-gray-500 font-medium">{xmlData?.refgpname}</p>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block text-sm font-medium">Address </label>
                                        <p className="text-xss text-gray-500 font-medium">{xmlData?.refgpaddress}</p>
                                    </div>
                                </section>
                                <section className={`${xmlData.formtype ? 'bg-yellow-50' : "bg-[#f0f5ff] "} w-full border-b mb-1 shadow`}>
                                    <h2 className={`${xmlData?.formtype ? 'bg-yellow-400' : 'bg-blue-400 '} text-sm font-medium py-1 px-2 rounded-t-md`}>First Step Fertility Specialist</h2>
                                    <div className="w-1/2">
                                        <label className="block text-sm font-medium">Name </label>
                                        <p className="text-xss text-gray-500 font-medium">{xmlData?.refgpname}</p>
                                    </div>
                                </section>

                                {/* Special Needs Section */}
                                <section className={`${xmlData.formtype ? 'bg-yellow-50' : "bg-[#f0f5ff] "} w-full border-b mb-1 shadow `}>
                                    <h2 className={`${xmlData?.formtype ? 'bg-yellow-400' : 'bg-blue-400 '} text-sm  font-medium py-1 px-2 rounded-t-md`}>SPECIAL NEEDS</h2>
                                    <div className="pt-2 px-2 flex">
                                        <label className="block text-sm  font-medium">Patient is happy to receive communication re Marketing/media activities?</label>
                                        <p className=" text-xss text-gray-500 font-medium">Yes</p>
                                    </div>
                                    <div className=" px-2 flex">
                                        <label className="block text-sm  font-medium">Does either you or your partner (if applicable) have a disability?</label>
                                        <p className=" text-xss text-gray-500 font-medium">No</p>
                                    </div>
                                    <div className=" px-2 flex">
                                        <label className="block text-sm  font-medium">How did you hear about the clinic?</label>
                                        <p className=" text-xss text-gray-500 font-medium">{xmlData.howHear}</p>
                                    </div>
                                </section>

                                {/* Sexual Orientation Section */}
                                <section className={`${xmlData.formtype ? 'bg-yellow-50' : "bg-[#f0f5ff] "} w-full border-b mb-1 shadow`}>
                                    <h2 className={`${xmlData?.formtype ? 'bg-yellow-400' : 'bg-blue-400 '} text-sm font-medium py-1 px-2 rounded-t-md`}>SEXUAL ORIENTATION</h2>
                                    <div className="flex flex-wrap">
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Gender Identify As</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.psurname}{xmlData?.patientSurname}{', ' + xmlData?.Firstname}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Gender Identify As</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.surname}{xmlData?.patientSurname}{', ' + xmlData?.pFirstname}</p>
                                        </div>

                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Intersex</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.middlename}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Intersex</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.pmiddlename}</p>
                                        </div>

                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Sexual Orientation</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.Maiden}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Sexual Orientation</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.Maiden}</p>
                                        </div>

                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Pronouns</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.dob}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Pronouns</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.pdob}</p>
                                        </div>
                                    </div>
                                </section>
                                <section className={`${xmlData.formtype ? 'bg-yellow-50' : "bg-[#f0f5ff] "} w-full border-b mb-1 shadow`}>
                                    <h2 className={`${xmlData?.formtype ? 'bg-yellow-400' : 'bg-blue-400 '} text-sm font-medium py-1 px-2 rounded-t-md`}>CONTACT INFORMATION</h2>
                                    <div className="flex flex-wrap">
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Residential Address</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.address}NORTH LAKES 4509 </p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Residential Address</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.paddress}NORTH LAKES 4509 </p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Postal Address</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.postaddress}NORTH LAKES 4509 </p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Postal Address</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.ppostaddress}NORTH LAKES 4509 </p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Home Phone</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.homephone}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Home Phone</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.phomephone}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Work Phone</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.workphone}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Work Phone</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.pworkphone}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Mobile</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.mobilephone}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Mobile</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.pmobilephone}</p>
                                        </div>

                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Preferred Contact Method</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.Maiden}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Preferred Contact Method</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.Maiden}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Email</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.emailaddress}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Email</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.pemailaddress}</p>
                                        </div>

                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Work Place</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.pworksuburb}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Work Place</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.pworksuburb}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Emergency Contact</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.emergencyName}</p>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.emergencyNumber}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Emergency Contact</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.pemergencyName}</p>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.pemergencyNumber}</p>

                                        </div>
                                    </div>
                                </section>
                                <section className={`${xmlData.formtype ? 'bg-yellow-50' : "bg-[#f0f5ff] "} w-full border-b mb-1 shadow`}>
                                    <h2 className={`${xmlData?.formtype ? 'bg-yellow-400' : 'bg-blue-400 '} text-sm font-medium py-1 px-2 rounded-t-md`}>MEDICAL INFORMATION</h2>
                                    <div className="flex flex-wrap">
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Medicare</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.medicare} {xmlData?.medicareref} {xmlData?.medicareexpiry}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Medicare</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.pmedicare} {xmlData?.pmedicareref} {xmlData?.pmedicareexpiry}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Height</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.height}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Height</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.ppostaddress}NORTH LAKES 4509 </p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Weight</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.weight}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Weight</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.phomephone}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Known Allergies&</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.workphone}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Known Allergies&</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.pworkphone}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Occupation</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.Occupation}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <label className="block text-sm font-medium">Occupation</label>
                                            <p className="text-xss text-gray-500 font-medium">{xmlData?.pOccupation}</p>
                                        </div>
                                    </div>
                                </section>
                                {/* NATIONALITY INFORMATION */}
                                <section className={`${xmlData.formtype ? 'bg-yellow-50' : "bg-[#f0f5ff] "} w-full border-b mb-1 shadow `}>
                                    <h2 className={`${xmlData?.formtype ? 'bg-yellow-400' : 'bg-blue-400 '} text-sm  font-medium py-1 px-2 rounded-t-md`}>NATIONALITY INFORMATION</h2>
                                    <div className="p-2 grid grid-cols-2 gap-4">
                                        <div>
                                            <label className="block text-sm  font-medium">Country of Birth</label>
                                            <p className=" text-xss text-gray-500 font-medium">{xmlData?.birthcountry}</p>
                                        </div>
                                        <div>
                                            <label className="block text-sm  font-medium">Country of Residence </label>
                                            <p className=" text-xss text-gray-500 font-medium">{xmlData?.formtype}</p>
                                        </div>
                                        <div>
                                            <label className="block text-sm  font-medium">IntersAus/NZ Citizen ex</label>
                                            <p className=" text-xss text-gray-500 font-medium">{xmlData?.australianCitizen}</p>
                                        </div>
                                        <div>
                                            <label className="block text-sm  font-medium">Aboriginal</label>
                                            <p className=" text-xss text-gray-500 font-medium">{xmlData?.formtype}</p>
                                        </div>
                                        <div>
                                            <label className="block text-sm  font-medium">Torres Strait Islander </label>
                                            <p className=" text-xss text-gray-500 font-medium">{xmlData?.formtype}</p>
                                        </div>
                                        <div>
                                            <label className="block text-sm  font-medium">First Language</label>
                                            <p className=" text-xss text-gray-500 font-medium">{xmlData?.formtype}</p>
                                        </div>
                                        <div>
                                            <label className="block text-sm  font-medium">Interpreter Required </label>
                                            <p className=" text-xss text-gray-500 font-medium">{xmlData?.interpreter}</p>
                                        </div>
                                        {xmlData.interpreterlanguage && <div>
                                            <label className="block text-sm  font-medium">Interpreter Required </label>
                                            <p className=" text-xss text-gray-500 font-medium">{xmlData?.interpreterlanguage}</p>
                                        </div>}
                                    </div>
                                </section>
                            </div>

                            <div className={' w-1/5 flex flex-col items-center border rounded-md  '}>

                                <div className={' flex p-1 items-center text-white rounded w-full  ' + theme.navbar}>
                                    <p className='text-sm font-bold px-2 '>Form Information</p>
                                </div>
                                <div className={` flex flex-col p-1 items-center rounded w-full  overflow-y-scroll scrollbar scrollbar-thin`}>
                                    <div className='w-full px-2 border-b '>
                                        <label className="block text-sm text-gray-500 font-medium">Form Type</label>
                                        <p className=" text-xss text-gray-500 font-medium"> {xmlData.formtype ? 'Electronic Forms' : xmlData.formTypeName}</p>
                                    </div>
                                    <div className='w-full px-2 border-b flex gap-x-2'>
                                        <div className='w-1/2'>
                                            <label className="block text-sm text-gray-500 font-medium">Version</label>
                                            <p className=" text-xss text-gray-500 font-medium">{xmlData?.formVersion}</p>
                                        </div>
                                        <div className='w-1/2'>
                                            <label className="block text-sm text-gray-500 font-medium">Date Entered</label>
                                            <p className=" text-xss text-gray-500 font-medium"> {getCorrectDate(xmlData?.addeddate)}</p>
                                        </div>
                                    </div>
                                    <div className='w-full px-2 border-b'>
                                        <label className="block text-sm text-gray-500 font-medium">Entered by
                                        </label>
                                        <p className=" text-xss text-gray-500 font-medium"> {xmlData?.formTypeName}</p>
                                    </div>
                                    <div className='w-full px-2 border-b'>
                                        <label className="block text-sm text-gray-500 font-medium">Linked</label>
                                        <p className=" text-xss text-gray-500 font-medium">{xmlData?.link}</p>
                                    </div>
                                    <div className='w-full px-2 border-b'>
                                        <label className="block text-sm text-gray-500 font-medium">Database ID</label>
                                        <p className=" text-xss text-gray-500 font-medium">  {/*{xmlData?.formTypeName}*/} 87651339</p>
                                    </div>



                                </div>
                                <div className='w-full flex gap-x-2 p-2'>
                                    <button id="printButton" className="bg-white flex justify-center items-center w-1/2  hover:bg-green-600 text-green-600 font-medium hover:text-white py-0.5 px-2 border-[1px] border-gray-300 hover:border-transparent rounded py-1 ">
                                        <svg width="19" height="14" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 5.33333V12C19 12.1768 18.9285 12.3464 18.8013 12.4714C18.674 12.5964 18.5014 12.6667 18.3214 12.6667H15.6071V15.3333C15.6071 15.5101 15.5357 15.6797 15.4084 15.8047C15.2811 15.9298 15.1085 16 14.9286 16H4.07143C3.89146 16 3.71886 15.9298 3.59161 15.8047C3.46435 15.6797 3.39286 15.5101 3.39286 15.3333V12.6667H0.678571C0.498603 12.6667 0.326006 12.5964 0.198749 12.4714C0.0714921 12.3464 0 12.1768 0 12V5.33333C0 4.23083 0.963572 3.33333 2.14853 3.33333H3.39286V0.666667C3.39286 0.489856 3.46435 0.320287 3.59161 0.195262C3.71886 0.070238 3.89146 0 4.07143 0H14.9286C15.1085 0 15.2811 0.070238 15.4084 0.195262C15.5357 0.320287 15.6071 0.489856 15.6071 0.666667V3.33333H16.8515C18.0364 3.33333 19 4.23083 19 5.33333ZM4.75 3.33333H14.25V1.33333H4.75V3.33333ZM14.25 10.6667H4.75V14.6667H14.25V10.6667ZM15.6071 7C15.6071 6.80222 15.5474 6.60888 15.4356 6.44443C15.3238 6.27998 15.1648 6.15181 14.9788 6.07612C14.7928 6.00043 14.5882 5.98063 14.3907 6.01921C14.1933 6.0578 14.0119 6.15304 13.8696 6.29289C13.7272 6.43275 13.6303 6.61093 13.591 6.80491C13.5517 6.99889 13.5719 7.19996 13.6489 7.38268C13.7259 7.56541 13.8564 7.72159 14.0238 7.83147C14.1912 7.94135 14.388 8 14.5893 8C14.8592 8 15.1181 7.89464 15.309 7.70711C15.4999 7.51957 15.6071 7.26522 15.6071 7Z" fill="currentColor" />
                                        </svg><label className='text-sm pl-1'>Print</label>
                                    </button>
                                    <button className="flex w-1/2 justify-center items-center text-blue-500  bg-white border border-gray-300 focus:outline-none hover:bg-blue-400 hover:text-white focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-2 py-1 mr-2 ">
                                        <DriveFileRenameOutlineRoundedIcon fontSize='17px' className='text-sm' />
                                        <label className='text-sm pl-1 cursor-pointer'> Edit</label>
                                    </button>
                                </div>
                                <div className={' flex py-1 px-2 items-center text-white rounded w-full  ' + theme.navbar}>
                                    <p className='text-sm font-bold px-2 '>Revisions / History</p>
                                </div>
                                <div className={`${theme.thumb} ${theme.track}  ${theme.thin} flex flex-col p-1 items-center text-white rounded w-full h-40 overflow-y-scroll scrollbar scrollbar-thin`}>
                                    {patientPortalForm?.map((item, index) => (
                                        < div onClick={() => { handleClick(item) }} className={"w-full flex justify-center items-center  px-2 gap-x-2 mt-0.5 rounded  bg-[#DBE3DF] "}>
                                            <div className={`flex flex-col h-8 w-8 shadow rounded items-center justify-center `} style={{ backgroundColor: (getCycleTypeColor(item?.cycletype?.split(' ')[0].trim()) || 'gray') }}>
                                                <label className={` text-sm  font-semibold   text-${getTextColor(getCycleTypeColor(item?.cycletype?.replaceAll(' ', '')?.split('-')[0]) || 'gray')}`}>
                                                    {item?.cycletype ? item?.cycletype == 'Ovulation Induction' ? 'OI' : item?.cycletype == "Egg-donor IVF Cycle" ? 'ED' : item?.cycletype?.split(' ')[0].trim() : ' - '}
                                                </label>
                                                <div className="rounded bg-white flex  items-center justify-center h-4 w-6 mb-1" >
                                                    {item.formVersion && <label className={`  text-sm3 font-bold   text-gray-600`}>{item.formVersion} </label>}
                                                </div>
                                            </div>
                                            <div className={"py-1"}>
                                                <p className="text-sm text-gray-800 font-medium">Registration Form</p>
                                                <p className="text-sm text-gray-800 font-medium">{item.addedon}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div >

                        </div>

                    )}
                </div>
            </div>

            {popUPImport && <div className="  fixed inset-0 z-10 overflow-y-auto delay-500 transition duration-1000 ease-in-out">
                {/* <div onClick={() => setShowElectronicForm(false)} className="fixed inset-0 w-full h-full bg-black opacity-60  " style={{ backdropFilter: 'blur(7px)' }}></div> */}
                <div style={{ backdropFilter: 'blur(7px)' }} className="fixed inset-0 transition-opacity filter" aria-hidden="true"
                // onClick={() => setShowElectronicForm(false)}
                >
                    <div className="absolute inset-0 bg-gray-700 opacity-70  " ></div>
                </div>
                <div className="flex items-center h-screen px-4 py-8 delay-900 duration-700">
                    <div className="relative w-10/12 h-5/6  p-4 mx-auto bg-gray-100 rounded-md shadow-lg">
                        <svg onClick={() => setPopUPImport(false)} className={' absolute top-2 right-2 cursor-pointer shadow  font-semibold  '}
                            width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545" />
                        </svg>
                        <p className={'font-bold text-xxl text-center my-3 ' + theme.txt_color + ' '}>Import forms / registration</p>
                        <p className={' font-semibold  text-gray-800 text-sm text-center my-1 '}>Select the Patient Portal submissions/registrations to be linked to the patient/couple,</p>
                        <div className=' w-full items-center bg-gray-100 p-3   rounded-t mb-b' onMouseDown={(e) => e.stopPropagation()}>

                            <div className={'rounded flex items-center gap-x-3'}>
                                <label onClick={() => { setActiveStep(1); }} className={' font-bold cursor-pointer ' + (activeStep === 1 ? (theme.txt_color + ' border-b-[3px] border-b-amber-500') : 'text-gray-400  border-b-[3px]  border-b-gray-400')}>SELECT</label>
                                <label onClick={() => { setActiveStep(2); }} className={' font-bold cursor-pointer ' + (activeStep === 2 ? (theme.txt_color + ' border-b-[3px] border-b-amber-500') : 'text-gray-400  border-b-[3px]  border-b-gray-400')}>EDIT</label>
                                <label onClick={() => { setActiveStep(3); }} className={' font-bold cursor-pointer ' + (activeStep === 3 ? (theme.txt_color + ' border-b-[3px] border-b-amber-500') : 'text-gray-400  border-b-[3px]  border-b-gray-400')}>Finish</label>
                            </div>
                            <div className=" w-[100%] h-[40vh] scrollbar overflow-auto text-gray-800 py-0.5 px-1 shadow mt-2 border">

                                {activeStep === 1 &&
                                    < div className={"w-full flex items-center  px-2  mt-0.5 rounded  bg-[#DBE3DF] "}>
                                        <div style={{ width: "10%" }} className={"py-1 flex items-center"}>
                                            <input checked={formData?.adm_art_morb} onChange={(e) => { handleInputChange(e) }} name='adm_art_morb' type='checkbox' className={theme.cheqColor + ' border mr-2 focus:outline-none'} />
                                            <div className={`logoImg shadow flex-none icon_16_${sessionUser.BrandCode}`}></div>
                                            {/* <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Admission With ART Morbility</p> */}
                                        </div>
                                        <div style={{ width: "20%" }} className={"py-1"}>
                                            <p className="text-sm text-gray-800 font-medium">Partner Fertility History Assessment</p>
                                            <p className="text-sm text-gray-800 font-medium">Entered on:  8-FEB-2023 12:33</p>
                                        </div>
                                        <div style={{ width: "20%" }} className={"py-1 flex gap-x-2 items-center"}>
                                            <p className={`dot-CFC  bg-red-300`} ></p>
                                            <p className="text-sm text-gray-800 font-medium">FSF NorthLakes </p>
                                        </div>
                                        <div style={{ width: "20%" }} className={""}>
                                            <div className={"text-left  cursor-pointer items-center gap-x-3 p-1 rounded "}>
                                                <label className={" text-sm font-semibold  "}>
                                                    Patient: {'item.PatientFirstname'}
                                                </label>
                                                <p className="text-sm text-gray-600 font-medium">
                                                    DOB : {`getCorrectDate('item.PatientDOB')`}
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ width: "20%" }} className={""}>
                                            {/* <p className="text-sm text-gray-800 font-semibold">Added Date</p> */}
                                            <div className={"text-left  cursor-pointer items-center gap-x-3 p-1 rounded "}>
                                                <label className={" text-sm font-semibold  "}>
                                                    Partner : {'item.PatientFirstname'}
                                                </label>
                                                <p className="text-sm text-gray-600 font-medium">
                                                    DOB : {`getCorrectDate('item.PatientDOB')`}
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ width: "10%" }} className={"py-1 flex justify-center sitems-center "}>
                                            <p className="text-sm text-gray-800 font-semibold">No Match</p>
                                        </div>
                                    </div>}
                                {activeStep === 2 &&
                                    <div className='flex w-full  items-between my-1  gap-2 '>
                                        <div className='w-1/2 rounded shadow bg-[#f2faff]'>
                                            <div className={'bg-[#86b3d1] text- text-left p-1 rounded-t'}><p className='  text-sbase font-medium text-gray-800 p-1'>Current information in the Couple File</p> </div>
                                            < div className={"w-full flex items-center  px-2  mt-0.5 rounded  bg-[#DBE3DF] "}>
                                                <div style={{ width: "5%" }} className={"py-1 flex items-center"}>
                                                    {/* <input checked={formData?.adm_art_morb} onChange={(e) => { handleInputChange(e) }} name='adm_art_morb' type='checkbox' className={theme.cheqColor + ' border mr-2 focus:outline-none'} /> */}
                                                    {/* <p className="text-sm text-gray-800 font-medium">BHCG Test Result</p> */}
                                                    <div className={`logoImg shadow flex-none icon_16_${sessionUser.BrandCode}`}></div>
                                                    {/* <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Admission With ART Morbility</p> */}
                                                </div>
                                                <div style={{ width: "30%" }} className={"py-1"}>
                                                    <p className="text-sm text-gray-800 font-medium">Couple Code</p>
                                                    <p className="text-sm text-gray-800 font-medium">C7212420301</p>
                                                </div>

                                                <div style={{ width: "30%" }} className={""}>
                                                    <p className="text-sm text-gray-800 font-medium">Cycle Counts</p>
                                                    <div className={"text-left flex cursor-pointer items-center gap-x-3 p-1 rounded "}>
                                                        <label className={" text-sm font-semibold  "}>
                                                            TP: 1.2
                                                        </label>
                                                        <p className="text-sm text-gray-600 font-medium">
                                                            BF : 3.1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div style={{ width: "30%" }} className={""}>
                                                    <p className="text-sm text-gray-800 font-medium">Current ART</p>
                                                    <div className={"text-left flex  cursor-pointer items-center gap-x-3 p-1 rounded "}>
                                                        <label className={" text-sm font-semibold  "}>
                                                            ART: 2
                                                        </label>
                                                        <p className="text-sm text-gray-600 font-medium">
                                                            AND : 2
                                                        </p>
                                                        <p className="text-sm text-gray-600 font-medium">
                                                            EMB :1
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className={` ${theme.tHedUpdte} flex justify-between mt-2 text-left p-2 rounded-t`}>
                                                <p className='text-sm text-white font-medium'>Patient Information</p>
                                                {true === 'F' ?
                                                    <svg width="15" height="20" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="white" />
                                                    </svg>
                                                    :
                                                    <svg width="17" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="White" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                }
                                            </div>


                                            <div className='w-full items-center flex font-normal  '>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label className="  text-sm text-gray-800 " >First Name</label>

                                                    <div className={`flex items-center px-2`} >
                                                        <input id="PatientResidential"
                                                            type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"PatientResidential"} />
                                                        <label htmlFor="PatientResidential" className=" px-2 text-sm text-gray-800 " >Test3</label>
                                                    </div>
                                                </div>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label htmlFor="PatientResidential" className="  text-sm text-gray-800 " >Last name</label>
                                                    <div className={`flex items-center px-2`} >
                                                        <input id="PatientResidential"
                                                            type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"PatientResidential"} />
                                                        <label htmlFor="PatientResidential" className=" px-2 text-sm text-gray-800 " >TEST1</label>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className='w-full items-center flex font-normal  '>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label htmlFor="PatientResidential" className="  text-sm text-gray-800 " >Dob</label>

                                                    <div className={`flex items-center px-2`} >
                                                        <input id="PatientResidential"
                                                            type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"PatientResidential"} />
                                                        <label htmlFor="PatientResidential" className=" px-2 text-sm text-gray-800 " >21-DEC-1960</label>
                                                    </div>
                                                </div>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label htmlFor="PatientResidential" className="  text-sm text-gray-800 " >Email</label>
                                                    <div className={`flex items-center px-2`} >
                                                        <input id="PatientResidential"
                                                            type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"PatientResidential"} />
                                                        <label htmlFor="PatientResidential" className=" px-2 text-sm text-gray-800 " >Abderazzakb@hotmail. Com</label>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className='w-full items-center flex font-normal  '>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label htmlFor="PatientResidential" className="  text-sm text-gray-800 " >Country of Birth</label>

                                                    <div className={`flex items-center px-2`} >
                                                        <input id="PatientResidential"
                                                            type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"PatientResidential"} />
                                                        <label htmlFor="PatientResidential" className=" px-2 text-sm text-gray-800 " >Australia</label>
                                                    </div>
                                                </div>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label htmlFor="PatientResidential" className="  text-sm text-gray-800 " >First Language</label>
                                                    <div className={`flex items-center px-2`} >
                                                        <input id="PatientResidential"
                                                            type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"PatientResidential"} />
                                                        <label htmlFor="PatientResidential" className=" px-2 text-sm text-gray-800 " >--</label>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className='w-full items-center flex font-normal  '>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label className="  text-sm text-gray-800 " >First Name</label>

                                                    <div className={`flex items-center px-2`} >
                                                        <input id="Patientfirstname"
                                                            type='radio' className={theme.cheqColor + ' border focus:outline-none'} name={"Patientfirstname"} />
                                                        <label htmlFor="Patientfirstname" className=" px-2 text-sm text-gray-800 " >Test2</label>
                                                    </div>
                                                </div>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label htmlFor="PatientResidential" className="  text-sm text-gray-800 " >Last name</label>
                                                    <div className={`flex items-center px-2`} >
                                                        <input id="PatientResidential"
                                                            type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"PatientResidential"} />
                                                        <label htmlFor="PatientResidential" className=" px-2 text-sm text-gray-800 " >TEST1</label>
                                                    </div>


                                                </div>
                                            </div>

                                            <div className={` ${theme.tHedUpdte} flex justify-between mt-2 text-left p-2 rounded-t`}>
                                                <p className='text-sm text-white font-medium'>Partner Information</p>
                                                {false ?
                                                    <svg width="15" height="20" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="white" />
                                                    </svg>
                                                    :
                                                    <svg width="17" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="White" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                }
                                            </div>
                                            <div className='w-full items-center flex font-normal  '>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label className="  text-sm text-gray-800 " >First Name</label>

                                                    <div className={`flex items-center px-2`} >
                                                        <input id="Patientfirstname"
                                                            type='radio' className={theme.cheqColor + ' border focus:outline-none'} name={"Patientfirstname"} />
                                                        <label htmlFor="Patientfirstname" className=" px-2 text-sm text-gray-800 " >Test</label>
                                                    </div>
                                                </div>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label htmlFor="PatientResidential" className="  text-sm text-gray-800 " >Last name</label>
                                                    <div className={`flex items-center px-2`} >
                                                        <input id="PatientResidential"
                                                            type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"PatientResidential"} />
                                                        <label htmlFor="PatientResidential" className=" px-2 text-sm text-gray-800 " >TEST1</label>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className='w-full items-center flex font-normal  '>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label htmlFor="PatientResidential" className="  text-sm text-gray-800 " >Dob</label>

                                                    <div className={`flex items-center px-2`} >
                                                        <input id="PatientResidential"
                                                            type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"PatientResidential"} />
                                                        <label htmlFor="PatientResidential" className=" px-2 text-sm text-gray-800 " >21-DEC-1960</label>
                                                    </div>
                                                </div>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label htmlFor="PatientResidential" className="  text-sm text-gray-800 " >Email</label>
                                                    <div className={`flex items-center px-2`} >
                                                        <input id="PatientResidential"
                                                            type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"PatientResidential"} />
                                                        <label htmlFor="PatientResidential" className=" px-2 text-sm text-gray-800 " >Abderazzakb@hotmail. Com</label>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className='w-full items-center flex font-normal  '>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label htmlFor="PatientResidential" className="  text-sm text-gray-800 " >Country of Birth</label>

                                                    <div className={`flex items-center px-2`} >
                                                        <input id="PatientResidential"
                                                            type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"PatientResidential"} />
                                                        <label htmlFor="PatientResidential" className=" px-2 text-sm text-gray-800 " >Australia</label>
                                                    </div>
                                                </div>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label htmlFor="PatientResidential" className="  text-sm text-gray-800 " >First Language</label>
                                                    <div className={`flex items-center px-2`} >
                                                        <input id="PatientResidential"
                                                            type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"PatientResidential"} />
                                                        <label htmlFor="PatientResidential" className=" px-2 text-sm text-gray-800 " >--</label>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className='w-full items-center flex font-normal  '>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label className="  text-sm text-gray-800 " >First Name</label>

                                                    <div className={`flex items-center px-2`} >
                                                        <input id="Patientfirstname"
                                                            type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"Patientfirstname"} />
                                                        <label htmlFor="Patientfirstname" className=" px-2 text-sm text-gray-800 " >Test1</label>
                                                    </div>
                                                </div>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label htmlFor="PatientResidential" className="  text-sm text-gray-800 " >Last name</label>
                                                    <div className={`flex items-center px-2`} >
                                                        <input id="PatientResidential"
                                                            type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"PatientResidential"} />
                                                        <label htmlFor="PatientResidential" className=" px-2 text-sm text-gray-800 " >TEST1</label>
                                                    </div>


                                                </div>
                                            </div>



                                        </div>
                                        <div className='w-1/2 rounded shadow bg-[#f0f5ff]'>
                                            <div className={'bg-[#A9C6FF] text- text-left p-1 rounded-t'}><p className='  text-sbase font-medium text-gray-800 p-1'>Information from the selected electronic forms</p> </div>
                                            < div className={"w-full flex items-center  px-2  mt-0.5 rounded  bg-[#DBE3DF] "}>
                                                <div style={{ width: "5%" }} className={"py-1 flex items-center"}>
                                                    {/* <input checked={formData?.adm_art_morb} onChange={(e) => { handleInputChange(e) }} name='adm_art_morb' type='checkbox' className={theme.cheqColor + ' border mr-2 focus:outline-none'} /> */}
                                                    {/* <p className="text-sm text-gray-800 font-medium">BHCG Test Result</p> */}
                                                    <div className={`logoImg shadow flex-none icon_16_${sessionUser.BrandCode}`}></div>
                                                    {/* <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Admission With ART Morbility</p> */}
                                                </div>
                                                <div style={{ width: "30%" }} className={"py-1"}>
                                                    <p className="text-sm text-gray-800 font-medium">Form ID</p>
                                                    <p className="text-sm text-gray-800 font-medium">2000508250</p>
                                                </div>

                                                <div style={{ width: "30%" }} className={""}>
                                                    <p className="text-sm text-gray-800 font-medium">Clinic / Location</p>
                                                    <div className={"text-left flex cursor-pointer items-center gap-x-1  rounded "}>
                                                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0.5 0.5H4.77857C6.78968 0.5 8.42 2.13032 8.42 4.14143C8.42 6.15253 6.78968 7.78286 4.77857 7.78286H4.46C2.27295 7.78286 0.5 6.0099 0.5 3.82286V0.5Z" fill="#C84F4F" stroke="#CCCCCC" />
                                                        </svg>
                                                        <label className={"flex  text-sm font-semibold  "}>
                                                            FSF NorthLakes
                                                        </label>

                                                    </div>
                                                </div>
                                                <div style={{ width: "30%" }} className={""}>
                                                    <p className="text-sm text-gray-800 font-medium">Cycle Counts</p>
                                                    <div className={"text-left flex  cursor-pointer items-center gap-x-3 p-1 rounded "}>
                                                        <label className={" text-sm font-semibold  "}>
                                                            Date of Entry
                                                        </label>
                                                        <p className="text-sm text-gray-600 font-medium">
                                                            7-FEB-2023 19:42
                                                        </p>

                                                    </div>
                                                </div>

                                            </div>
                                            <div className={` ${theme.tHedUpdte} flex justify-between mt-2 text-left p-2 rounded-t`}>
                                                <p className='text-sm text-white font-medium'>Partner Information</p>
                                                {false ?
                                                    <svg width="15" height="20" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="white" />
                                                    </svg>
                                                    :
                                                    <svg width="17" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="White" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                }
                                            </div>
                                            <div className='w-full items-center flex font-normal  '>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label className="  text-sm text-gray-800 " >First Name</label>

                                                    <div className={`flex items-center px-2`} >
                                                        <input id="Patientfirstname"
                                                            type='radio' className={theme.cheqColor + ' border focus:outline-none'} name={"Patientfirstname"} />
                                                        <label htmlFor="Patientfirstname" className=" px-2 text-sm text-gray-800 " >Test</label>
                                                    </div>
                                                </div>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label htmlFor="PatientResidential" className="  text-sm text-gray-800 " >Last name</label>
                                                    <div className={`flex items-center px-2`} >
                                                        <input id="PatientResidential"
                                                            type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"PatientResidential"} />
                                                        <label htmlFor="PatientResidential" className=" px-2 text-sm text-gray-800 " >TEST1</label>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className='w-full items-center flex font-normal  '>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label htmlFor="PatientResidential" className="  text-sm text-gray-800 " >Dob</label>

                                                    <div className={`flex items-center px-2`} >
                                                        <input id="PatientResidential"
                                                            type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"PatientResidential"} />
                                                        <label htmlFor="PatientResidential" className=" px-2 text-sm text-gray-800 " >21-DEC-1960</label>
                                                    </div>
                                                </div>
                                                <div className={`w-1/2 text-left  py-0.5 px-2 ${theme.hoverBg}`}>
                                                    <label htmlFor="PatientResidential" className="  text-sm text-gray-800 " >Email</label>
                                                    <div className={`flex items-center px-2`} >
                                                        <input id="PatientResidential"
                                                            type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"PatientResidential"} />
                                                        <label htmlFor="PatientResidential" className=" px-2 text-sm text-gray-800 " >Abderazzakb@hotmail. Com</label>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>


                        </div>

                        <div className=' py-2 rounded flex justify-end border px-6'>
                            <button onClick={() => setPopUPImport(false)} className="bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded"> Cancel</button>
                            <button className="bg-transparent  cursor-pointer ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded"
                                onClick={() => setActiveStep(2)}
                            > Next</button>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_GET, ApiCall_POST }

export default connect(mapStateToProps, mapDispatchToProps)(ElectronicForm)