import React from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../AppContextProvider ';

export const CoupleElectronicInfo = ({ xmlData }) => {
    const theme = useAppContext().themeDetails;
    return (
        <div className='w-full  h-full pb-2'>
            {/* Tab Body  */}
            <div className={`${theme.thumb} ${theme.track}  ${theme.thin} relative w-full overflow-y-scroll scrollbar scrollbar-thin h-screen `}>
                {/* Tab Body Section 4 */}
                {/* Primary Information */}
                <div className='w-full flex overflow-hidden gap-x-2 py-2' >
                    {/* Patient Information */}
                    <div className={`  w-1/2 rounded`}>
                        <div className={`${xmlData.formtype ? 'bg-[#ffc3c340]' : "bg-[#f0f5ff]"} w-full shadow  mb-2 font-normal   `}>
                            <div className={`${xmlData.formtype ? 'bg-[#FE9696]' : "bg-[#A9C6FF]"} flex justify-between  text-left p-2 rounded-t`}>
                                <p className='text-sm font-medium'>Patient Information</p>
                                {xmlData.sex == "female" ?
                                    <svg width="15" height="20" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="white" />
                                    </svg>
                                    :
                                    <svg width="17" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="White" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                }
                            </div>
                            <div className='w-full items-center flex font-normal  '>
                                <div className={`w-1/2 text-left flex items-center py-0.5 px-2 ${theme.hoverBg}`}>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>Name</p>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>{xmlData?.surname}{xmlData?.patientSurname}{', ' + xmlData?.patientFirstName}</p>

                                </div>
                                {/* <div className={`w-1/2 text-left flex items-center py-0.5 px-2 ${theme.hoverBg}`}>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>First name</p>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>{xmlData.Firstname}</p>

                                </div> */}
                            </div>
                            <div className='w-full items-center flex font-normal  '>
                                <div className={`w-1/2 text-left flex items-center py-0.5 px-2 ${theme.hoverBg}`}>
                                    <p className="w-1/2 text-sm font-normal text-gray-700">DOB</p>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>{xmlData?.patientDOB}</p>

                                </div>
                                <div className={`w-1/2 text-left flex items-center font-normal py-0.5 px-2 ${theme.hoverBg}`}>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>Monash URN</p>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>{xmlData.Firstname}</p>

                                </div>
                            </div>
                            <div className='w-full items-center  flex font-normal '>
                                <div className={`w-1/2 text-left flex items-center py-0.5 px-2 ${theme.hoverBg}`}>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>Maiden Name: </p>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>{xmlData.Firstname}</p>
                                </div>
                                <div className={`w-1/2 text-left flex items-center py-0.5 px-2 ${theme.hoverBg}`}>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>Middle Name:</p>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>{xmlData.Firstname}</p>
                                </div>
                            </div>
                            <div className='w-full items-center font-normal flex  '>
                                <div className={`w-1/2 text-left flex  py-0.5 px-2 ${theme.hoverBg}`}>
                                    <label className='w-1/2 text-sm font-normal text-gray-700 mb-1'>Gender Identify As</label>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>{xmlData.Firstname}</p>

                                </div>
                                <div className={`w-1/2 text-left flex items-center py-0.5 px-2 ${theme.hoverBg}`}>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>Intersex</p>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>{xmlData.Firstname}</p>

                                </div>
                            </div>

                            <div className='w-full items-center  flex  '>
                                <div className={`w-1/2 text-left flex items-center py-0.5 font-normal px-2 ${theme.hoverBg}`}>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>Sexuality</p>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>{xmlData.Firstname}</p>

                                </div>
                                <div className={`w-1/2 text-left flex items-center font-normal py-0.5 px-2 ${theme.hoverBg}`}>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>Pro-nouns</p>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>{xmlData.Firstname}</p>

                                </div>
                            </div>
                            <div className='w-full items-center  flex justify-between  border-b-[1px]  border-b-gray-200'>
                                <div className={`w-1/2 text-left flex items-center py-0.5 px-2 ${theme.hoverBg}`}>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>Security Word</p>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>{xmlData.Firstname}</p>
                                </div>
                                <div className={`w-1/2  gap-x-1 flex  items-center py-0.5 px-2 ${theme.hoverBg}`}>
                                    <svg className={`cursor-pointer hover:scale-125 duration-200 `} width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.6667 5.25H15.7778M13.6667 9.5H15.7778M15.7778 13.75H5.22222M5.22222 5.25V9.5H9.44444V5.25H5.22222ZM3.11111 18H17.8889C18.4488 18 18.9858 17.7761 19.3817 17.3776C19.7776 16.9791 20 16.4386 20 15.875V3.125C20 2.56141 19.7776 2.02091 19.3817 1.6224C18.9858 1.22388 18.4488 1 17.8889 1H3.11111C2.55121 1 2.01424 1.22388 1.61833 1.6224C1.22242 2.02091 1 2.56141 1 3.125V15.875C1 16.4386 1.22242 16.9791 1.61833 17.3776C2.01424 17.7761 2.55121 18 3.11111 18Z" stroke={theme.iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                    {/* <svg onClick={(e) => handleDeleteFile('PatientIDCard')} className="" widtd="25" height="20" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.3889 1.8C10.551 1.8 10.7064 1.86321 10.821 1.97574C10.9356 2.08826 11 2.24087 11 2.4C11 2.55913 10.9356 2.71174 10.821 2.82426C10.7064 2.93679 10.551 3 10.3889 3H9.77778L9.77594 3.0426L9.20578 10.8852C9.18383 11.188 9.04585 11.4713 8.81963 11.6781C8.5934 11.885 8.29575 12 7.98661 12H3.01278C2.70364 12 2.40599 11.885 2.17976 11.6781C1.95354 11.4713 1.81556 11.188 1.79361 10.8852L1.22344 3.0432C1.22252 3.02882 1.22211 3.01441 1.22222 3H0.611111C0.449034 3 0.293596 2.93679 0.17899 2.82426C0.0643847 2.71174 0 2.55913 0 2.4C0 2.24087 0.0643847 2.08826 0.17899 1.97574C0.293596 1.86321 0.449034 1.8 0.611111 1.8H10.3889ZM6.72222 0C6.8843 0 7.03974 0.0632141 7.15434 0.175736C7.26895 0.288258 7.33333 0.44087 7.33333 0.6C7.33333 0.75913 7.26895 0.911742 7.15434 1.02426C7.03974 1.13679 6.8843 1.2 6.72222 1.2H4.27778C4.1157 1.2 3.96026 1.13679 3.84566 1.02426C3.73105 0.911742 3.66667 0.75913 3.66667 0.6C3.66667 0.44087 3.73105 0.288258 3.84566 0.175736C3.96026 0.0632141 4.1157 0 4.27778 0H6.72222Z" fill="#FE8787"></path></svg> */}
                                </div>
                            </div>
                        </div>
                        {/* Patient Contact Information */}
                        <div className={`${xmlData.formtype ? 'bg-[#ffc3c340]' : "bg-[#f0f5ff]"} w-full shadow font-normal mb-2  `}>
                            <div className={`${xmlData.formtype ? 'bg-[#FE9696]' : "bg-[#A9C6FF]"} flex justify-between  text-left p-2 rounded-t`}> <p className='text-sm font-medium'>Patient Contact Information</p></div>
                            <div className={' text-left py-0.5 px-2 ' + theme.hoverBg}>
                                <div className='w-full flex gap-x-2'>
                                    <p className='w-28  text-sm font-normal  text-gray-700'>Residential Address</p>
                                    <button name={''} className="bg-transparent px-2 gap-x-1 flex items-center gap-x-1 hover:bg-[#52575C] text-[#52575C] font-semibold hover:text-white py-0.5 px-1 text-sm border border-[#52575C] hover:border-transparent rounded">
                                        <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.60156 2.3V10.1C3.60156 10.4448 3.73853 10.7754 3.98232 11.0192C4.22612 11.263 4.55678 11.4 4.90156 11.4H10.1016C10.4463 11.4 10.777 11.263 11.0208 11.0192C11.2646 10.7754 11.4016 10.4448 11.4016 10.1V4.4073C11.4015 4.23411 11.3669 4.06268 11.2997 3.90306C11.2325 3.74344 11.1341 3.59886 11.0103 3.4778L8.85551 1.3705C8.61265 1.13303 8.28648 1.00004 7.94681 1H4.90156C4.55678 1 4.22612 1.13696 3.98232 1.38076C3.73853 1.62456 3.60156 1.95522 3.60156 2.3Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M8.8 11.4V12.7C8.8 13.0448 8.66304 13.3754 8.41924 13.6192C8.17544 13.863 7.84478 14 7.5 14H2.3C1.95522 14 1.62456 13.863 1.38076 13.6192C1.13696 13.3754 1 13.0448 1 12.7V5.55C1 5.20522 1.13696 4.87456 1.38076 4.63076C1.62456 4.38696 1.95522 4.25 2.3 4.25H3.6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <label>Copy Details to Partner</label></button>
                                </div>
                                <div className='w-full flex gap-1 mt-1'>
                                    <div className='w-2/4 flex gap-2 items-center '>
                                        <p className='w-28  text-sm font-normal  text-gray-700'>Suburb</p>
                                    </div>
                                    <div className='w-1/4 flex gap-2 items-center'>
                                        <p className='  text-sm font-normal  text-gray-700'>State</p>
                                    </div>
                                    <div className='w-1/4 flex gap-2 items-center'>
                                        <p className='  text-sm font-normal  text-gray-700'>Postcode</p>
                                    </div>
                                </div>
                            </div>

                            <div className={' text-left px-2 py-0.5 ' + theme.hoverBg}>
                                <div className='w-full flex gap-x-2  items-center'>
                                    <p className=' w-28 text-sm font-normal  text-gray-700'>Postal Address</p>

                                    <div className=" flex justify- items-center w-1/3 cursor-pointer rounded px-2">
                                        <label htmlFor="PatientResidential" className=" px-2 text-sm text-gray-800 " >Same As Residential</label>
                                    </div>
                                </div>
                                <div className='w-full flex gap-1 mt-1'>
                                    <div className='w-2/4 flex gap-2  items-center'>
                                        <p className=' w-28 text-sm font-normal  text-gray-700'>Suburb</p>
                                    </div>
                                    <div className='w-1/4 flex gap-1  items-center'>
                                        <p className='  text-sm font-normal  text-gray-700'>State</p>
                                    </div>
                                    <div className='w-1/4 flex gap-1  items-center'>
                                        <p className='  text-sm font-normal  text-gray-700'>Postcode</p>
                                    </div>
                                </div>
                            </div>
                            <div className={' text-left py-0.5 px-2 flex  gap-x-1 font-normal border-t-[2px]  border-t-gray-200   ' + theme.hoverBg}>
                                <p className='w-48 text-sm   text-gray-700'>Preferred Contact Method</p>

                            </div>
                            <div className='w-full items-center flex  '>
                                <div className={`w-1/2 text-left flex items-center py-0.5 px-2 ${theme.hoverBg}`}>
                                    <p className='w-1/2 text-sm font-normal text-gray-700'>Phone (H)</p>
                                </div>
                                <div className={'w-1/2 text-left py-0.5 px-2 flex items-center border-l-[2px]  border-b-gray-200  ' + theme.hoverBg}>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>Phone (W)</p>
                                </div>
                            </div>
                            <div className='w-full items-center flex  '>
                                <div className={'w-1/2 text-leftpy-0.5 px-2  flex items-center ' + theme.hoverBg}>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>Phone (M)</p>
                                </div>
                                <div className={'w-1/2 text-left py-0.5 px-2 flex  border-l-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>Email Address  <button className='px-2 ml-3 bg-white border rounded cursor-pointer text-gray-600 text-sm '  >Send Email</button></p>
                                </div>
                            </div>
                            <div className='w-full items-center flex  '>
                                <div className={'w-1/2 text-left py-0.5 px-2  flex items-center ' + theme.hoverBg}>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>Emergency Contact Name</p>
                                </div>
                                <div className={'w-1/2 text-left py-0.5 px-2 flex items-center border-l-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>Emergency Contact Number</p>
                                </div>
                            </div>
                            <div className='w-full items-center flex   border-b-[1px]  border-b-gray-200'>
                                <div className={'w-1/2 text-left py-0.5 px-2 flex items-center' + theme.hoverBg}>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>Work Suburb</p>
                                </div>
                                <div className={'w-1/2 text-left py-0.5 px-2 flex  border-l-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>Work Postcode</p>
                                </div>
                            </div>
                        </div>

                        {/* Patient Medical Detail */}
                        <div className={`${xmlData.PatientGender == 'F' ? 'bg-[#ffc3c340]' : "bg-[#f0f5ff]"} w-full shadow  mb-2   `}>
                            <div className={`${xmlData.PatientGender == 'F' ? 'bg-[#FE9696]' : "bg-[#A9C6FF]"}  flex justify-between text-left p-2 rounded-t`}>
                                <p className='text-sm font-normal'>Patient Medical Details</p>
                            </div>
                            <div className={'w-full flex text-left  items-center flex-wrap justify-between'}>
                                {/*Medicare Card */}
                                <div className={' w-full flex gap-x-2 py-0.5 rounded-b px-2 ' + theme.hoverBg}>
                                    <div className='w-2/3  flex  gap-1 '>
                                        <div className='w-2/4 flex flex-col justify- '>
                                            <label className=" text-sm text-gray-800 " >Medicare Num</label>
                                        </div>
                                        <div className='w-1/4 flex flex-col justify- '>
                                            <label className=" text-sm text-gray-800 " >IRN</label>
                                        </div>
                                        <div className='w-1/4 flex flex-col justify-'>
                                            <label className="  text-sm text-gray-800 " >Expiry Date</label>
                                        </div>
                                    </div>
                                    <div className='w-1/3 flex justify-center  items-center '>
                                        <button className="bg-transparent flex items-center gap-x-2 hover:bg-red-400 mb-0.5 cursor-pointer text-red-500 font-semibold hover:text-white py-0.5 px-2 text-sm border border-red-500 hover:border-transparent rounded">
                                            <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.60156 2.3V10.1C3.60156 10.4448 3.73853 10.7754 3.98232 11.0192C4.22612 11.263 4.55678 11.4 4.90156 11.4H10.1016C10.4463 11.4 10.777 11.263 11.0208 11.0192C11.2646 10.7754 11.4016 10.4448 11.4016 10.1V4.4073C11.4015 4.23411 11.3669 4.06268 11.2997 3.90306C11.2325 3.74344 11.1341 3.59886 11.0103 3.4778L8.85551 1.3705C8.61265 1.13303 8.28648 1.00004 7.94681 1H4.90156C4.55678 1 4.22612 1.13696 3.98232 1.38076C3.73853 1.62456 3.60156 1.95522 3.60156 2.3Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M8.8 11.4V12.7C8.8 13.0448 8.66304 13.3754 8.41924 13.6192C8.17544 13.863 7.84478 14 7.5 14H2.3C1.95522 14 1.62456 13.863 1.38076 13.6192C1.13696 13.3754 1 13.0448 1 12.7V5.55C1 5.20522 1.13696 4.87456 1.38076 4.63076C1.62456 4.38696 1.95522 4.25 2.3 4.25H3.6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <label className='cursor-pointer'>No Medicare Card</label>
                                        </button>
                                    </div>
                                </div>

                                <div className={' w-full flex  justify-between rounded-b ' + theme.hoverBg}>
                                    <div className='w-[60%]   justify-between items-center '>
                                        <div className='w-full flex justify-between items-center py-0.5'>
                                            <label className=" px-2 text-sm text-gray-800  " >First Name on Medicare card</label>
                                        </div>
                                        <div className='w-full flex justify-between items-center pb-0.5'>
                                            <label className=" px-2 text-sm text-gray-800 ">Last Name on Medicare card</label>
                                        </div>
                                    </div>
                                    <div className='w-[40%]  px-2 flex justify-center items-center  gap-x-2 my-1 pb-1'>
                                        <svg className={`cursor-pointer hover:scale-125 duration-200 `} width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.6667 5.25H15.7778M13.6667 9.5H15.7778M15.7778 13.75H5.22222M5.22222 5.25V9.5H9.44444V5.25H5.22222ZM3.11111 18H17.8889C18.4488 18 18.9858 17.7761 19.3817 17.3776C19.7776 16.9791 20 16.4386 20 15.875V3.125C20 2.56141 19.7776 2.02091 19.3817 1.6224C18.9858 1.22388 18.4488 1 17.8889 1H3.11111C2.55121 1 2.01424 1.22388 1.61833 1.6224C1.22242 2.02091 1 2.56141 1 3.125V15.875C1 16.4386 1.22242 16.9791 1.61833 17.3776C2.01424 17.7761 2.55121 18 3.11111 18Z" stroke={theme.iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>

                                    </div>
                                </div>
                            </div>
                            <div className={'w-full flex flex-wrap py-0.5 px-2 pap-x-2 ' + theme.hoverBg}>
                                <div className='xl:w-1/2 w-full gap-x-3 items-center flex'>
                                    <p className='  text-sm font-normal  text-gray-700'>Health Care Card #</p>
                                </div>
                                <div className='xl:w-1/2 w-full flex items-center gap-x-3'>
                                    <p className='  text-sm font-normal  text-gray-700'>Expiry</p>
                                </div>
                            </div>
                            <div className={'w-full flex flex-wrap py-0.5 items-center  ' + theme.hoverBg}>
                                <div className='xl:w-1/3 w-full  items-center flex px-2 '>
                                    <p className='w-28  text-sm font-normal  text-gray-700'>PrivateFund </p>
                                </div>
                                <div className='xl:w-1/3 w-full flex items-center border-x-2 px-2'>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>Fund Member #</p>
                                </div>
                                <div className='xl:w-1/3 gap-x-1 items-center  w-full flex px-2 '>
                                    <p className='w-1/2   text-sm font-normal  text-gray-700'>Level of Cover</p>
                                </div>
                            </div>
                            <div className={'w-full flex flex-wrap border-b-[2px] border-b-gray-200 py-0.5  ' + theme.hoverBg} style={{ backgroundColor: xmlData?.color }}>
                                <div className='xl:w-1/3 w-full  items-center flex px-2'>
                                    <p className='w-28  text-sm font-normal  text-gray-700'>Height (cm)</p>
                                </div>
                                <div className='xl:w-1/3 w-full flex items-center border-x-2 px-2'>
                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>Weight (kg)</p>
                                </div>
                                <div className='xl:w-1/3 w-full items-center flex px-2' >
                                    <p className='w-1/3  text-sm font-normal text-gray-700'>BMI</p>
                                    <p className='w-1/3  text-sm text-gray-600 font-medium  flex  items-center'>
                                        aa                                    </p>
                                    <div className='flex w-1/3 items-center justify-center   w-4'>{xmlData && <p className='font-normal'>{xmlData.icon}</p>}</div>
                                </div>
                            </div>
                            <div className={'w-full text-left flex px-2  ' + theme.hoverBg}>
                                <div className={'w-1/2 text-left  flex items-center    py-0.5  ' + theme.hoverBg}>
                                    <p className='w-28 text-sm font-normal  text-gray-700'>Allergies</p>
                                </div>
                                <div className={'w-1/2 text-left  flex items-center gap-x-2  py-0.5 ' + theme.hoverBg}>
                                    <p className=' w-28 text-sm font-normal  text-gray-700'>Occupation</p>
                                </div>
                            </div>
                        </div>

                        {/*Patient Nationality Details*/}
                        <div className={`${xmlData.PatientGender == 'F' ? 'bg-[#ffc3c340]' : "bg-[#f0f5ff]"} w-full shadow   font-medium  `}>
                            <div className={`${xmlData.PatientGender == 'F' ? 'bg-[#FE9696]' : "bg-[#A9C6FF]"} flex justify-between  text-left p-2 rounded-t`}> <p className='text-sm font-medium'>Patient Nationality Details</p></div>
                            <div className='w-full items-center flex font-normal '>
                                <div className='w-[45%] items-center   '>
                                    <div className={'w-full text-left py-0.5 px-2 ' + theme.hoverBg}>
                                        <p className='w-full  text-sm font-normal  text-gray-700'>Australian or New Zealand Citizen?</p>
                                        <div className="flex justify- gap-x-2 items-center ">
                                            <div className='rounded mb-0.5 w-1/2 px-2 flex items-center justify-'>

                                                <label htmlFor="PatientAus_Nz_Citizen_no" className={" text-sm px-2 "} >No</label>
                                            </div>
                                            <div className='rounded w-full px-2 flex items-center'>

                                                <label htmlFor="PatientAus_Nz_Citizen_yes" className="ml-2 text-sm text-gray-800">Yes</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={' w-full text-left py-0.5 px-2 flex ' + theme.hoverBg}>
                                        <p className='w-1/2  text-sm font-normal  text-gray-800'>Country of Birth</p>
                                        <p className='w-1/2  text-sm font-normal  text-gray-600'>{xmlData.birthcountry}</p>

                                    </div>
                                    <div className={'w-full text-left py-0.5 px-2 flex ' + theme.hoverBg}>
                                        <p className='w-1/2 text-sm font-normal  text-gray-700'>Country of Residence</p>
                                        <p className='w-1/2   text-sm font-normal  text-gray-700'>Country of Residence</p>

                                    </div>
                                    <div className={'w-full text-left py-0.5 px-2 flex  border-l-[2px] border-l-gray-200 ' + theme.hoverBg}>
                                        <p className='w-1/2   text-sm font-normal  text-gray-700'>Ethnic Background</p>
                                        <p className='w-1/2   text-sm font-normal  text-gray-700'>Ethnic Background</p>

                                    </div>
                                    <div className={'w-full text-left py-0.5 px-2 flex ' + theme.hoverBg}>
                                        <p className='w-1/2   text-sm font-normal  text-gray-700'>First Language</p>
                                        <p className='w-1/2   text-sm font-normal  text-gray-700'>First Language</p>

                                    </div>
                                </div>
                                <div className='w-[55%] items-center  font-normal '>
                                    <div className={'w-full text-left y-0.5 px-2 border-l-[2px]  border-l-gray-200   ' + theme.hoverBg}>
                                        <p className='w-full  text-sm font-normal pb-1 text-gray-700'>Aboriginal or Torres Strait Islander Origin?</p>

                                    </div>
                                    <div className={'flex items-center w-full text-left py-0.5 px-2  border-l-[2px]  border-l-gray-200 ' + theme.hoverBg}>
                                        <p className='w-2/3  text-sm font-normal  text-gray-700'>State of Birth (If born Australia)</p>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CoupleElectronicInfo)