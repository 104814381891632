import React, { useEffect, useState } from "react";
import { ApiCall_POST, ApiCall_GET } from "../../../../Redux/Generic/action";
import { connect } from "react-redux";
import ServiceUrls from "../../../../Common/ServiceUrls";
import { useAppContext } from "../../../../AppContextProvider ";

function AddInstantAction({
  ApiCall_POST,
  ApiCall_GET,
  existingData,
  onInputChange,
  formData,
  setFormData,
  coupleid,
  artid,
  treatmentPlanID, apiResponse, setApiResponse
}) {
  // const [apiResponse, setApiResponse] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({});
  const theme = useAppContext().themeDetails;

  useEffect(() => {
    getAllCycleActionType();
    // setFormData(existingData)
    if (existingData) {
      prefillCheckboxStates(existingData);
    }
  }, []);

  const getAllCycleActionType = () => {
    ApiCall_GET(ServiceUrls.GetAllCycleActionType).then((payload) => {
      setApiResponse(payload);
    });
  };

  const prefillCheckboxStates = (existingData) => {
    const initialStates = apiResponse.reduce((acc, item) => {
      const matchedItem = existingData.find(
        (data) => data.actionTypeId === item.actionTypeId
      );

      acc[item.actionTypeId] = {
        isChecked: !!matchedItem, // Checked if existingData contains this actionTypeId
        textareaValue: matchedItem ? matchedItem.actionDesc : "", // Prefill textarea
        actionId: matchedItem ? matchedItem.actionId : 0, // Include actionId if present
      };

      return acc;
    }, {});
    setCheckboxStates(initialStates);
  };

  useEffect(() => {
    // Only run prefill when API response changes in edit mode
    if (existingData) {
      prefillCheckboxStates(existingData);
    } else {
      const initialStates = apiResponse.reduce((acc, item) => {
        acc[item.actionTypeId] = { isChecked: false, textareaValue: "" };
        return acc;
      }, {});
      setCheckboxStates(initialStates);
      setFormData(initialStates);
    }
  }, [apiResponse]);

  const handleActions = (e) => {
    const { name, value, type, checked, id } = e.target;
    if (type === "checkbox") {
      setFormData((prevFormData) => {
        if (!checked) {
          // Uncheck the box and clear associated actionDesc
          const { [name]: _, ...rest } = prevFormData;
          return rest;
        }
        return {
          ...prevFormData,
          [name]: {
            ...prevFormData[name], actionDesc: "", checked,
            actionTypeId: name,
            coupleid: coupleid,
            artid: artid,
            recordID: treatmentPlanID,
            priority: 3,
          },
        };
      });
    } else {
      const [actionTypeId, field] = name.split(".");
      setFormData((prevFormData) => ({
        ...prevFormData,
        [actionTypeId]: {
          ...prevFormData[actionTypeId],
          [field]: value,
        },
      }));
    }
  };

  return (
    <>
      {/* {apiResponse.map((item) => (
        <div
          key={item.actionTypeId}
          className={
            "p-1 py-0.5 rounded flex flex-wrap justify-start " + theme.hoverBg
          }
        >
          <div
            className={
              "w-1/3 text-left px-4 p-1 flex items-center cursor-pointer"
            }
          >
            <label className="cursor-pointer text-sbase font-normal text-gray-900">
              <input
                type="checkbox"
                checked={checkboxStates[item.actionTypeId]?.isChecked || false}
                onChange={() => handleCheckboxChange(item.actionTypeId)}
              />
              <span className='px-2'> {item.actionTypeName}</span>
            </label>
          </div>
          {checkboxStates[item.actionTypeId]?.isChecked && (
            <textarea
              rows="1"
              value={checkboxStates[item.actionTypeId]?.textareaValue || ""}
              onChange={(e) =>
                handleTextareaChange(item.actionTypeId, e.target.value)
              }
              placeholder="Enter comment here..."
              className={`block w-2/3 text-sm px-1 py-0.5 border rounded accent-green-700 text-gray-700 focus:border-teal-500 focus:outline-none ${!checkboxStates[item.actionTypeId]?.textareaValue && 'border-red-400'}`}
            />
          )}
        </div>
      ))} */}
      {apiResponse.map((item) => {
        // const checked = formData[item.actionTypeId] || false;
        // { console.log(checked, 'formData[item.actionTypeId] ') }
        const actionDesc = formData[item.actionTypeId]?.actionDesc || "";
        return (
          <div key={item.actionTypeId} className={"p-1 py-0.5 rounded flex flex-wrap justify-start " + theme.hoverBg}          >
            <div className="w-1/2 text-left px-4 p-1 py-0.5 flex items-center cursor-pointer">
              <input
                id={item.actionTypeId}
                // checked={checked}
                onChange={(e) => handleActions(e)}
                name={item.actionTypeId}
                type="checkbox"
                className={
                  theme.cheqColor + " border mr-2 focus:outline-none "
                }
              />
              <p className="cursor-pointer text-sbase font-normal text-gray-900">
                {item.actionTypeName}
              </p>
            </div>
            {formData[item.actionTypeId]?.checked && (
              <textarea
                rows="1"
                type="text"
                name={`${item.actionTypeId}.actionDesc`}
                value={actionDesc}
                onChange={(e) => handleActions(e)}
                className={`block w-1/2 text-sm px-1  border rounded accent-green-700 text-gray-700 focus:outline-none ${actionDesc.trim() === "" ? "border-red-500" : "focus:border-teal-500"
                  }`} />
            )}
          </div>
        );
      })}

    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { ApiCall_POST, ApiCall_GET };

export default connect(mapStateToProps, mapDispatchToProps)(AddInstantAction);
