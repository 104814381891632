import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useAppContext } from "../../../AppContextProvider ";

import { ApiCall_GET, ApiCall_POST } from "../../../Redux/Generic/action";
import AddAction from "./AddAction";
import AddIcon from "@mui/icons-material/Add";
import { svgHandler } from "../../../Utils";
import EventEmitter from "../../../Common/EventEmitter";
import ServiceUrls from "../../../Common/ServiceUrls";
import ActionDailog from "./ActionDailog";
import ActionItem from "./ActionsItem";
import CompletedActions from "./CompletedActions";
import {
  getUserInformation,
  capitalizeName,
  formatTime,
  formatTimeDifference,
  getCorrectDate,
  getCycleTypeColor,
  getEnumValueById,
  getSubstringByLength,
  getTextColor,
  priority,
  replaceEmptyObjectsWithNull,
} from "../../../common";

export const Actions = ({
  ApiCall_POST,
  ApiCall_GET,
  props,
  coupleid,
  setIsSubmitted,
}) => {
  const theme = useAppContext().themeDetails;
  // const [nurseActiveStep, setNurseActiveStep] = useState("");
  const { nurseActiveStep, setNurseActiveStep } = useAppContext("");
  const [actionForProgress, setActionForProgress] = useState([]);
  const [nursingActionItem, setNursingActionItem] = useState("");
  const [actionCompleted, setActionCompleted] = useState("");
  const [departmentWiseCount, setDepartmentWiseCount] = useState("");
  const [openNursingActionModal, setOpenNursingActionModal] = useState(0);
  const user = getUserInformation();

  useEffect(() => {
    let actionType =
      user.userdepartment === "*" ? "admin" : user.userdepartment;

    getProgressAction(actionType);
    getCompletedActions();
    getDepartmentWiseCounts();

    EventEmitter.on("AddNurseAction", async (data) => {
      await getProgressAction(data);  // Use await inside the async function
      getDepartmentWiseCounts();
    });

    EventEmitter.on("Acknowlodgement", (data) => {
        getProgressAction(data);
        getCompletedActions();
        getDepartmentWiseCounts();
    });
  }, []);

  const getProgressAction = async (actionType) => {
    setNurseActiveStep(actionType);
    const payload = await ApiCall_GET(
      `${ServiceUrls.GetProgressActions}/${actionType}`,
      true
    );
    setActionForProgress(replaceEmptyObjectsWithNull(payload));
  };

  const getCompletedActions = () => {
    ApiCall_GET(ServiceUrls.GetCompletedActions, true, true).then(function (payload) {
      setActionCompleted(replaceEmptyObjectsWithNull(payload));
    });
  };

  const getDepartmentWiseCounts = () => {
    ApiCall_GET(ServiceUrls.GetDepartmentWiseCounts, true, true).then(function (
      payload
    ) {
      setDepartmentWiseCount(replaceEmptyObjectsWithNull(payload));
    });
  };

  const handleAction = (value) => {
    setNurseActiveStep(value);
    getProgressAction(value);
  };

  const actionMap = {
    admin: "admin",
    nursing: "nursing",
    lab: "lab",
    clinician: "clinician",
    all: "all",
  };

  return (
    <div className="w-full  bg-white p-2 overflow-hidden h-[99%]">
      <div className={"pl-2 py-2 rounded flex items-center justify-between "}>
        <div className="flex items-center">
          {/* Admin Actions Tab */}
          <label
            onClick={() => handleAction(actionMap.admin)}
            className={
              "mr-4 font-bold cursor-pointer " +
              (nurseActiveStep === actionMap.admin
                ? `${theme.txt_color} border-b-[3px] border-b-amber-500`
                : "text-gray-400 border-b-[3px] border-b-gray-400")
            }
          >
            <label className=" text-left ">
              Admin Actions (
              <label className={" text-sm "}>
                #{departmentWiseCount.AdminCount}
              </label>
              )
            </label>
          </label>
          {/* Nurse Actions Tab */}
          <label
            onClick={() => handleAction(actionMap.nursing)}
            className={
              "ml-4 font-bold cursor-pointer " +
              (nurseActiveStep === actionMap.nursing
                ? `${theme.txt_color} border-b-[3px] border-b-amber-500`
                : "text-gray-400 border-b-[3px] border-b-gray-400")
            }
          >
            <label className=" text-left ">
              Nurse Actions (
              <label className={" text-sm "}>
                #{departmentWiseCount.NursingCount}
              </label>
              )
            </label>
            {/* Nurse Actions {departmentWiseCount.NursingCount} */}
          </label>

          {/* Lab Actions Tab */}
          <label
            onClick={() => handleAction(actionMap.lab)}
            className={
              "ml-4 font-bold cursor-pointer " +
              (nurseActiveStep === actionMap.lab
                ? `${theme.txt_color} border-b-[3px] border-b-amber-500`
                : "text-gray-400 border-b-[3px] border-b-gray-400")
            }
          >
            <label className=" text-left ">
              Lab Actions (
              <label className={" text-sm "}>
                #{departmentWiseCount.LabCount}
              </label>
              )
            </label>
            {/* Lab Actions {departmentWiseCount.LabCount} */}
          </label>

          {/* Clinician Actions Tab */}
          <label
            onClick={() => handleAction(actionMap.clinician)}
            className={
              "ml-4 font-bold cursor-pointer " +
              (nurseActiveStep === actionMap.clinician
                ? `${theme.txt_color} border-b-[3px] border-b-amber-500`
                : "text-gray-400 border-b-[3px] border-b-gray-400")
            }
          >
            <label className=" text-left ">
              Clinician Actions (
              <label className={" text-sm "}>
                #{departmentWiseCount.ClinicianCount}
              </label>
              )
            </label>
            {/* Clinician Actions {departmentWiseCount.ClinicianCount} */}
          </label>

          {/* General Actions Tab */}
          <label
            onClick={() => handleAction(actionMap.all)}
            className={
              "ml-4 font-bold cursor-pointer " +
              (nurseActiveStep === actionMap.all
                ? `${theme.txt_color} border-b-[3px] border-b-amber-500`
                : "text-gray-400 border-b-[3px] border-b-gray-400")
            }
          >
            <label className=" text-left ">
              General Actions (
              <label className={" text-sm "}>
                #{departmentWiseCount.GeneralCount}
              </label>
              )
            </label>
            {/* General Actions {departmentWiseCount.GeneralCount} */}
          </label>
        </div>

        <button
          onClick={() => setOpenNursingActionModal(2)}
          className="flex items-center cursor-pointer justify-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:text-black  hover:bg-green-600 hover:text-white focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2"
        >
          <AddIcon fontSize="small" />
          <label className=" cursor-pointer">Add Action</label>
        </button>
      </div>

      <div className={`flex gap-x-2  w-full h-full`}>
        <div className={`w-2/3`}>
          {openNursingActionModal == 1 && (
            <ActionDailog
              nursingActionItem={nursingActionItem}
              setOpenNursingActionModal={setOpenNursingActionModal}
              coupleid={coupleid}
              nurseActiveStep={nurseActiveStep}
            />
          )}
          {openNursingActionModal == 2 && (
            <AddAction
              setOpenNursingActionModal={setOpenNursingActionModal}
            />
          )}

          {Object.values(actionMap).includes(nurseActiveStep) && (
            <ActionItem
              actionsInProgress={actionForProgress}
              coupleid={coupleid}
            />
          )}
        </div>
        <div className={`w-1/3`}>
          <CompletedActions
            actionsCompleted={actionCompleted}
          ></CompletedActions>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { ApiCall_GET };

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
