// eventEmitter.js
const EventEmitter = () => {
  const events = {};

  const on = (event, listener) => {
    if (!events[event]) {
      events[event] = [];
    }
    events[event].push(listener);
  };

  const emit = (event, data) => {
    if (events[event]) {
      events[event].forEach(listener => listener(data));
    }
  };

  return { on, emit };
};

export default EventEmitter();
