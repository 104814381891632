import React, { useEffect, useState } from "react";
import { ApiCall_POST, ApiCall_GET } from "../../../Redux/Generic/action";
import { connect } from "react-redux";
import { useAppContext } from "../../../AppContextProvider ";
import PinModal from "../PinModal";
import {
    formatTime,
    formatTimeDifference,
    getCorrectDate,
    getCycleTypeColor,
    getEnumValueById,
    getTextColor,
    replaceEmptyObjectsWithNull,
} from "../../../common";
import EventEmitter from "../../../Common/EventEmitter";
import ServiceUrls from "../../../Common/ServiceUrls";

function Acknowledgement({
    ApiCall_POST,
    ApiCall_GET,
    coupleid,
    nursingActionItem,
    setOpenNursingActionModal,
    fromActionDailog = false,
    openNursingActionModal,
}) {

    const { nurseActiveStep } = useAppContext();
    const theme = useAppContext().themeDetails;
    const [formData, setFormData] = useState({
        actionId: nursingActionItem.actionId,
        closedComment: "",
        coupleid: coupleid,
    });
    const [showModal, setShowModal] = useState(false);
    const { openAcknowlodgment, setOpenAcknowlodgment } = useAppContext()


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        setShowModal(true);
    };

    const handleSubmit = async (e) => {
        ApiCall_POST(ServiceUrls.SaveCycleAction, formData, true).then(function (
            payload
        ) {
            setOpenAcknowlodgment(false);
            setOpenNursingActionModal(0);
            EventEmitter.emit("Acknowlodgement", nurseActiveStep); // Emit event with data
        });
    };

    return (
        <div className="fixed z-50 inset-0 overflow-y-auto ">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    onClick={() => { setOpenAcknowlodgment(false); setOpenNursingActionModal(0) }}
                    style={{ backdropFilter: "blur(7px)" }}
                    className="fixed inset-0 transition-opacity filter"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-gray-700 opacity-70  "></div>
                </div>
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl "
                    aria-hidden="true"
                ></span>
                {/* <Draggable> */}
                <div
                    className={`sm:my-8   sm:max-w-lg sm:w-full filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`}
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative  `}>
                        <svg
                            className={
                                " absolute top-2 right-2 cursor-pointer shadow  font-semibold  "
                            }
                            onClick={() => { setOpenAcknowlodgment(false); setOpenNursingActionModal(0) }}
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                className="hover:shadow   "
                                d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z"
                                fill="#ed4545"
                            />
                        </svg>
                        <h3
                            className={
                                theme.txt_color +
                                " text-xl text-center mb-6 mt-2 leading-6 font-bold text-gray-900"
                            }
                            id="modal-headline"
                        >
                            Confirming completion of an Action
                        </h3>
                        <div
                            className=" my-2 w-full border rounded-md shadow items-center bg-gray-100 p-4  rounded-lg"
                            onMouseDown={(e) => e.stopPropagation()}
                        >
                            <div className="mb-4">
                                <div
                                    id="alert-4"
                                    className="flex my-1 items-center self-end shadow p-1 px-2 mr-0 justify-center border rounded-lg bg-red-300 "
                                    role="alert"
                                >
                                    <svg
                                        className="flex-shrink-0 w-4 h-4"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#11381f"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                    </svg>
                                    <span className="sr-only">Info</span>
                                    <div className="ms-3 text-sm font-medium">
                                        <i>
                                            {" "}
                                            * This is a significant database request. You are
                                            confirming that you've consciously done all you can to
                                            ascertain that this action has been completed. Actions are
                                            mandates from Clinicians and Senior Nurses who have
                                            entered important NEW information requiring action in the
                                            best interests and with due-respect to the patient/s.
                                        </i>
                                    </div>
                                </div>
                                <div
                                    className={
                                        nursingActionItem?.itemtype +
                                        ` p-2 w-full  mb-1 items-center rounded ${theme.tableBg} border shadow border-[${nursingActionItem.NoteColor}] `
                                    }
                                >
                                    <div className={"px-2"}>
                                        <p className="text-sm text-gray-700 font-semibold ">
                                            <span className="text-red-600"> {nursingActionItem.actionTypeName}  </span>
                                            by ~{" "}
                                            {nursingActionItem.AddedByName ? (
                                                <i>
                                                    <b>{nursingActionItem.AddedByName}</b>
                                                </i>
                                            ) : (
                                                " "
                                            )}{" "}
                                            on ~{" "}
                                            {nursingActionItem.addedDate && (
                                                <>
                                                    {" "}
                                                    <i><b>{getCorrectDate(nursingActionItem.addedDate)}{" "}</b></i>{" "}
                                                    at{" "}
                                                    <i><b>{formatTime(nursingActionItem.addedDate, true)}</b></i>{" "}
                                                </>
                                            )}{" "}
                                            {nursingActionItem.addedDateOnly
                                                ? " ( " + formatTimeDifference(nursingActionItem.addedDateOnly) + " ) "
                                                : ""}
                                        </p>
                                        <p className="text-sm3 font-medium">
                                           {/*  {nursingActionItem.actionTypeName}:{" "} */}
                                            {nursingActionItem.actionDesc} - for{" "}
                                            {nursingActionItem.PatientSurname},{" "}
                                            {nursingActionItem.PartnerFirstname}
                                        </p>
                                    </div>
                                    <div
                                        className={
                                            "px-2 flex items-center gap-x-4 rounded-md " +
                                            theme.hoverBg
                                        }
                                    >
                                        {nursingActionItem?.actionActivityName ==
                                            "Treatment Plan" ? (
                                            <div className=" text-sbase mt-1 text-gray-700 flex items-center">
                                                {getEnumValueById(
                                                    nursingActionItem?.ProcedureTypeId
                                                ) === "---" ? (
                                                    ""
                                                ) : (
                                                    <div
                                                        className={`flex flex-col h-7 w-7 shadow rounded items-center justify-center  `}
                                                        style={{
                                                            backgroundColor:
                                                                getCycleTypeColor(
                                                                    getEnumValueById(
                                                                        nursingActionItem?.ProcedureTypeId
                                                                    )
                                                                        ?.split(" ")[0]
                                                                        ?.trim()
                                                                ) || "gray",
                                                        }}
                                                    >
                                                        <label
                                                            className={` text-xss text-center font-semibold  w-6 overflow-hidden  text-${getTextColor(
                                                                getCycleTypeColor(
                                                                    nursingActionItem?.ProcedureTypeId
                                                                )
                                                            )}`}
                                                        >
                                                            {getEnumValueById(
                                                                nursingActionItem?.ProcedureTypeId
                                                            )}
                                                        </label>
                                                        <div className="rounded bg-white flex   items-center justify-center h-3 w-5 my-0.5">
                                                            {nursingActionItem?.version && (
                                                                <label
                                                                    className={`  text-smm font-bold   text-gray-600`}
                                                                >
                                                                    v{nursingActionItem?.version}
                                                                </label>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div
                                            className={
                                                "text-left  cursor-pointer items-center gap-x-3 mr-2 px-1 rounded "
                                            }
                                        >
                                            {nursingActionItem.PatientSurname && <label className={" text-xss font-semibold  "}>
                                                Patient: {nursingActionItem.PatientSurname}
                                            </label>}
                                            {getCorrectDate(nursingActionItem.PatientDOB) && <p className="text-xss text-gray-600 font-medium">
                                                DOB : {getCorrectDate(nursingActionItem.PatientDOB)}
                                            </p>}
                                        </div>
                                        <div
                                            className={
                                                "text-left  cursor-pointer items-center gap-x-3 mr-2 px-1 rounded "
                                            }
                                        >
                                            {nursingActionItem.PartnerFirstname && <label className={" text-xss font-semibold  "}>
                                                Partner: {nursingActionItem.PartnerFirstname}
                                            </label>}
                                            {getCorrectDate(nursingActionItem.PartnerDOB) && <p className="text-xss text-gray-600 font-medium">
                                                DOB : {getCorrectDate(nursingActionItem.PartnerDOB)}
                                            </p>}
                                        </div>
                                        <div
                                            className={
                                                "text-left  cursor-pointer items-center gap-x-3 mr-2 px-1 rounded "
                                            }
                                        >
                                            <label className={" text-xss font-semibold "}>
                                                {nursingActionItem.couplecode}
                                            </label>
                                            <p className="text-xss text-gray-600 font-medium">
                                                {nursingActionItem?.clinicianName}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!fromActionDailog && (
                                <div className="mb-4">
                                    <label
                                        className="block text-sm font-medium text-gray-700"
                                        htmlFor="closedComment"
                                    >
                                        Comment
                                    </label>
                                    <textarea
                                        name="closedComment"
                                        value={formData.closedComment || ""}
                                        onChange={(e) => handleChange(e)}
                                        rows="3"
                                        className={`border-gray-300'  focus:bg-white border  focus:bg-white m-0 w-full  rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500`}
                                        placeholder="Enter comment here..."
                                    ></textarea>
                                </div>
                            )}
                            <div className="border py-2 rounded flex justify-end items-center  px-6">
                                <button
                                    className="bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded"
                                    onClick={() => { setOpenAcknowlodgment(false); setOpenNursingActionModal(0) }}
                                >
                                    {" "}
                                    Cancel{" "}
                                </button>
                                <button
                                    type="submit"
                                    className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded"
                                    onClick={validateForm}
                                >
                                    {" "}
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <PinModal
                    title="Saving Couple Details"
                    setShowModal={setShowModal}
                    successFunction={(e) => handleSubmit(e)}
                />
            )}
        </div>
    );
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = { ApiCall_POST, ApiCall_GET };

export default connect(mapStateToProps, mapDispatchToProps)(Acknowledgement);
