import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useAppContext } from "../../../AppContextProvider ";
import {
  capitalizeName,
  formatTime,
  formatTimeDifference,
  getCorrectDate,
  getCycleTypeColor,
  getEnumValueById,
  getSubstringByLength,
  getTextColor,
  getUserInformation,
  priority,
  replaceEmptyObjectsWithNull,
} from "../../../common";
import { ApiCall_GET, ApiCall_POST } from "../../../Redux/Generic/action";
import AddNurseAction from "./AddAction";
import ActionDailog from "./ActionDailog";
import AddIcon from "@mui/icons-material/Add";
import { svgHandler } from "../../../Utils";
import Swal from "sweetalert2";

export const CompletedActions = ({ actionsCompleted }) => {
  const theme = useAppContext().themeDetails;
  const user = getUserInformation();

  return (
    <div className={` ${theme.track} ${theme.thumb} w-full bg-white  rounded-md shadow  overflow-y-auto scroll scrollbar-thin height-80`}    >
      <div className={theme.navbar + " p-2  text-gray-900 text-left shadow rounded-t"}>
        <p className="text-white text-sbase font-medium ">Actions Completed Today</p>
      </div>
      <div className={`w-full px-2`}>
        {actionsCompleted.length > 0 ?
          actionsCompleted.map((item) => (
            <div className={"item.NoteColor" + ` p-2 w-full  mt-1 items-center rounded ${theme.tableBg} border  shadow border-[${"item.NoteColor"}] `}>
              <div className={"px-2"}>
                <p className="text-sm text-gray-700 font-semibold ">
                  <span className={item.CloseAt !== ""
                    ? "text-green-600 "
                    : " "}>
                    {item.CloseAt ? 'Completed' : ''}  {` ~ `}
                  </span>
                  {capitalizeName(item.actionTypeDept)} {" - "} {item.actionTypeName}:{" "}
                  {/* {item.PatientSurname}, {item.PartnerFirstname} */}
                </p>
                {/* <p className="text-sm3 font-medium">
                  {capitalizeName(item.actionTypeDept)}-{item.actionTypeName}:{" "}
                  {item.PartnerSurname}, {item.PartnerFirstname}
                </p> */}
              </div>
              <div className={"px-2 flex gap-x-4 rounded-md " + theme.hoverBg}>
                {item?.actionActivityName == "Treatment Plan" ? (
                  <div className=" text-sbase  text-gray-700 flex items-center">
                    {getEnumValueById(item?.ProcedureTypeId) === "---" ? (
                      ""
                    ) : (
                      <div
                        className={`flex flex-col h-7 w-7 shadow rounded items-center justify-center  `}
                        style={{
                          backgroundColor:
                            getCycleTypeColor(
                              getEnumValueById(item?.ProcedureTypeId)
                                ?.split(" ")[0]
                                ?.trim()
                            ) || "gray",
                        }}
                      >
                        <label
                          className={` text-xss text-center font-semibold  w-6 overflow-hidden  text-${getTextColor(
                            getCycleTypeColor(item?.ProcedureTypeId)
                          )}`}
                        >
                          {getEnumValueById(item?.ProcedureTypeId)}
                        </label>
                        <div className="rounded bg-white flex   items-center justify-center h-3 w-5 my-0.5">
                          {item?.version && (
                            <label className={`  text-smm font-bold   text-gray-600`}>v{item?.version}</label>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}

                <div className={"text-left  cursor-pointer items-center gap-x-3 pr-2 p-1 rounded "}>
                  {item.PatientFirstname && <label className={" text-xss font-semibold  "}>
                    Patient: {item.PatientFirstname}, {item.PatientFirstname}
                  </label>}
                  {getCorrectDate(item.PatientDOB) && <p className="text-xss text-gray-600 font-medium">
                    DOB : {getCorrectDate(item.PatientDOB)}</p>}
                </div>
                <div className={"text-left  cursor-pointer items-center gap-x-3 pr-2 p-1 rounded "}>
                  {item.PartnerFirstname && <label className={" text-xss font-semibold  "}>
                    Partner: {item.PartnerFirstname}, {item.PartnerSurname}
                  </label>}
                  {getCorrectDate(item.PatientDOB) && <p className="text-xss text-gray-600 font-medium">
                    DOB : {getCorrectDate(item.PatientDOB)}
                  </p>}
                </div>

                <div className={"text-left  cursor-pointer items-center gap-x-3 pr-2 p-1 rounded "}>
                  <label className={" text-xss font-semibold "}>
                    {item.couplecode}
                  </label>
                  <p className="text-xss text-gray-600 font-medium">
                    {item.clinicianName}
                  </p>
                </div>
              </div>

              {item.ClosedComment && (
                <p className="text-sm pl-2 text-gray-700 font-semibold ">
                  Comment ~ {item.ClosedComment}
                </p>
              )}
              <p className="text-sm text-gray-700 font-semibold text-end">
                ~ Completed by ~{" "}{item.ClosedByName ? (
                  <i><b>{item.ClosedByName}</b></i>
                ) : (
                  " ")}
                {" "}
                on ~{" "}
                {item.CloseAt && (
                  <>
                    {" "}
                    <i><b>{item.CloseAt} </b></i>{" "}at{" "}<i><b>{formatTime(item.closedDate, true)}</b></i>{" "}
                  </>
                )}{" "}
                {item.closedDate
                  ? " ( " + formatTimeDifference(item.closedDate) + " ) "
                  : ""}
              </p>
            </div>
          ))
          :
          <div id="alert-4" className="flex justify-center w-full self-end shadow p-2  mb-2 mr-0 text-[#664d03] border rounded-lg bg-yellow-100  dark:text-yellow-300" role="alert">
            <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#664d03" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only">Info</span>
            <div className="ms-3 text-sm  font-medium text-[#664d03]">
              <i> ~ No Actions Have Been Completed Yet ~</i>
            </div>
          </div>}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompletedActions);
