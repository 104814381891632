const ServiceUrls = {
    sendEmail: 'UserManagment/SendEmail',
    //BEGIN :::: ACTION SECTION
    GetCompletedActions: 'Actions/GetCompletedActions',
    GetProgressActions: 'Actions/GetProgressActions',
    SaveCycleAction: 'Actions/SaveCycleAction',
    GetAllCycleActionType: 'Actions/GetAllCycleActionType',
    SaveCycleAction: 'Actions/SaveCycleAction',
    GetDepartmentWiseCounts: 'Actions/GetDepartmentWiseCounts',
    GetTotalActionCount: 'Actions/GetTotalActionCount',
    //END :::: ACTION SECTION

    //BEGIN :::: NOTICATION SECTION
    GetCoupleNotifications: 'Couple/GetCoupleNotifications/',
    CreateNotificationAsync: 'Couple/CreateNotificationAsync/',
    //BEGIN :::: NOTICATION SECTION

    //BEGIN :::: PRETREATMENT PLAN SECTION
    GetCouplePreworkupByCoupleID: 'PreTreatmentPlan/GetCouplePreworkupByCoupleID/',
    GetCoupleClinicalHistory: 'PreTreatmentPlan/GetCoupleClinicalHistory/',
    GetActiveCouplePreworkupStatus: 'PreTreatmentPlan/GetActiveCouplePreworkupStatus',
    GetAllCouplePreworkupItemtype: 'PreTreatmentPlan/GetAllCouplePreworkupItemtype/',
    SavePreTreatmentPlan: 'PreTreatmentPlan/SavePreTreatmentPlan',
    SavePreTreatmentPlanItem: 'PreTreatmentPlan/SavePreTreatmentPlanItem',
    InsertProcessCalendarAppointments: 'PreTreatmentPlan/InsertProcessCalendarAppointments',
    //BEGIN :::: PRETREATMENT PLAN SECTION

};

export default ServiceUrls;