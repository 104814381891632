import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useAppContext } from "../../../../../AppContextProvider ";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiCall_GET } from "../../../../../Redux/Generic/action";
import { replaceEmptyObjectsWithNull } from "../../../../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { renderDataOnLoad } from "../../../../../Utils";
import OutcomeView from "./OutcomeView";

export const Outcome = ({ ApiCall_GET }) => {

  let location = useLocation();
  var artid = location?.state?.artid;
  var coupleid = location.state.coupleid;
  var artData = location.state.artData;
  var IsCalledByView = location.state.IsCalledByView;
  const theme = useAppContext().themeDetails ?? {};
  let navigate = useNavigate();
  const [outcomeData, setOutcomeData] = useState([]);

  const [show, setShow] = useState(false);
  const [outcome, setOutcome] = useState(null);
  useEffect(() => {

    apicall()
  }, [])

  const apicall = () => {
    ApiCall_GET('Patient/GetCycleOutcome/' + coupleid, true).then(function (payload) {
      setOutcomeData(replaceEmptyObjectsWithNull(payload))
    });
  }

  const handleView = (e) => {
    setShow(true)
    setOutcome(e)
  }

  return (
    <div className={" bg-white  mb-3 pt-3 p-2 w-full "}>
      <div className={theme.bg_Light + "  p-2   flex gap-2 items-center justify-between shadow-md w-full "} >
        <label className={' pl-1 text-base font-medium '}>Outcome</label>
        <button onClick={() => { navigate('/runningSheet', { state: { coupleid: coupleid, artData: artData, IsCalledByView: IsCalledByView } }) }} className="flex items-center cursor-pointer justify-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:text-black hover:bg-yellow-400 focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1">
          <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.15716 0.607836C5.26072 0.711393 5.3125 0.834005 5.3125 0.975672C5.3125 1.11734 5.26072 1.23981 5.15716 1.34309L2.12293 4.37732L5.16752 7.42191C5.26417 7.51856 5.3125 7.63938 5.3125 7.78436C5.3125 7.92934 5.26072 8.05361 5.15716 8.15716C5.05361 8.26072 4.93099 8.3125 4.78933 8.3125C4.64766 8.3125 4.52519 8.26072 4.42191 8.15716L0.942381 4.66728C0.900958 4.62586 0.871548 4.58098 0.85415 4.53266C0.836753 4.48433 0.828192 4.43255 0.828468 4.37732C0.828468 4.32209 0.837167 4.27031 0.854564 4.22199C0.871962 4.17366 0.901234 4.12878 0.942381 4.08736L4.43226 0.59748C4.52892 0.500827 4.64808 0.4525 4.78974 0.4525C4.93141 0.4525 5.05388 0.504279 5.15716 0.607836Z" fill="#52575C" />
          </svg>
          <label className='pl-2 cursor-pointer'> Back to Running Sheet</label>
        </button>
      </div>
      <div className={theme.thumb + " " + theme.track + "   overflow-auto h-[70vh] scrollbar pt-2 scrollbar-thin w-full "} style={{ width: "100%" }}>
        {/* table header */}
        <div className={"w-full flex py-1 text-white rounded " + theme.navbar}>
          <div
            style={{ width: "3%" }}
            className={"flex item-center justify-center py-1"} >
            <p className="text-sm font-bold">#</p>
          </div>
          <div style={{ width: "10%" }} className={"py-1"}>
            <p className="text-sm font-bold">Day One</p>
          </div>
          <div style={{ width: "15%" }} className={"py-1"}>
            <p className="text-sm font-bold">Procedure Type</p>
          </div>
          <div style={{ width: "10%" }} className={"py-1"}>
            <p className="text-sm font-bold">Donor Code</p>
          </div>
          <div style={{ width: "12%" }} className={"py-1"}>
            <p className="text-sm font-bold">Clinician</p>
          </div>
          <div style={{ width: "15%" }} className={"py-1"}>
            <p className="text-sm font-bold">Preg Test Status</p>
          </div>
          <div style={{ width: "15%" }} className={"py-1"}>
            <p className="text-sm font-bold">Clinical Preg</p>
          </div>
          <div style={{ width: "10%" }} className={"py-1 "}>
            <p className="text-sm font-bold">Delivery Status</p>
          </div>
          <div style={{ width: "10%" }} className={"py-1 "}>
            <p className="text-sm font-bold">Action</p>
          </div>
        </div>
        {/* display data */}
        {outcomeData && outcomeData?.map((item, index) => (
          <div key={index} className={" w-full  flex  mb-0.5 pt-1 bg-[#DBE3DF] rounded shadow border items-center"}>
            <div
              style={{ width: "3%" }} className={"flex item-center justify-center py-3"} >
              <p className="text-sm font-medium text-gray-600">#</p>
            </div>
            <div style={{ width: "10%" }} className={"py-1 "}>
              <p className="text-sm font-medium text-gray-600">{item?.dayofcycle}</p>
            </div>
            <div style={{ width: "15%" }} className={"py-1"}>
              <p className="text-sm font-medium text-gray-600">{item?.cycletype} </p>
            </div >
            <div style={{ width: "10%" }} className={"py-1"}>
              <p className="text-sm font-medium text-gray-600">{item?.donorcode}</p>
            </div>
            <div style={{ width: "12%" }} className={"py-1"}>
              <p className="text-sm font-medium text-gray-600">{item?.username}</p>
            </div>

            <div style={{ width: "11%" }} className={"py-1"}>
              <p className="text-sm font-medium text-gray-600">{'BHCG: ' + (item?.multiBhcg > 1 ? " (Multi) " : " ") + (item?.bhcgtestresult || '')} {(item?.cycletype?.split(" ")[0] === "IUI" || item?.cycletype?.split(" ")[0] === "AIH") && ('Urine: ' + item?.urinetestresult)} </p>
            </div >

            <div style={{ width: "19%" }} className={"py-1"}>
              <p className="text-sm font-medium text-gray-600">{renderDataOnLoad(item).clinicalPregnancy}{' ART' + item?.artid}</p>
            </div>
            <div style={{ width: "10%" }} className={"py-1 "}>
              <p className="text-sm font-medium text-gray-600">{(item).outcomename1}</p>
            </div>
            <div style={{ width: "10%" }} className={"py-1 "}>

              <button onClick={(e) => { handleView(item) }}
                className="flex items-center cursor-pointer justify-center text-gray-500 bg-yellow-400 border border-gray-300 focus:outline-none hover:text-black  hover:bg-yellow-600 hover:text-white focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2">
                <FontAwesomeIcon icon={faEye} />
                <label className="pl-2 cursor-pointer">View</label>
              </button>
            </div>

          </div>
        ))}
      </div>
      {show && <OutcomeView outcomeData={outcome} setShow={setShow} artid={artid} apicall={apicall}
        coupleid={coupleid} />}

    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { ApiCall_GET };

export default connect(mapStateToProps, mapDispatchToProps)(Outcome);
