import React from 'react'
import { connect } from 'react-redux'
import logo from '../../../../../Assets/images/chaartlogo.png';
import clinicIcon from '../../../../../Assets/images/clinicIcon.png';
import { formatTime, generateScanInput, getCorrectDate, getScanResults, getUniqueObjectsByKey, replaceEmptyObjectsWithNull } from '../../../../../common';
import moment from 'moment'

export const DocterSheetPrint = ({ theme, doctorView, coupleDetails, artid, bfcomments, comments, user }) => {

    const handleMedication = (str, DisplayName) => {
        if (!str) { return ''; }
        const substrings = str.split(',')//.map(substring => substring.trim());
        return (
            <div className={' border-b-[1px]  border-b-gray-200 w-1/2 text-left py-0.5 p-1 flex   cursor-pointer ' + theme.hoverBg}>
                <label className=' text-xss color-green font-bold w-28 text-gray-700'> {DisplayName} </label>
                <div>
                    {substrings[0].length > 0 ? substrings.map((substring, index) => (
                        <div className={'  mr-1 text-xss text-gray-500 font-medium'} key={index}>
                            <label className={" text-xss w-2/6"} >{substring?.split('=>')[0] ? substring?.split('=>')[0] : '--'}</label>
                            {
                                <label className={" text-xss w-1/6 px-2"} >
                                    {substring?.split('Dosage=>')[1]?.split('=>')[0] ?
                                        '- ' + substring?.split('Dosage=>')[1]?.split('=>')[0] + ' -' :
                                        (
                                            substring?.split('Dose=>')[1]?.split('=>')[0] ?
                                                '- ' + substring?.split('Dose=>')[1]?.split('=>')[0] :
                                                (
                                                    substring?.split('=>')[1]?.split('=>')[0] ?
                                                        '- ' + substring?.split('=>')[1]?.split('=>')[0] + (substring?.split('=>')[2] ? ' - ' + substring?.split('=>')[2] : '') :
                                                        ''
                                                )
                                        )
                                    }</label>

                            }
                            <label className={" text-xss w-2/6 capitalize"} >{
                                substring.toLowerCase()?.split('when')[1] ?
                                    substring?.toLowerCase()?.split('when')[1]?.replaceAll('=>', '')?.replaceAll(':', '') :
                                    (substring.toLowerCase()?.split('start')[1] ?
                                        'Start Day ' + substring?.toLowerCase()?.split('start')[1]?.replaceAll('=>', ' - ').replaceAll('- finish', '/ Finish Day') : '')}
                            </label>
                            {/* <label className={" text-sm w-1/6"} >{DisplayName}</label> */}
                        </div>
                    )) : 'N/A'}
                </div>
            </div>


        );
    };

    return (
        <div id="divPrint" className=" flex min-h-screen  w-full p-1  delay-900 duration-700 pagebreak ">
            {/* Header */}
            <div className=" w-full  mx-auto ">
                <div className={'flex justify-between   '} >

                    <div className='flex items-center justify-end '>
                        <img src={logo} className="logoCFC" alt="img" width="170px" />
                        <p className='  text-base pr-2'>[ Beta ]</p>
                        {/* <img src={clinicIcon} className="h-3 pr-1" alt="img" /> */}
                        <p className={`dot-${user?.BrandCode?.toUpperCase()} `} style={{ backgroundColor: `#${user?.ChartingColor}` }}></p>

                        <p className='   text-sm ml-1'>{user?.LocationName}</p>
                        {/* <p className=' text-white  text-sm ml-1'>{user?.LocationName}</p> */}
                    </div>
                    <div className='flex justify-end  items-center '>
                        <label className='  text-sm'>{moment(new Date()).format('ddd DD-MMM-yyyy')}</label>
                    </div>
                </div>
                <label className={'mr-4 font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500'}> Doctor's Sheet</label>
                <div className={theme.tableBg + ' w-full flex flex-wrap mt-0  rounded px-2 py-1 ' + theme.borderColor}>

                    <div className='w-1/3  flex items-center justify-between '>
                        <label className='  text-xss '>Patient :</label>
                        <label className='  text-xss  text-gray-700 pr-3'>{coupleDetails?.PatientFullName}</label>
                    </div>
                    <div className='w-1/3  flex items-center justify-between '>
                        <label className='  text-xss '>Partner :</label>
                        <label className='  text-xss  text-gray-700 pr-3'>{coupleDetails?.PartnerFullName}</label>
                    </div>
                    <div className='w-1/3  flex items-center justify-between '>
                        <label className='  text-xss '>Clinician :</label>
                        <label className='  text-xss  text-gray-700 pr-3'>{coupleDetails?.clinicianFullName}</label>
                    </div>
                    <div className='w-1/3  flex items-center justify-between '>
                        <label className='  text-xss '>Patient DOB :</label>
                        <label className='  text-xss  text-gray-700 pr-3'>{coupleDetails?.PatientDOB}</label>
                    </div>

                    <div className='w-1/3  flex items-center justify-between '>
                        <label className='  text-xss '>Partner DOB :</label>
                        <label className='  text-xss  text-gray-700 pr-3'>{coupleDetails?.PartnerDOB}</label>
                    </div>

                    <div className='w-1/3  flex items-center justify-between '>
                        <label className='  text-xss '>Couple Code / ART ID:</label>
                        <label className='  text-xss  text-gray-700 pr-3'>{coupleDetails?.CoupleCode + ' ( ART' + artid + ' )'}</label>
                    </div>
                </div>

                <div className={theme.tableBg + ' w-full flex  flex-wrap  mt-0.5 rounded px-2 py-1 ' + theme.borderColor}>
                    <div className='w-1/3  flex items-center justify-between '>
                        <label className='  text-xss '>Embryoscope :</label>
                        <label className='  text-xss  text-gray-700 pr-3'>{doctorView?.DoctorDetails?.EmbryoScope}</label>
                    </div>
                    <div className='w-1/3  flex items-center justify-between '>
                        <label className='  text-xss '>Consents given: :</label>
                        <label className='  text-xss  text-gray-700 pr-3'></label>
                    </div>
                    <div className='w-1/3  flex items-center justify-between '>
                        <label className='  text-xss '>AMH :</label>
                        <label className='  text-xss  text-gray-700 pr-3'></label>
                    </div>
                    <div className='w-1/3  flex items-center justify-between '>
                        <label className='  text-xss '>Consent received:</label>
                        <label className='  text-xss  text-gray-700 pr-3'></label>
                    </div>

                    <div className='w-1/3  flex items-center justify-between '>
                        <label className='  text-xss '>IVF / ICSI :</label>
                        <label className='  text-xss  text-gray-700 pr-3'>{doctorView?.DoctorDetails?.IVFICSI} ({doctorView?.DoctorDetails?.IVFICSIDetails} ) </label>
                    </div>
                    <div className='w-1/3  flex items-center justify-between '>
                        <label className='  text-xss  '>FRESH / FROZEN :</label>
                        <label className='  text-xss   text-gray-700 pr-3'>{doctorView?.DoctorDetails?.FreshFrozen}</label>
                    </div>
                    <div className='w-1/3  flex items-center justify-between '>
                        <label className='  text-xss '>PARTNER / DONOR :</label>
                        <label className='  text-xss  text-gray-700 pr-3'>{doctorView?.DoctorDetails?.DonorPartner}</label>
                    </div>
                    <div className='w-1/3  flex items-center justify-between '>
                        <label className='  text-xss '>BLAST / DAY3 :</label>
                        <label className='  text-xss  text-gray-700 pr-3'>{doctorView?.DoctorDetails?.BlastocystDay3}</label>
                    </div>
                    <div className='w-1/3  flex items-center justify-between '>
                        <label className='  text-xss '>CLOTTING DISORDER Y/N :</label>
                        <label className='  text-xss  text-gray-700 pr-3'></label>
                    </div>
                    <div className='w-1/3  flex items-center justify-between '>
                        <label className='  text-xss '># Embryos in store :</label>
                        <label className='  text-xss  text-gray-700 pr-3'>{doctorView?.DoctorDetails?.NoOfEmbryo}</label>
                    </div>
                    <div className='w-1/3 flex items-center justify-between '>
                        <label className='  text-xss '>Day 1 Date :</label>
                        <label className='  text-xss  text-gray-700 pr-3'>{getCorrectDate(doctorView?.DoctorDetails?.DayOneOfCycle)}</label>

                    </div>
                    <div className='w-1/3  flex items-center justify-between '>
                        <label className='  text-xss '>Trigger Date :</label>
                        <label className='  text-xss  text-gray-700 pr-3'>{getCorrectDate(doctorView?.DoctorDetails?.TriggerDate)}</label>
                    </div>
                    <div className='w-1/3  flex items-center justify-between '>
                        {/* <div className='w-full flex justify-between pr-3'> */}
                        <label className='  text-xss '>EPU Date :</label>
                        <label className='  text-xss  text-gray-700 pr-3'>{getCorrectDate(doctorView?.DoctorDetails?.DateOfEPU)}</label>
                    </div>
                    <div className='w-1/3  flex items-center justify-between '>

                        <label className='  text-xss '>Transfer Date :</label>
                        <label className='  text-xss  text-gray-700 pr-3'>{getCorrectDate(doctorView?.DoctorDetails?.TransferDate)}</label>
                    </div>

                </div>
                {/* In-Cycle Scan Result -------------*/}
                <div className='w-full flex  mt-0.5 '>
                    {doctorView?.ScanDetails.ScanDetailList[0]?.scanresultid > 0 &&
                        <div className='w-[57%]  mr-1'>
                            <div className={'shadow mt-0.5 rounded ' + theme.borderColor}>
                                <div className={theme.tHedUpdte + ' text-white text-left p-1 flex rounded-t '}>
                                    <p className='text-sbase font-medium '>In-Cycle Scan Result </p>
                                </div>
                                {doctorView?.ScanDetails.ScanDetailList[0]?.scanresultid > 0 &&
                                    <div className='flex '>
                                        <div style={{ width: "10%" }} className='  items-center  border'>
                                            <p className={'  text-sm   pl-0.5   bg-gray-200  h-10  '}></p>
                                            <p className={'  text-sm    h-4 bg-gray-200   '}></p>
                                            <p className={'  text-sm    h-4 bg-gray-200   '}></p>
                                            <p className={'  text-sm    h-4 bg-gray-200   '}></p>
                                            <p className={' text-center text-sm   text-left   bg-gray-200 border  '}>Size</p>
                                            <p className={' text-center text-sm  text-left p   border  '}>{'>'}23</p>
                                            <p className={' text-center text-sm  text-left p   border  '}>23</p>
                                            <p className={' text-center text-sm  text-left p   border  '}>22</p>
                                            <p className={' text-center text-sm   text-left   border  '}>21</p>
                                            <p className={' text-center text-sm   text-left p   border  '}>20</p>
                                            <p className={' text-center text-sm   text-left p   border  '}>19</p>
                                            <p className={' text-center text-sm   text-left p   border  '}>18</p>
                                            <p className={' text-center text-sm  text-left p   border  '}>17</p>
                                            <p className={' text-center text-sm  text-left p   border  '}>16</p>
                                            <p className={' text-center text-sm  text-left p   border  '}>15</p>
                                            <p className={' text-center text-sm  text-left p   border  '}>14</p>
                                            <p className={' text-center text-sm  text-left p   border  '}>13</p>
                                            <p className={' text-center text-sm  text-left p   border  '}>12</p>
                                            <p className={' text-center text-sm  text-left p   border  '}>11</p>
                                            <p className={' text-center text-sm  text-left p   border  '}>10</p>
                                            <p className={' text-center text-sm  text-left p   border  '}>{'<'}10</p>
                                            <p className={' text-center text-sm  text-left h-10 bg-gray-200 border border-t  '}></p>

                                        </div>

                                        {doctorView?.ScanDetails.ScanDetailList.map((item, index) => (index <= 2 ?
                                            <>
                                                <div style={{ width: "30%" }} className='border border-l-gray-400'>
                                                    <div className='flex justify-between bg-gray-200'>
                                                        <p className={'w-1/2 text-xss  text-left px-1  font-medium     '}>Day <span className='font-normal'>{item.scandayofcycle}</span></p>
                                                        <p className={'w-1/2 text-xss  bg-gray-200  pl-2 font-medium border-l-gray-400  border  '}>Endo: <span className='font-normal'>{item.result_endo}</span></p>
                                                    </div>
                                                    <p className={' text-xss  text-left px-1   bg-gray-200 border  font-medium '}>Scan date :  <span className='font-normal'>{getCorrectDate(item.scandate)}</span></p>
                                                    <p className={' text-xss  text-left px-1   bg-gray-200 border  font-medium '}>Clinic :<span className='font-normal'>{item.ClinicName}</span></p>
                                                    <p className={' text-xss  text-left px-1   bg-gray-200 border  font-medium '}>P. Location :<span className='font-normal text-sm3 '>{item.HospitalName}</span></p>

                                                    <p className={' text-xss bg-gray-200  px-1 font-medium  border '}>Utrus: <span className='font-normal'>{item.result_uterus}</span></p>
                                                    <div className='w-full flex'>
                                                        <div className='w-1/2    border'>

                                                            <p className={' text-center text-sm  text-left h-5 bg-gray-200 border-gray-300  border  '}>L</p>
                                                            {Array.from({ length: 16 }).map((_, i) => (
                                                                <p className={' text-center text-sm  text-left h-5    border  '}>{generateScanInput(item.result_l).split(',').length == 16 ? generateScanInput(item.result_l).split(',')[i] : ''}</p>
                                                            ))}
                                                        </div>
                                                        <div className='w-1/2    border'>
                                                            <p className={' text-center text-sm  text-left h-5 bg-gray-200   border border-gray-300 '}>R</p>
                                                            {Array.from({ length: 16 }).map((_, i) => (
                                                                <p className={' text-center text-sm  text-left h-5    border  '}>{generateScanInput(item.result_r).split(',').length == 16 ? generateScanInput(item.result_r).split(',')[i] : ''}</p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <p className={'  text-sm px-2 border elepsis w-28 truncate w-full '}>L : {getScanResults(generateScanInput(item.result_l), true)}</p>
                                                    <p className={'  text-sm px-2 border elepsis w-28 truncate w-full '}>R : {getScanResults(generateScanInput(item.result_r), true)}</p>

                                                </div>
                                            </>


                                            : ''))}

                                        {Array.from({ length: (3 - doctorView?.ScanDetails.ScanDetailList.length) }).map((_, i) => (
                                            <div style={{ width: "30%" }} className='border border-l-gray-400'>
                                                <div className='flex justify-between bg-gray-200'>
                                                    <p className={'w-1/2 text-xss  text-left px-1  font-medium     '}>Day <span className='font-normal'>--</span></p>
                                                    <p className={'w-1/2 text-xss  bg-gray-200  pl-2 font-medium border-l-gray-400  border  '}>Endo: <span className='font-normal'>--</span></p>
                                                </div>
                                                <p className={' text-xss  text-left px-2   bg-gray-200 border  font-medium '}>Scan date :  <span className='font-normal'>--</span></p>
                                                <p className={' text-xss  text-left px-2   bg-gray-200 border  font-medium '}>Clinic :<span className='font-normal'>--</span></p>
                                                <p className={' text-xss  text-left px-2   bg-gray-200 border  font-medium '}>Procedure Location:<span className='font-normal'>-- <t>{" "} </t></span></p>
                                                <p style={{ width: "45%" }} className={' text-xss  text-left px-2   bg-gray-200 border  font-medium   '}>Utrus: <span className='font-normal'>--</span></p>

                                                <div className='w-full flex'>
                                                    <div className='w-1/2    border'>

                                                        <p className={' text-center text-sm  text-left h-5 bg-gray-200 border-gray-300  border  '}>L</p>
                                                        {Array.from({ length: 14 }).map((_, i) => (
                                                            <p className={' text-center text-sm  text-left h-5    border  rounded-t'}></p>
                                                        ))}
                                                    </div>
                                                    <div className='w-1/2    border'>
                                                        <p className={' text-center text-sm  text-left h-5 bg-gray-200   border border-gray-300 '}>R</p>
                                                        {Array.from({ length: 14 }).map((_, i) => (
                                                            <p className={' text-center text-sm  text-left h-5    border  rounded-t'}></p>
                                                        ))}
                                                    </div>
                                                </div>
                                                <p className={'  text-sm px-2 border  rounded-t  '}>L : </p>
                                                <p className={'  text-sm px-2 border  rounded-t  '}>R : </p>


                                            </div>
                                        ))}  </div>
                                }
                            </div>
                        </div>
                    }
                    <div className='w-[43%]  '>
                        {doctorView?.BloodTestDetails[0].bloodtestid > 0 ?
                            <>
                                <div className={'shadow mt-0.5 rounded ' + theme.borderColor}>
                                    <div className={theme.tHedUpdte + ' text-white text-left p-1 flex rounded-t '}>
                                        <p className='text-sbase font-medium '>In-Cycle Blood Test </p>
                                    </div>
                                    {/* <label className={'mr-4  font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500  mt-2'}>In-Cycle Blood Test</label> */}
                                    {doctorView?.BloodTestDetails.map((item, index) =>
                                        index <= 2 ?
                                            <div className='w-full mb-0.5 border rounded '>
                                                <div className='bg-white rounded   flex flex-wrap p-1 items-center w-full  border-b '>
                                                    {item?.testdayofcycle &&
                                                        <div className=' flex  border-r  px-1 py-0.5'>
                                                            <label className='text-xss text-black  '>LMP</label>
                                                            <label className='text-xss text-gray-500  rounded-md pl-1 '>{item?.testdayofcycle} </label>
                                                        </div>}
                                                    <div className=' flex   border-r px-1 py-0.5'>
                                                        <label className='text-xss text-black  '>Date</label>
                                                        <label className='text-xss text-gray-500   rounded-md pl-1 '>{getCorrectDate(item.testdate)} </label>
                                                    </div>
                                                    <div className=' flex   border-r px-1 py-0.5'>
                                                        <label className='text-xss text-black  '>E2 pmol/L</label>
                                                        <label className='text-xss text-gray-500   rounded-md pl-1 '>{item.e2_dose} </label>
                                                    </div>
                                                    <div className=' flex   border-r px-1 py-0.5'>
                                                        <label className='text-xss text-black  '>P4 nmol/L</label>
                                                        <label className='text-xss text-gray-500   rounded-md pl-1 '>{item.p4_dose} </label>
                                                    </div>
                                                    <div className=' flex   border-r px-1 py-0.5'>
                                                        <label className='text-xss text-black '>LH IU/L</label>
                                                        <label className='text-xss text-gray-500   rounded-md pl-1 '>{item.lh_dose} </label>
                                                    </div>
                                                    <div className=' flex   border-r px-1 py-0.5'>
                                                        <label className='text-xss text-black  '>FSH IU/L</label>
                                                        <label className='text-xss text-gray-500   rounded-md pl-1 '>{item.fsh_dose} </label>

                                                    </div>
                                                    <div className=' flex  border-r  px-1 py-0.5'>
                                                        <label className='text-xss text-black  '>HCG IU/L</label>
                                                        <label className='text-xss text-gray-500   rounded-md pl-1 '>{item.hcg_dose} </label>
                                                    </div>
                                                    <div className='flex px-1 elepsis truncate py-0.5'>
                                                        <label className='text-xss text-black  '>Scan By</label>
                                                        <label className='text-xss text-gray-500   rounded-md pl-1 '>{item.addedbyuser} </label>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    )}
                                </div>
                            </>
                            : ''}
                        {doctorView?.ScanDetails?.MedicationDetails ?
                            <>
                                <div className={'shadow mt-0.5 rounded pb-0.5 ' + theme.borderColor}>
                                    <div className={theme.tHedUpdte + ' text-white text-left p-1 flex rounded-t '}>
                                        <p className='text-sbase font-medium '>Medications </p>
                                    </div>
                                    <div className=' flex flex-wrap'>
                                        {handleMedication(doctorView?.ScanDetails.MedicationDetails.StimMedication, 'Stimulation')}
                                        {handleMedication(doctorView?.ScanDetails.MedicationDetails.AdjuvantMedication, 'Adjuvant')}
                                        {handleMedication(doctorView?.ScanDetails.MedicationDetails.TriggerMedication, 'Trigger')}
                                        {handleMedication(doctorView?.ScanDetails.MedicationDetails.LutealMedication, 'Luteal')}
                                        {handleMedication(doctorView?.ScanDetails.MedicationDetails.ProcedureMedication, 'Procedure')}
                                    </div>
                                </div>
                            </>
                            : ''}
                    </div>
                </div>
                {/* In-Cycle Blood Test */}
                <div className='flex mt-0.5 bg-yellow-400 hidden'>
                    <div className='w-1/2 mr-2'>
                        {doctorView?.BloodTestDetails[0].bloodtestid > 0 ?
                            <>
                                <label className={'mr-4  font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500  mt-2'}>In-Cycle Blood Test</label>
                                {doctorView?.BloodTestDetails.map((item, index) =>
                                    <div className='w-full bg-red-300 border rounded mt-1'>
                                        <div className={((theme.tHedUpdte + '  font-medium')) + ' text-white flex rounded-t text-xss text-left p-1'}>
                                            <label className='w-4/5'>
                                                Blood Scan {(item.testdate) ? 'on ' + getCorrectDate(item.testdate) : ''}
                                            </label>
                                            <label className='w-2/5 text-right'>
                                                <i>  {item.addedbyuser ? 'by : ' + item.addedbyuser : ''} </i>
                                            </label>
                                        </div>
                                        <div className='bg-white rounded  pl-3 flex flex-wrap p-1 items-center w-full '>
                                            <div className='w-1/3 flex justify-between  border-b  py-0.5'>
                                                <label className='text-xss text-black  '>LMP</label>
                                                <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item?.testdayofcycle} </label>
                                            </div>
                                            <div className='w-1/3 flex  justify-between border-b  py-0.5'>
                                                <label className='text-xss text-black  '>Date</label>
                                                <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{getCorrectDate(item.testdate)} </label>
                                            </div>
                                            <div className='w-1/3 flex justify-between  border-b  py-0.5'>
                                                <label className='text-xss text-black  '>E2 pmol/L</label>
                                                <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.e2_dose} </label>
                                            </div>
                                            <div className='w-1/3 flex  justify-between border-b  py-0.5'>
                                                <label className='text-xss text-black  '>P4 nmol/L</label>
                                                <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.p4_dose} </label>
                                            </div>
                                            <div className='w-1/3 flex  justify-between border-b  py-0.5'>
                                                <label className='text-xss text-black '>LH IU/L</label>
                                                <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.lh_dose} </label>
                                            </div>
                                            <div className='w-1/3 flex justify-between  border-b  py-0.5'>
                                                <label className='text-xss text-black  '>FSH IU/L</label>
                                                <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.fsh_dose} </label>

                                            </div>
                                            <div className='w-1/3 flex   justify-between py-0.5'>
                                                <label className='text-xss text-black  '>HCG IU/L</label>
                                                <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.hcg_dose} </label>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                            : ''}

                    </div>
                    <div className='w-1/2 hidden'>


                        {doctorView?.ScanDetails.ScanDetailList[0]?.scanresultid > 0 &&
                            <div className='w-full'>
                                <label className={'mr-4 font-bold text- xl  ' + theme.txt_color + ' border-b-[3px] border-b-amber-500 mt-2'}>In-Cycle Scan Result</label>
                                {doctorView?.ScanDetails.ScanDetailList.map((item, index) => (index <= 2 ?
                                    <div className={' text-white mt-2 border rounded'}>

                                        <div className={((theme.tHedUpdte + '  font-medium')) + ' flex rounded-t text-xss text-left p-1'}>
                                            <label className='w-4/5'>
                                                Pregnancy Scan {getCorrectDate(item.scandate) ? 'on ' + getCorrectDate(item.scandate) : ''}
                                            </label>
                                            <label className='w-2/5 text-right'>
                                                <i>{item.clinicianname ? 'by : ' + item.clinicianname : ''}</i>
                                            </label>
                                        </div>
                                        <div className='bg-white   pl-3 flex flex-wrap p-1 items-center w-full '>
                                            <div className='w-1/2 flex   border-b  py-0.5'>
                                                <label className='text-xss text-black   '>Cycle Day</label>
                                                <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.scandayofcycle} </label>
                                            </div>
                                            <div className='w-1/2 flex   border-b  py-0.5'>
                                                <label className='text-xss text-black   '>Scan Date </label>
                                                <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{getCorrectDate(item.scandate)} </label>
                                            </div>


                                            <div className='w-1/2 flex   border-b  py-0.5'>
                                                <label className='text-xss text-black  '>Next Scan Date</label>
                                                <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{getCorrectDate(item.nextscan) || '--'} </label>

                                            </div>
                                            {/* <div className='w-1/3 flex   border-b  py-0.5'>
                                <label className='text-xss text-black   '>Scan Performed By</label>
                                <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.fetTriggerDate} </label>

                            </div> */}
                                            {/*  */}
                                            <div className='w-1/2 flex   border-b  py-0.5'>
                                                <label className='text-xss text-black  '>Uterus</label>
                                                <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.result_uterus} </label>

                                            </div>
                                            <div className='w-1/2 flex   border-b  py-0.5'>
                                                <label className='text-xss text-black  '> Endometrial Thicknes</label>
                                                <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.result_endo} </label>
                                            </div>

                                            <div className='flex text-gray-500 w-full flex-wrap  border-b '>
                                                <label className={" text-sm  font-bold mr-1"} >L : </label>
                                                <label className={" text-sm w-11/12 flex   whitespace-nowrap"} >{getScanResults(generateScanInput(item.result_l), true)}</label>
                                            </div>
                                            <div className='flex text-gray-500 w-full  flex-wrap  border-b '>
                                                <label className={" text-sm   font-bold mr-1"} >R : </label>
                                                <label className={"text-sm w-11/12 whitespace-nowrap"} style={{ whiteSpace: 'nowrap' }}>{getScanResults(generateScanInput(item.result_r), true)}</label>
                                            </div>
                                            <div className='flex text-gray-500 w-full    '>
                                                <label className={" text-sm   font-bold mr-1"} >Comments : </label>
                                                <label className={"text-sm  whitespace-nowrap"} >{item.comments}</label>
                                            </div>

                                        </div>

                                    </div>
                                    : ''
                                ))}
                            </div>
                        }

                    </div>
                </div>

                {/* TP comment */}
                {getUniqueObjectsByKey(comments, 'treatmentPlanID').filter(x => x.comments.length > 0).length > 0 && <div className='w-full'>
                    <div className='flex justify-between items-center w-full'>
                        <label className={` mr-4 font-bold text- xl  ${theme.txt_color}  border-b-[3px] border-b-amber-500`} >Treatment Plan Comments</label>
                        <label className='text-xss text-gray-600 font-medium'>Total Comments# {getUniqueObjectsByKey(comments, 'treatmentPlanID').filter(x => x.comments.length > 0).length > 0 ? getUniqueObjectsByKey(comments, 'treatmentPlanID').filter(x => x.comments.length > 0).length : "0"} </label>
                    </div>
                    <div className={'w-full   bg-white rounded p-0.5 shadow pb-1'}>
                        {comments &&
                            getUniqueObjectsByKey(comments, 'treatmentPlanID').filter(x => x.comments.length > 0).length > 0 &&
                            getUniqueObjectsByKey(comments, 'treatmentPlanID').filter(x => x.comments.length > 0).map((item, index) => (
                                <div key={index} className={theme.hoverBg + ' cursor-pointer border-gray-200  p-2 mx-1 border border-gray-300 shadow  rounded shadow border mt-1 '}>
                                    <textarea
                                        style={{
                                            // overflowY: 'hidden', // Hide scrollbar
                                            height: 'fit-content', // Let the textarea adjust its height based on content
                                            minHeight: 'fit-content', // Minimum height to fit content
                                            resize: 'none', // Prevent manual resizing by the user
                                        }}

                                        rows={item.comments && Math.max(item.comments.split('\n').length, +1)} className='text-xss cursor-pointer text-left  font-medium w-full p-1 disabled  scrollbar-thin' disabled value={item.comments} />
                                    <p className={' text-xss text-gray-800 font-medium  '}><b>TP</b> v {item.formVersionMajor + '.' + item.formVersionMinor} ~ by <i><b>{item.clinicianname}</b></i> {' ~ Added on: ' + getCorrectDate(item.addedon) + ' ' + formatTime(item.addedon)}</p>
                                </div>

                            ))


                        }

                    </div>
                </div>}

                {/* Bf Comment */}
                {bfcomments?.BFDetails?.filter(x => x.comments.length > 0).length > 0 && <div className='w-full'>
                    <div className='flex justify-between items-center mt-2'>
                        <label className={` mr-4 font-bold text- xl  ${theme.txt_color}  border-b-[3px] border-b-amber-500`} >Booking Form Comments</label>
                        <label className='text-xss text-gray-600 font-medium'>Total Comments# {bfcomments?.BFDetails?.filter(x => x.comments.length > 0).length > 0 ? bfcomments?.BFDetails?.filter(x => x.comments.length > 0).length : ' '}   {/* {countComment()} */}  </label>
                    </div>

                    <div className={' w-full   bg-white rounded p-0.5 shadow pb-1'}>
                        {bfcomments && bfcomments?.BFDetails.filter(x => x.comments.length > 0).length > 0 ? replaceEmptyObjectsWithNull(bfcomments?.BFDetails?.filter(x => x.comments.length > 0)).map((item, index) => (
                            <div key={index} className={theme.hoverBg + ' cursor-pointer border-gray-200  p-2 mx-1 border border-gray-300 shadow  rounded shadow border mt-1 '}>
                                < textarea
                                    style={{
                                        // overflowY: 'hidden', // Hide scrollbar
                                        height: 'fit-content', // Let the textarea adjust its height based on content
                                        minHeight: 'fit-content', // Minimum height to fit content
                                        resize: 'none', // Prevent manual resizing by the user
                                    }}
                                    rows={item?.comments && Math.max(item?.comments?.split('\n').length + 1, 1)} className='text-xss cursor-pointer text-left  font-medium w-full p-1 disabled  scrollbar-thin' disabled value={item?.comments} />
                                <p className={' text-xss text-gray-800 font-medium  '}><b>BF</b> v {item.BFVersion} ~ by <i><b>{item.clinicianname}</b></i> {' ~ Added on: ' + getCorrectDate(item.addedon) + ' ' + formatTime(item.addedon)}</p>

                            </div>
                        ))
                            : ''
                        }

                    </div>
                </div>}
            </div >
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DocterSheetPrint)