import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../AppContextProvider ';
import { formatTime, formatTimeDifference, getCorrectDate, getEnumValueById, replaceEmptyObjectsWithNull } from '../../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPlus, faPrint } from '@fortawesome/free-solid-svg-icons';
import { ApiCall_GET, ApiCall_POST } from '../../../Redux/Generic/action';
import CoupleNotificationAdd from './CoupleNotificationAdd';
import ServiceUrls from '../../../Common/ServiceUrls';

export const CoupleNotification = ({ ApiCall_GET, coupleid }) => {
    const theme = useAppContext().themeDetails;
    const [coupleNotifications, setCoupleNotifications] = useState([]);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);

    // Fetch couple notifications
    useEffect(() => {
        getCoupleNotifications();
    }, []);

    const getCoupleNotifications = () => {
        ApiCall_GET(ServiceUrls.GetCoupleNotifications + coupleid, true).then(function (payload) {
            setCoupleNotifications(replaceEmptyObjectsWithNull(payload));
        });
    };

    const handleCoupbleNotificationAdd = () => {
        getCoupleNotifications();
    }

    // Group notifications by date
    const groupNotificationsByDate = (notifications) => {
        return notifications.reduce((acc, item) => {
            const date = getCorrectDate(item.addedon); // Assuming getCorrectDate formats the date
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(item);
            return acc;
        }, {});
    };

    const groupedNotifications = groupNotificationsByDate(coupleNotifications);

    return (
        <div className={` w-full bg-white p-2 `}>
            <div className={'pl-2 py-2 rounded flex items-center justify-between'}>
                <label className={`mr-4 font-bold cursor-pointer theme.txt_color + ' border-b-[3px] border-b-amber-500`}>Couple Notifications</label>
                <button onClick={() => setOpenNotificationModal(true)} className="flex items-center cursor-pointer justify-center text-green-800 bg-white border border-green-600 focus:outline-none hover:text-black hover:bg-green-600 hover:text-white focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2">
                    <FontAwesomeIcon icon={faPlus} />
                    <label className="pl-2 cursor-pointer">Add Notification</label>
                </button>
                {openNotificationModal && <CoupleNotificationAdd coupleid={coupleid} setOpenNotificationModal={setOpenNotificationModal} sendDataToCoupleNotification={handleCoupbleNotificationAdd} />}
            </div>
            <div className={`${theme.thumb} ${theme.track} w-full  rounded-md shadow overflow-y-auto scroll scrollbar-thin h-[80vh]`}>

                {Object.keys(groupedNotifications).map((date) => (
                    <div key={date} className={` w-full  mt-1 rounded-md shadow `}>
                        <div className={theme.navbar + ' p-2 text-white text-left shadow rounded-t'}>
                            <p className="text-sbase font-medium">{date}</p>
                        </div>
                        {groupedNotifications[date].map((item) => (
                            <div className={'item.NoteColor' + ` p-2 w-full  mb-1 items-center rounded ${theme.tableBg} border shadow border-[${'item.NoteColor'}] `} key={item.recordID}>
                                <div className="flex items-center">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z" fill="#FDB913" />
                                        <path d="M6.99766 4L6.80156 5.00937C6.4719 5.1337 6.16916 5.30797 5.90391 5.52578L4.93437 5.19141L4 6.80859L4.77656 7.48359C4.74671 7.66688 4.73125 7.83674 4.73125 8C4.73125 8.16351 4.74717 8.33305 4.77656 8.51641V8.51719L4 9.19219L4.93437 10.8086L5.90312 10.475C6.16841 10.6929 6.47182 10.8662 6.80156 10.9906L6.99766 12H8.86484L9.06094 10.9906C9.39082 10.8662 9.69321 10.6922 9.95859 10.4742L10.9281 10.8086L11.8617 9.19219L11.0859 8.51641C11.1158 8.33312 11.1312 8.16326 11.1312 8C11.1312 7.83698 11.1157 7.66735 11.0859 7.48438V7.48359L11.8625 6.80781L10.9281 5.19141L9.95937 5.525C9.69409 5.30707 9.39068 5.13377 9.06094 5.00937L8.86484 4H6.99766ZM7.65703 4.8H8.20547L8.36094 5.6L8.77813 5.75781C9.02959 5.85259 9.25552 5.9827 9.45156 6.14375L9.79687 6.42656L10.5656 6.1625L10.8398 6.63672L10.2258 7.17109L10.2961 7.61094V7.61172C10.3206 7.76169 10.3312 7.88751 10.3312 8C10.3312 8.11249 10.3206 8.23829 10.2961 8.38828L10.225 8.82812L10.8391 9.3625L10.5648 9.8375L9.79687 9.57266L9.45078 9.85625C9.25474 10.0173 9.02959 10.1474 8.77813 10.2422H8.77734L8.36016 10.4L8.20469 11.2H7.65703L7.50156 10.4L7.08437 10.2422C6.83291 10.1474 6.60698 10.0173 6.41094 9.85625L6.06562 9.57344L5.29687 9.8375L5.02266 9.36328L5.6375 8.82812L5.56641 8.38984V8.38906C5.5423 8.23844 5.53125 8.11227 5.53125 8C5.53125 7.88751 5.54195 7.76171 5.56641 7.61172L5.6375 7.17188L5.02266 6.6375L5.29687 6.1625L6.06562 6.42734L6.41094 6.14375C6.60698 5.9827 6.83291 5.85259 7.08437 5.75781L7.50156 5.6L7.65703 4.8ZM7.93125 6.4C7.05264 6.4 6.33125 7.12139 6.33125 8C6.33125 8.87861 7.05264 9.6 7.93125 9.6C8.80986 9.6 9.53125 8.87861 9.53125 8C9.53125 7.12139 8.80986 6.4 7.93125 6.4ZM7.93125 7.2C8.37584 7.2 8.73125 7.55541 8.73125 8C8.73125 8.44459 8.37584 8.8 7.93125 8.8C7.48666 8.8 7.13125 8.44459 7.13125 8C7.13125 7.55541 7.48666 7.2 7.93125 7.2Z" fill="white" />
                                    </svg>
                                    <div className={'px-2'}>
                                        <p className="text-sm text-gray-700 font-semibold ">
                                            {item.sourceName} {item.CreatedAt} by ~ {item.username ? <i><b>{item.username}</b></i> : ' '} on ~ {item.CreatedAt && <> <i><b>{item.CreatedAt}</b></i> at <i><b>{formatTime(item.addedon, true)}</b></i> </>} {item.addedon ? ' ( ' + formatTimeDifference(item.addedon) + ' ) ' : ''} ~ ID. {item.notificationid}
                                        </p>
                                        <p className="text-sm3 font-medium">{item.message}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { ApiCall_GET };

export default connect(mapStateToProps, mapDispatchToProps)(CoupleNotification);
