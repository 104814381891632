import React from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../../AppContextProvider ';
import { getCycleTypeColor, getTextColor } from '../../../../../common';

export const GenerateProcedureBooking = ({ }) => {
    const theme = useAppContext().themeDetails;
    const accordionData = []
    return (
        <div className="w-full flex mt-1 gap-x-1 ">
            <div className="w-2/3 bg-white">
                <div className={`bg-[#5599ae] flex justify-between text-left p-2 rounded-t`}><p className='text-sm font-medium text-white'>OHSS Monitoring In Progress - Follow-up required</p></div>

                <div className={theme.thumb + ' ' + theme.track + ' mt-1  overflow-auto scrollbar  scrollbar-thin w-full xl:h-[50vh] 2xl:h-[60vh] '} style={{ width: "100%", }}>
                    {accordionData.map((item, index) => (

                        < div className={'  w-full  flex  mb-0.5 pt-1 bg-white rounded shadow border items-center'} >
                            <div style={{ width: '5%', height: "100% " }} className={theme.txt_color + ' flex item-center justify-center  py-1 '}>

                                <p className='text-sm  font-medium '>{index + 1}.</p>
                            </div>
                            <div style={{ width: '10%', height: "100% " }} className={'  p-1 items-center flex justify-center '}>
                                <div className={` rounded rounded-full h-8 w-8 shadow `} style={{ backgroundColor: item?.bt_color }}>
                                    {/* <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.6667 5.25H15.7778M13.6667 9.5H15.7778M15.7778 13.75H5.22222M5.22222 5.25V9.5H9.44444V5.25H5.22222ZM3.11111 18H17.8889C18.4488 18 18.9858 17.7761 19.3817 17.3776C19.7776 16.9791 20 16.4386 20 15.875V3.125C20 2.56141 19.7776 2.02091 19.3817 1.6224C18.9858 1.22388 18.4488 1 17.8889 1H3.11111C2.55121 1 2.01424 1.22388 1.61833 1.6224C1.22242 2.02091 1 2.56141 1 3.125V15.875C1 16.4386 1.22242 16.9791 1.61833 17.3776C2.01424 17.7761 2.55121 18 3.11111 18Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg> */}
                                </div>
                            </div>
                            <div style={{ width: '10%', height: "100% " }} className={'  py-1 '}>
                                <p className='text-sm  font-medium '>{item?.title}</p>
                            </div>
                            <div style={{ width: '10%', height: "100% " }} className={'  py-1 '}>
                                <div className={`flex flex-col h-8 w-8 shadow rounded items-center justify-center `} style={{ backgroundColor: (getCycleTypeColor(item?.procedureTypeName?.split(' ')[0].trim()) || 'gray') }}>
                                    <label className={` text-xs  font-semibold   text-${getTextColor(getCycleTypeColor(item?.procedureTypeName?.replaceAll(' ', '').split('-')[0]) || 'gray')}`}>
                                        {item?.procedureTypeName ? item?.procedureTypeName == 'Ovulation Induction' ? 'OI' : item?.procedureTypeName == "Egg-donor IVF Cycle" ? 'ED' : item?.procedureTypeName?.split(' ')[0].trim() : ' - '}
                                    </label>
                                    <div className=" bg-white flex  items-center justify-center h-3 mt-0.5 w-6 mb-1 rounded" >
                                        <label className={`  text-xs font-bold   text-gray-600`}> {'v' + (item?.version || '')} </label>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '20%', height: "100% " }} className={' py-1  '}>
                                <p className='text-sm font-medium '>{item?.Patient} </p>
                                <p className='text-xss font-medium '>{item?.PatientDOB} </p>
                            </div>
                            <div style={{ width: '20%', height: "100% " }} className={' py-1  '}>
                                <p className='text-sm font-medium '>{item?.Partner} </p>
                                <p className='text-xss font-medium '>{item?.PartnerDOB} </p>
                            </div>
                            <div style={{ width: '20%', height: "100% " }} className={' py-1  '}>
                                <p className='text-sm font-medium '>{item?.CoupleCode} </p>
                                <p className='text-xss font-medium '>{item?.Clinician} </p>
                            </div>




                        </div>

                    ))}
                </div>
            </div>
            <div className="w-1/3 bg-white">
                <div className={`bg-[#5599ae] flex justify-between text-left p-2 rounded-t`}>
                    <p className='text-sm font-medium text-white'>Done / Already followed-up Today</p>
                </div>
                <div className={theme.thumb + ' ' + theme.track + ' mt-1  overflow-auto scrollbar scrollbar-thin w-full xl:h-[50vh] 2xl:h-[60vh] '} style={{ width: "100%", }}>
                    {accordionData.map((item, index) => (
                        <div className={'  w-full   mb-0.5 pt-1 bg-white rounded shadow border items-center'} >
                            <div className={'  w-full  flex items-center'} >
                                <div style={{ width: '10%', height: "100% " }} className={theme.txt_color + ' flex item-center justify-center  py-1 '}>
                                    <svg width="20" height="20" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.9966 0.252222C11.767 0.124475 11.5145 0.0432355 11.2535 0.0131501C10.9925 -0.0169354 10.7281 0.00472298 10.4755 0.076887C10.2229 0.149051 9.98697 0.270305 9.78125 0.433715C9.57553 0.597124 9.40403 0.799484 9.27658 1.02922L5.56358 7.71122L3.43858 5.58622C3.25408 5.3952 3.03339 5.24284 2.78939 5.13802C2.54538 5.0332 2.28294 4.97803 2.01738 4.97572C1.75182 4.97341 1.48846 5.02402 1.24267 5.12458C0.996876 5.22514 0.773572 5.37365 0.585787 5.56143C0.398001 5.74922 0.249495 5.97252 0.148933 6.21831C0.0483713 6.46411 -0.00223212 6.72747 7.55134e-05 6.99302C0.00238315 7.25858 0.0575557 7.52102 0.162374 7.76503C0.267192 8.00904 0.419556 8.22973 0.610576 8.41422L4.61058 12.4142C4.98858 12.7932 5.49858 13.0012 6.02458 13.0012L6.30158 12.9812C6.60813 12.9383 6.90054 12.8249 7.1558 12.6498C7.41105 12.4747 7.62219 12.2428 7.77258 11.9722L12.7726 2.97222C12.9002 2.74267 12.9814 2.49023 13.0115 2.2293C13.0416 1.96838 13.02 1.70408 12.9479 1.4515C12.8759 1.19892 12.7548 0.963011 12.5915 0.757238C12.4283 0.551465 12.2261 0.37986 11.9966 0.252222Z"
                                            fill={"#4AA473"} />
                                    </svg>
                                </div>
                                <div style={{ width: '10%', height: "100% " }} className={'flex justify-center  items-center '}>
                                    <div className={`flex flex-col h-8 w-8 shadow rounded items-center justify-center `} style={{ backgroundColor: (getCycleTypeColor(item?.procedureTypeName?.split(' ')[0].trim()) || 'gray') }}>
                                        <label className={` text-xs  font-semibold   text-${getTextColor(getCycleTypeColor(item?.procedureTypeName?.replaceAll(' ', '').split('-')[0]) || 'gray')}`}>
                                            {item?.procedureTypeName ? item?.procedureTypeName == 'Ovulation Induction' ? 'OI' : item?.procedureTypeName == "Egg-donor IVF Cycle" ? 'ED' : item?.procedureTypeName?.split(' ')[0].trim() : ' - '}
                                        </label>
                                        <div className=" bg-white flex  items-center justify-center h-3 mt-0.5 w-6 mb-1 rounded" >
                                            <label className={`  text-xs font-bold   text-gray-600`}> {'v' + (item?.version || '')} </label>
                                        </div>
                                    </div>

                                </div>
                                <div style={{ width: '30%', height: "100% " }} className={' py-1 '}>
                                    <p className='text-xss font-medium '>{item?.Patient} </p>
                                    <p className='text-xs7 font-medium '>{item?.PatientDOB} </p>
                                </div>
                                <div style={{ width: '30%', height: "100% " }} className={' py-1  '}>
                                    <p className='text-xss font-medium '>{item?.Partner} </p>
                                    <p className='text-xs7 font-medium '>{item?.PartnerDOB} </p>
                                </div>
                                <div style={{ width: '20%', height: "100% " }} className={' py-1 '}>
                                    <p className='text-xss font-medium '>{item?.CoupleCode} </p>
                                    <p className='text-xs7 font-medium '>{item?.Clinician} </p>
                                </div>
                            </div>
                            <p className="text-xs w-full font-medium text-gray-700 py-1 px-2">OHSS Follow-Up is NOT required Today by Lori Diaz at 7-Oct-2024 15:31 AEST</p>
                        </div>
                    ))}
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(GenerateProcedureBooking)
