import React from 'react'
import { connect } from 'react-redux'
import { CycleType, getCycleTypeColor } from '../../../../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAppContext } from '../../../../../AppContextProvider ';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ApiCall_POST, ApiCall_GET } from '../../../../../Redux/Generic/action'

export const DocterTemplets = ({ ApiCall_GET, ApiCall_POST, resetViewTemplate, setTemplateId, TemplatesList, setShowTemplist, fromAddTemplate, setTemplatesList }, props) => {

    const theme = useAppContext().themeDetails;

    let navigate = useNavigate();


    const artid = props.artid || ''
    const coupleid = props.coupleid || ''
    const clinicianName = props.treatmentPlanObj?.clinicianname || ''
    // const templateIdSelected = item?.value || ''


    const clinicsList = props?.clinicsList || ''
    // const coupleLocationId = props?.additionalData?.coupleLocationId || ''
    const isEmptyART = props?.isEmptyART || ''

    const removeTemplate = (Id, name) => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "bg-transparent flex justify-between items-center mx-1  hover:bg-green-600 text-green-700 font-medium hover:text-white py-1 px-5 border-2 border-green-500 hover:border-transparent rounded",
                cancelButton: "bg-transparent flex justify-between items-center  mx-1 hover:bg-red-600 text-red-700 font-medium hover:text-white py-1 px-5 border-2 border-red-500 hover:border-transparent rounded"
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            title: "Template Delete Confirmation",
            text: "Are you sure you want to delete " + name + "?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            // timerProgressBar: true, timer: 6000,
        }).then((result) => {
            if (result.isConfirmed) {
                ApiCall_POST('TreatmentPlan/DeleteUserTemplate/' + Id, null, true).then(function (payload) {
                    ApiCall_GET('TreatmentPlan/LoadTemplateList', true).then(function (payload) {
                        // getTemplateList().then(function (payload) {
                        setTemplatesList(payload);

                    });
                });
            }
        });
    };
    return (
        (
            <div className='w-full p-2'>
                {TemplatesList.filter(x => !x.isAdmin).length > 0 ?
                    <div className=' w-full '>
                        <label className={' font-bold text -xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500'}>Doctor Templates</label>
                        <div className=' rounded py-1'>
                            {['IVF', 'FET_FOT', 'FOC', 'OTHERS'].map((groupType, rowIndex) => {
                                const groupedItems = TemplatesList.filter(item => {
                                    switch (groupType) {
                                        case 'IVF':
                                            return item.procedureTypeId === CycleType.IVF;
                                        case 'FET_FOT':
                                            return item.procedureTypeId === CycleType.FET || item.procedureTypeId === CycleType.FOT;
                                        case 'FOC':
                                            return item.procedureTypeId === CycleType.FOC;
                                        case 'OTHERS':
                                            return item.procedureTypeId !== CycleType.IVF &&
                                                item.procedureTypeId !== CycleType.FET &&
                                                item.procedureTypeId !== CycleType.FOT &&
                                                item.procedureTypeId !== CycleType.FOC;
                                        default:
                                            return false;
                                    }
                                });
                                return (
                                    <div key={rowIndex} className='flex flex-wrap'>
                                        {groupedItems.filter(x => !x.isAdmin).map((item, index) => (
                                            <div key={index} className='p-2  md:w-1/2 flex mb-1 flex-wrap rounded'>
                                                <div className='w-full gap-x-2 duration-300 shadow font-normal hover:text-white border border-gray-200 rounded border-[#ffb578] bg-[#fff6ef] hover:shadow-xl hover:scale-110 duration-300'
                                                    onMouseEnter={(e) => { e.currentTarget.querySelector('#userTemp_' + index).style.backgroundColor = getCycleTypeColor(item?.procedureTypeName?.split(' ')[0].trim()) || 'gray'; e.currentTarget.querySelector('#userTemp_' + index).style.color = 'white' }}
                                                    onMouseLeave={(e) => { e.currentTarget.querySelector('#userTemp_' + index).style.backgroundColor = 'white'; e.currentTarget.querySelector('#userTemp_' + index).style.color = getCycleTypeColor(item?.procedureTypeName?.split(' ')[0].trim()) || 'gray' }}
                                                >
                                                    <div className='flex justify-between'>
                                                        <div className='flex w-full items-center cursor-pointer rounded'
                                                            onClick={(e) => {
                                                                if (fromAddTemplate) {
                                                                    resetViewTemplate(item.value);
                                                                    setTemplateId(item.value);
                                                                    setShowTemplist(true);
                                                                } else {
                                                                    navigate('/updateTreatmentPlan', {
                                                                        state: {
                                                                            artid,
                                                                            coupleid,
                                                                            clinicianName,
                                                                            // templateIdSelected,
                                                                            procedureTypeSelected: 1,
                                                                            isAdminTemplate: false,
                                                                            clinicsList,
                                                                            // coupleLocationId,
                                                                            isEmptyART,
                                                                        },
                                                                    });
                                                                }
                                                            }}

                                                        >
                                                            <div className={'flex flex-col h-10 w-10 duration-300 p-3 shadow-xl rounded items-center justify-center bg-white border'}
                                                                id={'userTemp_' + index}
                                                                style={{ borderColor: (getCycleTypeColor(item?.procedureTypeName?.split(' ')[0].trim()) || 'gray'), color: (getCycleTypeColor(item?.procedureTypeName?.split(' ')[0].trim()) || 'gray') }}
                                                            >
                                                                <label className={`text-sm font-bold`}>
                                                                    {item?.procedureTypeName ? item?.procedureTypeName === 'Ovulation Induction' ? 'OI' : item?.procedureTypeName === "Egg-donor IVF Cycle" ? 'ED' : item?.procedureTypeName?.split(' ')[0].trim() : ' - '}
                                                                </label>
                                                            </div>
                                                            <div className='pl-2'>
                                                                <p className=' text-xss cursor-pointer font-semibold text-gray-600'>{item.label}</p>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center cursor-pointer text-red-500 px-2 hover:bg-red-100 duration-300'
                                                            onClick={() => removeTemplate(item.value, item.label)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div>
                    </div >
                    : ''
                }</div >
        )
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_GET, ApiCall_POST }

export default connect(mapStateToProps, mapDispatchToProps)(DocterTemplets)
