import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import logo from '../../../../../Assets/images/white.png';

import { getUserInformation } from '../../../../../common';
import { useAppContext } from '../../../../../AppContextProvider ';

export const OutcomePositivePrint = ({ artid, artDetails, htmlContent, showPreview }) => {
    
    const user = getUserInformation()
    const theme = useAppContext().themeDetails;
      const [currentTime, setCurrentTime] = useState(new Date());
   
    return (
        <div className=" flex min-h-screen w-4/5 m-auto  p-2 delay-900 duration-700  ">
            {/* Header */}
            <div className=" w-full   bg-white p-3">
                < div className={` ${theme.navbar} ` + ' p-2 flex justify-between rounded  w-full mb-2'} >
                    <div className='flex items-center justify-end '>
                        <img src={logo} className="logoCFC" alt="img" width='160px' />
                        <p className={`dot-${user?.BrandCode?.toUpperCase()} `} style={{ backgroundColor: `#${user?.ChartingColor}` }}></p>

                        <p className=' text-white  text-sbase'>{user?.LocationName}</p>
                    </div>
                    <div className='flex justify-end text-white items-center '>
                        <label className='  text-sm'>{currentTime.toLocaleDateString(undefined, { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' })}</label>
                        <label className='pl-1 w-24 font-semibold'>{currentTime.toLocaleTimeString()}</label>
                    </div>
                  
                </div>
                <div className=" w-full   bg-white p-3">
                    <p className='text-base '>{htmlContent?.add1}</p>
                    <p className='text-base  '>{htmlContent?.add2}</p>
                    <p className='text-base '>{htmlContent?.add3}</p>
                    <p className='text-base '>{htmlContent?.add4}</p>
                    <p className='text-base mb-2 '>{htmlContent?.city} {htmlContent?.state} {htmlContent?.postcode}</p>
                    <p className='text-base mb-2 '>{htmlContent?.locationid != 1 && htmlContent?.locationid != 5 && htmlContent?.locationid != 51 ? "N46 Melb GC SA S" : "N46 Bris SB"} ~ {htmlContent?.CurrentDate}</p>
                    <p className="text-base mb-2 font-normal">{htmlContent?.RecipientName || ((htmlContent?.pfirstname) || htmlContent?.pfirstname.toUpperCase() === "NO PARTNER" ? htmlContent?.firstname : `${htmlContent?.firstname} and ${htmlContent?.pfirstname}`)}</p>
                    <p className='text-base '>Dear{htmlContent?.RecipientName}</p>
                    <p className='text-basee mb-2 font-normal'>Congratulations on your pregnancy. You will, no doubt, have many questions about your pregnancy. The aim of this letter is to guide you on to the next step you need to take.</p>
                    <p className="text-base mb-2">{htmlContent?.locationid === 1 || htmlContent?.locationid === 5 || htmlContent?.locationid === 51 ? null: `At ${htmlContent?.brandCode}, we have developed an early pregnancy seminar to provide information and support through the first trimester of pregnancy. Please contact City Fertility Centre reception to reserve a place.`}</p>
                    <p className='text-base mb-2 '>It is important that you contact your IVF Clinician’s rooms to arrange your initial pregnancy consultation; this will include your first ultrasound to confirm your pregnancy. This scan usually occurs 2-3 weeks after your positive pregnancy blood test or at your doctor’s discretion."</p>
                    <p className='text-base mb-2 '>It is important that you do not cease any medication that you have been instructed to take until advised by your treating doctor. Panadol and Panadeine are safe to be taken in the event of any mild pain, discomfort, and fever.</p>
                    <p className='text-base mb-2 '>$"At {htmlContent.brandCode}, we like to remain involved in your pregnancy journey. As part of this involvement, we will be enquiring about your pregnancy progress at approximately 14 and 32 weeks of pregnancy via a phone call and/or letter or Email."</p>
                    {/* <p className='text-base mb-2 '>{htmlContent?.LetterContent?.FollowUpDetails}</p> */}
                    <p className='text-base mb-2 '>Once again, congratulations on your pregnancy and please do not hesitate to contact us if we can be of any further assistance.</p>
                    <p className='text-base mb-2 '>Kind Regards, IVF Nurse Coordinator, {htmlContent.brandCode}</p>
                    <p className='text-base mb-2 '>{htmlContent?.brandCode}</p>
                </div >
            </div >
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OutcomePositivePrint)