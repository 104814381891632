import React, { useEffect, useState } from "react";
import { ApiCall_POST, ApiCall_GET } from "../../../Redux/Generic/action";
import { connect } from "react-redux";
import { useAppContext } from "../../../AppContextProvider ";


export const AddNew = ({ selectedPage, setShowAddNewlist, showAddNewlist }) => {
    const theme = useAppContext().themeDetails;
    const [callReturn, setCallReturn] = useState('');

    // console.log(showAddNewlist, "showAddNewlist");
    // console.log(selectedPage, "selectedPage");

    const handleInputChange = (e) => {

        const { name, value, checked, type } = e.target;

        if (type === 'checkbox') {
            setCallReturn(prevFormData => ({
                ...prevFormData,
                [name]: checked,
            }));
        }
        else if (type === 'radio')
            setCallReturn(prevFormData => ({
                ...prevFormData,
                [name]: checked,
            }));
        else {
            setCallReturn(prevFormData => ({
                ...prevFormData,
                [name]: value,
            }));

        }
        // console.log(callReturn)
    }
    const handlecancel = () => {


        setCallReturn({});


    }
    const handleSubmit = () => {

        if (selectedPage === 1) {
            if (showAddNewlist === 1) {
                alert('addCall')
            } else {
                alert('addUnAnswarCall')
            }
        }
        else {
            alert('Waitinglist')
        }
        // handleSubmit(action);
    };
    return (
        <div className="fixed z-50 inset-0 overflow-y-auto ">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div onClick={() => setShowAddNewlist(0)}
                    style={{ backdropFilter: "blur(7px)" }} className="fixed inset-0 transition-opacity filter" aria-hidden="true"                >
                    <div className="absolute inset-0 bg-gray-700 opacity-70  "></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl " aria-hidden="true"></span>
                {/* <Draggable> */}
                <div className={`sm:my-8  w-full md:w-1/2 filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`}
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"                >
                    <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative  `}>
                        <svg className={" absolute top-2 right-2 cursor-pointer shadow  font-semibold  "}
                            onClick={() => setShowAddNewlist(0)}
                            width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"                       >
                            <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z"
                                fill="#ed4545" />
                        </svg>
                        <h3
                            className={`${theme.txt_color} text-xl text-center mb-4 mt-2 leading-6 font-bold text-gray-900`}
                            id="modal-headline"
                        >
                            {selectedPage === 1
                                ? `Add ${showAddNewlist === 1 ? 'New Phone' : 'Unanswered Initial'} Call`
                                : 'Add New Waiting List'}
                        </h3>
                        {showAddNewlist == 1 ?
                            <div className=" my-2 w-full border rounded-md shadow items-center bg-gray-100 p-4  rounded-lg"
                                onMouseDown={(e) => e.stopPropagation()}>
                                <div className="mb-4">
                                    <div className="flex flex-col w-full ">
                                        <p className='text-sm font-medium pb-1 text-gray-700'>Select Couple From the System (Type three (3) or more character/symbols to trigger the search)</p>
                                        <input type='text' name='QuickFind'
                                            // onChange={(e) => handleSearchText(e)}
                                            // value={refGPName}
                                            className={
                                                `${theme.cheqColor} shadow border w-full rounded-md py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none `
                                                // ((!refGPName.trim() || !coupleData?.PatientId || !coupleId) ? 'bg-red-100 border-red-500' : '')
                                            }
                                        />
                                        {/* 
                                 {gpReferralList && (
                                     <ul className={`${theme.thumb} ${theme.track} ${theme.thin} absolute top-[35%] left-2 bg-white  mt-1 shadow-md max-h-48 w-[60%]  overflow-y-scroll scrollbar scrollbar-thin`}>
                                         {gpReferralList?.map((refgp, index) => (
                                             <li
                                                 key={index}
                                                 className="px-2 py-1 hover:bg-gray-200 cursor-pointer w-full text-sm"
                                                 onClick={(e) => { setSearchText(refgp); setRefGPName(refgp.TITLE + ' ' + refgp.FIRSTNAME + ' ' + refgp.LASTNAME); setSelectedGPid(refgp.refgpid) }}>
                                                 {refgp.TITLE + ' ' + refgp.FIRSTNAME + ' ' + refgp.LASTNAME + ' ' + refgp.MAILORG + ' ' + refgp.SUBURB + ' ' + refgp.STATE + ' ' + refgp.MAILPC}
                                             </li>
                                         ))}
                                     </ul>
                                 )} */}


                                        <h3 className={theme.txt_color + " text-xl text-center mb-4 mt-2 leading-6 font-semibold text-gray-900"} id="modal-headline">OR</h3>
                                        <div className={`w-full gap-x-2  flex items-center font-normal my-0.5 `}>
                                            <div className={`w-1/2 gap-x-2 text-left flex items-center font-normal  px-2 `}>
                                                <p className='w-1/2  text-sm font-normal  text-gray-700'>Type a not registerd patient name</p>
                                                <input type="text" name='nonregname' onChange={(e) => handleInputChange(e)}
                                                    className={` input-field`} />
                                            </div>

                                            <div className={`w-1/2 gapx-3 text-left flex items-center font-normal overflow-hidden `}>
                                                <div className={`w-1/3 gap-x-2 text-left flex items-center font-normal  `}>
                                                    <input type="radio" name="brandcode" value="rf" className="" id="brandcode_rf" onChange={(e) => handleInputChange(e)} />
                                                    <p className='  text-sm font-normal  text-gray-700'>Rainbow Patient</p>

                                                </div>
                                                <div className={`w-1/3 gap-x-2 text-left flex items-center font-normal  `}>
                                                    <input type="radio" name="brandcode" value="fsf" className="" id="brandcode_fsf" onChange={(e) => handleInputChange(e)} />
                                                    <p className='  text-sm font-normal  text-gray-700'>First Step Patient</p>

                                                </div>
                                            </div>


                                        </div>
                                        {selectedPage === 2 && <div className={`w-full gap-x-2  flex items-center font-normal my-0.5 `}>
                                            <div className={`w-1/2 gap-x-2 text-left flex items-center font-normal  px-2 `}>
                                                <p className='w-1/2  text-sm font-normal  text-gray-700'>Waiting Room Location</p>
                                                <input type="radio" name="locationid" className="" onChange={(e) => handleInputChange(e)} />
                                                <p className='  text-sm font-normal  text-gray-700'>  (CFC) Brisbane City Waiting Room</p>

                                            </div>

                                            <div className={`w-1/2 gapx-3 text-left flex items-center font-normal overflow-hidden `}>
                                                <div className={`w-1/2 gap-x-2 text-left flex items-center font-normal  `}>
                                                    <input type="radio" name="noReferralGP" className="" />
                                                    <p className='  text-sm font-normal  text-gray-700'>(RF) Brisbane City Waiting Room</p>

                                                </div>
                                                <div className={`w-1/2 gap-x-2 text-left flex items-center font-normal  `}>
                                                    <input type="radio" name="noReferralGP" className="" />
                                                    <p className='  text-sm font-normal  text-gray-700'>(FSF) FSF NorthLakes Waiting Room</p>

                                                </div>
                                            </div>


                                        </div>}

                                        <div className={`w-full gap-x-2  flex items-center font-normal my-0.5 `}>
                                            <div className={`w-1/2 gap-x-2 text-left flex items-center font-normal  px-2 `}>
                                                <p className='w-1/2  text-sm font-normal  text-gray-700'>Department</p>
                                                <select name="department" value={''} onChange={(e) => handleInputChange(e)}
                                                    className={` focus:bg-white border border-transparent focus:bg-white  w-1/2  rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500 `}>
                                                    <option value={''}>~ Select ~</option>
                                                    {/* <option key={item.value} value={item.Label}>{item.Label}</option> */}
                                                    <option value="Nursing">Nursing</option>
                                                    <option value="Scientist">Scientist</option>
                                                    <option value="Patient Services">Patient Services</option>
                                                    <option value="Clinician">Clinician</option>
                                                    <option value="Counsellor">Counsellor</option>
                                                    <option value="Finance">Finance</option>
                                                    <option value="Procedure Centre">Procedure Centre</option>
                                                </select>
                                            </div>

                                            <div className={`w-1/2  text-left flex items-center font-normal overflow-hidden `}>
                                                {selectedPage === 1 && < div className={`w-2/3 gap-x-2 text-left flex items-center font-normal  `}>
                                                    <input type="checkbox" name="incycle" onChange={(e) => handleInputChange(e)} className="" />
                                                    <p className='  text-sm font-normal  text-gray-700'>In Cycle</p>

                                                </div>}

                                            </div>


                                        </div>
                                        {showAddNewlist === 1 ? <div className={`w-full gap-x-2  flex items-center font-normal my-0.5 `}>
                                            <div className={`w-1/2 gap-x-2 text-left flex items-center font-normal  px-2 `}>
                                                <p className='w-1/2  text-sm font-normal  text-gray-700'>Phone Call In Time</p>
                                                <input name="arrivaltime" type="time" value={''} onChange={(e) => handleInputChange(e)}
                                                    className={` focus:bg-white border border-transparent focus:bg-white  w-1/2  rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500 `}>

                                                    {/* <option key={item.value} value={item.Label}>{item.Label}</option> */}

                                                </input>
                                            </div>

                                            <div className={`w-1/2  text-left flex items-center font-normal overflow-hidden `}>
                                                <div className={`w-2/3 gap-x-2 text-left flex items-center font-normal  `}>
                                                    <p className='  text-sm font-normal  text-gray-700'>Expect Call Back Between</p>
                                                    <input name="returntime1" type="time" value={''} onChange={(e) => handleInputChange(e)}
                                                        className={` focus:bg-white border border-transparent focus:bg-white  w-1/2  rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500 `}>

                                                        {/* <option key={item.value} value={item.Label}>{item.Label}</option> */}

                                                    </input>
                                                </div>
                                                <div className={`w-1/3 gap-x-2 text-left flex items-center font-normal  `}>
                                                    <p className=' w-1/4 text-sm font-normal  text-gray-700'>And</p>
                                                    <input name="returntime2" type="time" value={''}
                                                        className={` focus:bg-white border border-transparent focus:bg-white  w-3/4 rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500 `}>

                                                        {/* <option key={item.value} value={item.Label}>{item.Label}</option> */}

                                                    </input>
                                                </div>
                                            </div>
                                        </div>
                                            :
                                            <div className={`w-full gap-x-2  flex items-center font-normal my-0.5 `}>
                                                <div className={`w-1/2 gap-x-2 text-left flex items-center font-normal  px-2 `}>
                                                    <p className='w-1/2  text-sm font-normal  text-gray-700'>Arrival Date</p>
                                                    <select name="arrivaldate" value={''} onChange={(e) => handleInputChange(e)}
                                                        className={` focus:bg-white border border-transparent focus:bg-white  w-1/2  rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500 `}>
                                                        <option value={''}>~ Select ~</option>
                                                        {/* <option key={item.value} value={item.Label}>{item.Label}</option> */}
                                                        <option >test</option>
                                                    </select>
                                                </div>

                                                <div className={`w-1/2  text-left flex items-center font-normal overflow-hidden `}>
                                                    <div className={`w-2/3 gap-x-2 text-left flex items-center font-normal  `}>
                                                        <p className=' w-[41%] text-sm font-normal  text-gray-700'>Arrival Time
                                                        </p>
                                                        <input name="arrivaltime" type="time" value={''} onChange={(e) => handleInputChange(e)}
                                                            className={` focus:bg-white border border-transparent focus:bg-white  w-1/2  rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500 `}>

                                                            {/* <option key={item.value} value={item.Label}>{item.Label}</option> */}

                                                        </input>
                                                    </div>

                                                </div>
                                            </div>}

                                        <div className={`w-full gap-x-2  flex items-center font-normal  my-0.5 `}>
                                            <div className={`w-1/2 gap-x-2 text-left flex flex-col  font-normal px-2  `}>
                                                <p className='w-1/2  text-sm font-normal  text-gray-700'>Reason For Call</p>
                                                <textarea rows="2" placeholder="Enter your Reason" name="reason" onChange={(e) => handleInputChange(e)}
                                                    className={`block p-2.5 w-full rounded text-sm px-1 py-1 border border rounded-b accent-green-700 text-gray-900 focus:border-teal-500 focus:outline-none `} />
                                            </div>
                                            <div className={`w-1/2  text-left flex items-center font-normal overflow-hidden `}>
                                                <div className={`w-2/3 gap-x-2 text-left flex items-center font-normal  `}>
                                                    <p className=' w-[41%]  text-sm font-normal  text-gray-700'>Common Reasons</p>
                                                    <select name="commonreasons" value={''} onChange={(e) => handleInputChange(e)}
                                                        className={` focus:bg-white border border-transparent focus:bg-white  w-1/2  rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500 `}>
                                                        <option value={''}>~ Select ~</option>
                                                        {/* <option key={item.value} value={item.Label}>{item.Label}</option> */}
                                                        <option >test</option>
                                                    </select>
                                                </div>
                                                <div className={`w-1/3 gap-x-2 text-left flex items-center font-normal  `}>

                                                    <button
                                                        className="flex items-center cursor-pointer w-full justify-center text-gray-500 bg-yellow-400 border border-gray-300 focus:outline-none hover:text-black  hover:bg-yellow-600 hover:text-white focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1">
                                                        {/* <FontAwesomeIcon icon={faEye} /> */}
                                                        <label className="pl-2 cursor-pointer">Add Reason</label>
                                                        {/* <FontAwesomeIcon icon={faPrint} className='ml-2' /> */}
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            :
                            <div className=" my-2 w-full border rounded-md shadow items-center bg-gray-100 p-4  rounded-lg"
                                onMouseDown={(e) => e.stopPropagation()}>
                                <div className="mb-4">
                                    <div className="flex flex-col w-full ">
                                        <div className={`w-full gap-x-2 flex items-center font-normal my-0.5 `}>
                                            <div className={`w-2/3 gap-x-2 text-left  items-center font-normal  px-2 `}>
                                                <p className='w-full text-sm font-normal  text-gray-700'>Select Couple From the System (Type three (3) or more character/symbols to trigger the search)</p>
                                                <select name="QuickFind" value={''}
                                                    className={` focus:bg-white border border-transparent focus:bg-white  w-1/2  rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500 `}>
                                                    <option value={''}>~ Select ~</option>
                                                    {/* <option key={item.value} value={item.Label}>{item.Label}</option> */}
                                                    <option >test</option>
                                                </select>
                                            </div>
                                            <div className={`w-1/2 gap-x-2 text-left flex items-center font-normal  px-2 `}>
                                                <p className='w-1/3  text-sm font-normal  text-gray-700'>Time of Initial Call</p>
                                                <input name="calltime" type="time" value={''} onChange={(e) => handleInputChange(e)}
                                                    className={` focus:bg-white border border-transparent focus:bg-white  w-1/2  rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500 `}>

                                                    {/* <option key={item.value} value={item.Label}>{item.Label}</option> */}

                                                </input>
                                            </div>




                                        </div>

                                    </div>
                                </div>

                            </div>}
                        {<div className="border py-2 rounded flex justify-end items-center  px-6">
                            <button onClick={() => { setShowAddNewlist(0); handlecancel() }} className="bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded">Cancel</button>
                            <button
                                // onClick={() => { selectedPage === 1 ? (showAddNewlist == 1 ? handleSubmit('addCall') : handleSubmit('addUnAnswarCall')) : handleSubmit('Waitinglist') }}
                                onClick={() => handleSubmit()}
                                className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded">Confirm</button>
                        </div>}
                    </div>
                </div>
            </div>
            {/* {showModal && (
                <PinModal
                    title="Saving Couple Details"
                    setShowModal={setShowModal}
                    successFunction={(e) => handleSubmit(e)}
                />
            )} */}
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddNew)
