import React from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../../AppContextProvider ';
import { useNavigate } from 'react-router-dom';
import { getCycleTypeColor, getTextColor } from '../../../../../common';

export const AdminTemplets = ({ resetViewTemplate, isEditAllowed, TemplatesList,
    setTemplateId,
    setShowTemplist }, props) => {
    const theme = useAppContext().themeDetails;

    let navigate = useNavigate();
    return (
        <div className='w-full p-2'>
            <label className={' font-bold text- xl mt-3  ' + theme.txt_color + ' mt-1 border-b-[3px] border-b-amber-500'}>Default</label>
            {isEditAllowed ?
                <div className='  py-2 text-center items-center flex flex-wrap my-1'>
                    {/* {TemplatesList.filter(x => x.isAdmin).map((item, index) => ( */}
                    {TemplatesList.filter(x => x.isAdmin).slice(0, 1).map((item, index) => (
                        <>
                            <div className='p-1 md:w-2/3 flex mb-2 flex-wrap rounded  '
                                onClick={(e) => { resetViewTemplate(item.value); setTemplateId(item.value); setShowTemplist(true) }}
                                // onClick={() => navigate('/updateTreatmentPlan', { state: { artid: artid, coupleid: coupleid, clinicianName: treatmentPlanObj?.clinicianname, templateIdSelected: item.value, procedureTypeSelected: item.procedureTypeId, isAdminTemplate: true, clinicsList: clinicsList, coupleLocationId: additionalData.coupleLocationId, isEmptyART: isEmptyART } })}
                                onMouseEnter={(e) => { e.currentTarget.querySelector('#adminTemp_' + index).style.backgroundColor = getCycleTypeColor(item?.procedureTypeName?.split(' ')[0].trim()) || 'gray'; e.currentTarget.querySelector('#adminTemp_' + index).style.color = 'white' }}
                                onMouseLeave={(e) => { e.currentTarget.querySelector('#adminTemp_' + index).style.backgroundColor = 'white'; e.currentTarget.querySelector('#adminTemp_' + index).style.color = getCycleTypeColor(item?.procedureTypeName?.split(' ')[0].trim()) || 'gray' }}
                            >
                                <div className=' h-10  w-full gap-x-2 duration-300 flex  items-center shadow font-normal hover:text-white border border-gray-200  rounded bg-[#e4f2ff] border-[#3e88ca] hover:shadow-xl hover:scale-110 duration-300' >
                                    <div className='w-full h-full  flex border items-center  cursor-pointer rounded'>
                                        <div className={`flex flex-col ml-4 h-12 w-12 shadow-xl rounded items-center justify-center border bg-white`}
                                            //  style={{ backgroundColor: (getCycleTypeColor(item?.procedureTypeName?.split(' ')[0].trim()) || 'gray') }}
                                            id={'adminTemp_' + index}
                                            style={{ borderColor: (getCycleTypeColor(item?.procedureTypeName?.split(' ')[0].trim()) || 'gray'), color: (getCycleTypeColor(item?.procedureTypeName?.split(' ')[0].trim()) || 'gray') }}
                                        >
                                            <label className={` text-lg  font-bold  `} style={{}}>
                                                {item?.procedureTypeName ? item?.procedureTypeName == 'Ovulation Induction' ? 'OI' : item?.procedureTypeName == "Egg-donor IVF Cycle" ? 'ED' : item?.procedureTypeName?.split(' ')[0].trim() : ' - '}
                                            </label>
                                        </div>
                                        <div className=' pl-2'>
                                            <p className='xl:text-base md:text-xss cursor-pointer font-semibold text-gray-600'> Template</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </>
                    ))}
                </div>
                : ''}

        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AdminTemplets)