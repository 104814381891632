import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../../AppContextProvider ';
import { getArtStatClass, getTextColor } from '../../../../../common';
import { renderDataOnLoad } from '../../../../../Utils';

export const GenerateTheatreBooking = ({ }) => {
    const theme = useAppContext().themeDetails;
    const [formData, seFormData] = useState(null);
    const [showRports, setShowRports] = useState(false);




    const handleInputChange = (e, index) => {
        const { name, value, checked, type, options, selectedIndex, id } = e.target;

    };

    const handelBhcg = (e, index) => {
        const { name, value, checked, type, options, selectedIndex, id } = e.target;

    };

    return (
        <div className={" bg-white  p-2 w-full h-[99%] "}>
            <div className=' p-2 h-[50%] bg-red-300'>
                <div className={"  p-2  flex gap-2 items-center justify-between shadow-md w-full "} >
                    <label className={theme.txt_color + '  font-bold cursor-pointer mx-2 border-b-[3px] border-b-amber-500 '}>Theatre Booking Form ( 29-NOV-2024 )</label>
                    <div>
                        <button onClick={(e) => { setShowRports(true) }}
                            className="flex items-center cursor-pointer justify-center text-gray-500 bg-yellow-400 border border-gray-300 focus:outline-none hover:text-black  hover:bg-yellow-600 hover:text-white focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2">
                            {/* <FontAwesomeIcon icon={faEye} /> */}
                            <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.15716 0.607836C5.26072 0.711393 5.3125 0.834005 5.3125 0.975672C5.3125 1.11734 5.26072 1.23981 5.15716 1.34309L2.12293 4.37732L5.16752 7.42191C5.26417 7.51856 5.3125 7.63938 5.3125 7.78436C5.3125 7.92934 5.26072 8.05361 5.15716 8.15716C5.05361 8.26072 4.93099 8.3125 4.78933 8.3125C4.64766 8.3125 4.52519 8.26072 4.42191 8.15716L0.942381 4.66728C0.900958 4.62586 0.871548 4.58098 0.85415 4.53266C0.836753 4.48433 0.828192 4.43255 0.828468 4.37732C0.828468 4.32209 0.837167 4.27031 0.854564 4.22199C0.871962 4.17366 0.901234 4.12878 0.942381 4.08736L4.43226 0.59748C4.52892 0.500827 4.64808 0.4525 4.78974 0.4525C4.93141 0.4525 5.05388 0.504279 5.15716 0.607836Z" fill="#52575C" />
                            </svg>
                            <label className="pl-2 cursor-pointer">Back to Report</label>
                        </button>
                    </div>
                </div>
                <div className={theme.thumb + " " + theme.track + " shadow rounded  bg-white overflow-auto h-[89%] scrollbar  scrollbar-thin w-full "} style={{ width: "100%" }}>
                    {/* table header */}
                    <div className={"w-full flex py-1 sticky top-0 text-white rounded " + theme.navbar}>
                        <div
                            style={{ width: "4%" }}
                            className={"flex item-center justify-center  py-1"} >
                            <p className="text-sm font-bold">Order</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1"}>
                            <p className="text-sm font-bold">Admit Time</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1"}>
                            <p className="text-sm font-bold">Procedure Date</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1"}>
                            <p className="text-sm font-bold">Procedure Time</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1"}>
                            <p className="text-sm font-bold">Procedure Type</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1"}>
                            <p className="text-sm font-bold">Duration</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1"}>
                            <p className="text-sm font-bold">Hospital</p>
                        </div>
                        <div style={{ width: "15%" }} className={"py-1"}>
                            <p className="text-sm font-bold">Couple Details</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1"}>
                            <p className="text-sm font-bold">Clinician</p>
                        </div>
                        <div style={{ width: "10%" }} className={"py-1 "}>
                            <p className="text-sm font-bold">Anaesthetic</p>
                        </div>
                        <div style={{ width: "15%" }} className={"py-1 "}>
                            <p className="text-sm font-bold">Comment</p>
                        </div>
                    </div>
                    {/* display data */}
                    {/* {outcomeData && outcomeData?.map((item, index) => ( */}
                    <div className={" w-full  flex  my-0.5 gap-x-2 bg-[#DBE3DF] rounded shadow border items-center"}>
                        <div style={{ width: "3%" }} className={"flex item-center justify-center  "} >
                            <p className="text-sm font-medium text-gray-600">#</p>
                        </div>
                        <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                            <input type='date' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handleInputChange(e)} className='w-full pl-3 text-sm px-1 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />

                        </div>
                        <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                            <input type='date' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handleInputChange(e)} className='w-full pl-3 text-sm px-1 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                        </div >
                        <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                            <input type='date' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handleInputChange(e)} className='w-full pl-3 text-sm px-1 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                        </div>
                        <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                            <input type='date' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handleInputChange(e)} className='w-full pl-3 text-sm px-1 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                        </div>

                        <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                            <input type='date' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handleInputChange(e)} className='w-full pl-3 text-sm px-1 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                        </div >
                        <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                            <input type='date' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handleInputChange(e)} className='w-full pl-3 text-sm px-1 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                        </div >
                        <div style={{ width: "16%" }} className={"flex item-center justify-center"}>
                            <textarea rows="2" type='text' name='positivecomment' value={'outcome?.positivecommen'} onChange={(e) => handleInputChange(e)} className="focus:bg-white border w-full rounded flex item-center justify-center text-sm text-gray-900 focus:border-teal-500 focus:outline-none" />
                        </div >

                        <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                            <input type='date' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handleInputChange(e)} className='w-full pl-3 text-sm px-1 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                        </div>
                        <div style={{ width: "10%" }} className={"flex item-center justify-center flex-col "}>
                            <input type='date' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handleInputChange(e)} className='w-full pl-3 text-sm px-1 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                            <input type='text' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handleInputChange(e)} className='w-full pl-3 mt-1 text-sm px-1 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                            {/* <textarea rows="1" type='text' name='positivecomment' value={'outcome?.positivecommen'} onChange={(e) => handleInputChange(e)} className="focus:bg-white border w-full rounded flex item-center justify-center text-sm text-gray-900 focus:border-teal-500 focus:outline-none" /> */}
                        </div>

                        <div style={{ width: "15%" }} className={"flex item-center justify-center "}>
                            <textarea rows="2" type='text' name='positivecomment' value={'outcome?.positivecommen'} onChange={(e) => handleInputChange(e)} className="focus:bg-white border w-full rounded flex item-center justify-center text-sm text-gray-900 focus:border-teal-500 focus:outline-none" />
                        </div>

                    </div>
                    {/* ))} */}
                </div>
            </div>

            <div className='  p-2 h-[50%] bg-green-400'>
                <div className={" flex gap-2 items-center justify-between shadow-md w-full "} >
                    <label className={theme.txt_color + '  mb-2 font-bold cursor-pointer mx-2 border-b-[3px] border-b-amber-500 '}>Outside of Cycle Procedure  ( 29-NOV-2024 )</label>
                    <button onClick={(e) => { setShowRports(true) }}
                        className="flex items-center cursor-pointer justify-center text-gray-500 bg-yellow-400 border border-gray-300 focus:outline-none hover:text-black  hover:bg-yellow-600 hover:text-white focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2">
                        {/* <FontAwesomeIcon icon={faEye} /> */}
                        <label className="pl-2 cursor-pointer">View</label>
                    </button>
                </div>
                <div className={theme.thumb + " " + theme.track + " shadow rounded  bg-white overflow-auto h-[89%] scrollbar  scrollbar-thin w-full "} style={{ width: "100%" }}>
                    {/* table header */}
                    <div className={"w-full flex py-1 sticky top-0 text-white rounded " + theme.navbar}>
                        <div
                            style={{ width: "3%" }}
                            className={"flex item-center justify-center  py-1"} >
                            <p className="text-sm font-bold">Order</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1"}>
                            <p className="text-sm font-bold">Admit Time</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1"}>
                            <p className="text-sm font-bold">Procedure Date</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1"}>
                            <p className="text-sm font-bold">Procedure Time</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1"}>
                            <p className="text-sm font-bold">Procedure Type</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1"}>
                            <p className="text-sm font-bold">Duration</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1"}>
                            <p className="text-sm font-bold">Hospital</p>
                        </div>
                        <div style={{ width: "16%" }} className={"py-1"}>
                            <p className="text-sm font-bold">Couple Details</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1"}>
                            <p className="text-sm font-bold">Clinician</p>
                        </div>
                        <div style={{ width: "10%" }} className={"py-1 "}>
                            <p className="text-sm font-bold">Anaesthetic</p>
                        </div>
                        <div style={{ width: "15%" }} className={"py-1 "}>
                            <p className="text-sm font-bold">Comment</p>
                        </div>
                    </div>
                    {/* display data */}
                    {/* {outcomeData && outcomeData?.map((item, index) => ( */}
                    <div className={" w-full  flex  my-0.5 gap-x-2 bg-[#DBE3DF] rounded shadow border items-center"}>
                        <div style={{ width: "3%" }} className={"flex item-center justify-center  "} >
                            <p className="text-sm font-medium text-gray-600">#</p>
                        </div>
                        <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                            <input type='date' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handleInputChange(e)} className='w-full pl-3 text-sm px-1 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />

                        </div>
                        <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                            <input type='date' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handleInputChange(e)} className='w-full pl-3 text-sm px-1 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                        </div >
                        <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                            <input type='date' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handleInputChange(e)} className='w-full pl-3 text-sm px-1 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                        </div>
                        <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                            <input type='date' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handleInputChange(e)} className='w-full pl-3 text-sm px-1 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                        </div>

                        <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                            <input type='date' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handleInputChange(e)} className='w-full pl-3 text-sm px-1 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                        </div >
                        <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                            <input type='date' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handleInputChange(e)} className='w-full pl-3 text-sm px-1 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                        </div >
                        <div style={{ width: "16%" }} className={"flex item-center justify-center"}>
                            <textarea rows="2" type='text' name='positivecomment' value={'outcome?.positivecommen'} onChange={(e) => handleInputChange(e)} className="focus:bg-white border w-full rounded flex item-center justify-center text-sm text-gray-900 focus:border-teal-500 focus:outline-none" />
                        </div >

                        <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                            <input type='date' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handleInputChange(e)} className='w-full pl-3 text-sm px-1 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                        </div>
                        <div style={{ width: "10%" }} className={"flex item-center justify-center flex-col "}>
                            <input type='date' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handleInputChange(e)} className='w-full pl-3 text-sm px-1 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                            <input type='text' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handleInputChange(e)} className='w-full pl-3 mt-1 text-sm px-1 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                            {/* <textarea rows="1" type='text' name='positivecomment' value={'outcome?.positivecommen'} onChange={(e) => handleInputChange(e)} className="focus:bg-white border w-full rounded flex item-center justify-center text-sm text-gray-900 focus:border-teal-500 focus:outline-none" /> */}
                        </div>

                        <div style={{ width: "15%" }} className={"flex item-center justify-center "}>
                            <textarea rows="2" type='text' name='positivecomment' value={'outcome?.positivecommen'} onChange={(e) => handleInputChange(e)} className="focus:bg-white border w-full rounded flex item-center justify-center text-sm text-gray-900 focus:border-teal-500 focus:outline-none" />
                        </div>

                    </div>
                    {/* ))} */}
                </div>
            </div>
            {showRports && (
                <div className="fixed z-50 inset-0 overflow-y-auto " >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div style={{ backdropFilter: 'blur(7px)' }} className="fixed inset-0 transition-opacity filter" aria-hidden="true" >
                            <div className="absolute inset-0 bg-gray-700 opacity-70  " ></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl " aria-hidden="true" ></span>
                        {/* <Draggable> */}
                        <div className={`sm:my-8   sm:max-w-lg sm:w-full filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`} role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                            <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative  `}>
                                <svg className={' absolute top-2 right-2  cursor-pointer shadow  font-semibold  '}
                                    onClick={() => setShowRports(false)}
                                    width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545" />
                                </svg>
                                <h3 className={theme.txt_color + " text-xl text-center mb-6 leading-6 font-bold text-gray-900"} id="modal-headline" >Add Outside of Cycle Procedure</h3>
                                <div className=' w-full items-center bg-gray-100 p-3   rounded-t mb-b' onMouseDown={(e) => e.stopPropagation()}>
                                    <div className={' w-full text-left px-4 p-1 flex   cursor-pointer '}>
                                        <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Admit Time</p>
                                        <input type='date' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handelBhcg(e)} className='w-1/2 pl-3 text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                                    </div>
                                    <div className={' w-full text-left px-4 p-1 flex   cursor-pointer '}>
                                        <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Procedure Time</p>
                                        <input type='date' name='bhcgtestdate' value={formData?.bhcgtestdate} onChange={(e) => handelBhcg(e)} className='w-1/2 pl-3 text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                                    </div>
                                    <div className={' w-full text-left px-4 p-1 flex   cursor-pointer '}>
                                        <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Hospital</p>
                                        <select name='bhcgtest' value={formData?.bhcgtest || ''} onChange={(e) => handelBhcg(e)} className={"w-1/2 pl-3 text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none"}>
                                            <option value="">~ Select Type ~</option>
                                            {/* {lookUpList && lookUpList.filter((item) => item.LookUpTypeId === LookUpType.BHCGTestResult)
                                                .map((item) => (
                                                    <option key={item.Value} value={item.Value}>
                                                        {item.Label}
                                                    </option>
                                                ))} */}
                                            <option  >select</option>
                                        </select>
                                    </div>
                                    <div className={' w-full text-left px-4 p-1 flex   cursor-pointer '}>
                                        <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Patient </p>
                                        <input type='text' name='bhcglevel' placeholder='Type three (3) or more character/symbols' value={formData?.bhcglevel} onChange={(e) => handelBhcg(e)} className='w-1/2 pl-3 text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                                    </div>
                                    <div className={' w-full text-left px-4 p-1 flex   cursor-pointer '}>
                                        <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Clinician</p>
                                        <select name='bhcgtest' value={formData?.bhcgtest || ''} onChange={(e) => handelBhcg(e)} className={"w-1/2 pl-3 text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none"}>
                                            <option value="">~ Select Type ~</option>
                                            {/* {clinicianlist && clinicianlist.map((item) => (
                                                <option value={item.ActualUserId}
                                                    selected={coupleData && coupleData.userid == item.ActualUserId}
                                                >{item?.username}</option>
                                            ))} */}
                                            <option  >select</option>
                                        </select></div>
                                    <div className={' w-full text-left px-4 p-1 flex   cursor-pointer '}>
                                        <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Procedure Type</p>
                                        <select name='bhcgtest' value={formData?.bhcgtest || ''} onChange={(e) => handelBhcg(e)} className={"w-1/2 pl-3 text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none"}>
                                            <option value="">~ Select Type ~</option>
                                            {/* {lookUpList && lookUpList.filter((item) => item.LookUpTypeId === LookUpType.BHCGTestResult)
                                                .map((item) => (
                                                    <option key={item.Value} value={item.Value}>
                                                        {item.Label}
                                                    </option>
                                                ))} */}
                                            <option  >select</option>
                                        </select> </div>
                                    <div className={' w-full text-left px-4 p-1 flex   cursor-pointer '}>
                                        <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Anaesthetic</p>
                                        <select name='bhcgtest' value={formData?.bhcgtest || ''} onChange={(e) => handelBhcg(e)} className={"w-1/2 pl-3 text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none"}>
                                            <option value="">~ Select Type ~</option>
                                            <option value='1' >Genral</option>
                                            <option value='2' >Local</option>
                                            <option value='3'>N/A</option>
                                            <option value='4'>Conscious Sedation</option>
                                        </select>  </div>
                                    <div className={' w-full text-left px-4 p-1 flex   cursor-pointer ' + theme.hoverBg}>
                                        <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Anaesthetist</p>
                                        <input type='text' name='bhcglevel' value={formData?.bhcglevel} onChange={(e) => handelBhcg(e)} className='w-1/2 pl-3 text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                                    </div>
                                    <div className={' w-full text-left px-4 p-1 flex   cursor-pointer ' + theme.hoverBg}>
                                        <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Comment</p>

                                        <textarea rows="3" type='text' name='bhcglevel' value={formData?.bhcglevel} onChange={(e) => handleInputChange(e)} className="focus:bg-white border w-1/2 rounded py-1 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none" />
                                    </div>
                                </div>
                                <div className=' py-2 rounded flex justify-end border px-6'>
                                    <button className="bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded" onClick={() => setShowRports(false)}> Cancel</button>
                                    <button className="bg-transparent  cursor-pointer ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded">  Generate Form</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(GenerateTheatreBooking)