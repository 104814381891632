import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useAppContext } from '../../../../../AppContextProvider ';
import ReactToPrint from 'react-to-print';
import StimPrint from './StimPrint';
import { AppendDateWithDayNo, NumberExtractor, calculateDifference, filterNonEmptyProperties, getCorrectDateForInput, getDateByRange, getMinMaxByKey, handlePrintTitle } from '../../../../../common';
import { useNavigate } from 'react-router-dom';
import MiddlePrint from './MiddlePrint';

const PrintPop = ({ setPopup, ApiCall_GET, dayOneOfCycle, artData, artid, medication, NumberOfColums, setMedication }) => {
    let navigate = useNavigate();

    const theme = useAppContext().themeDetails;
    const componentRef = useRef();
    const [updatedArtData, setUpdatedArtData] = useState({ ...artData });

    const [ShowModal, setShowModal] = useState(true)
    const [selectedIndex, setSelectedIndex] = useState(0)

    const initObj = {
        artid: artData.artid,
        semdel: artData.semdel,
        tritime: artData.tritime,
        fasttime: artData.fasttime,
        hosptime: artData.hosptime,
        semtime: artData.semtime,
        embtime: artData.embtime,
        handnotes: artData.handnotes,
        displaydates: artData.displaydates,
        PrintLutealMedication: artData.PrintLutealMedication,
        tridate: getCorrectDateForInput(artData.tridate),
        fastdate: getCorrectDateForInput(artData.fastdate),
        hospdate: getCorrectDateForInput(artData.hospdate),
        semdate: getCorrectDateForInput(artData.semdate),
        embdate: getCorrectDateForInput(artData.embdate),


        scandate1day: artData.scandate1 ? calculateDifference(artData?.DayofCycle, artData.scandate1) + 1 : artData.scandate1day,
        scandate2day: artData.scandate2 ? calculateDifference(artData?.DayofCycle, artData.scandate2) + 1 : artData.scandate2day,
        scandate3day: artData.scandate3 ? calculateDifference(artData?.DayofCycle, artData.scandate3) + 1 : artData.scandate3day,
        scandate4day: artData.scandate4 ? calculateDifference(artData?.DayofCycle, artData.scandate4) + 1 : artData.scandate4day,
        scandate5day: artData.scandate5 ? calculateDifference(artData?.DayofCycle, artData.scandate5) + 1 : artData.scandate5day,
        scandate1: getCorrectDateForInput(artData.scandate1),
        scandate2: getCorrectDateForInput(artData.scandate2),
        scandate3: getCorrectDateForInput(artData.scandate3),
        scandate4: getCorrectDateForInput(artData.scandate4),
        scandate5: getCorrectDateForInput(artData.scandate5),

        bloodtest1day: artData.bloodtest1 ? calculateDifference(artData?.DayofCycle, artData.bloodtest1) + 1 : artData.bloodtest1day,
        bloodtest2day: artData.bloodtest2 ? calculateDifference(artData?.DayofCycle, artData.bloodtest2) + 1 : artData.bloodtest2day,
        bloodtest3day: artData.bloodtest3 ? calculateDifference(artData?.DayofCycle, artData.bloodtest3) + 1 : artData.bloodtest3day,
        bloodtest4day: artData.bloodtest4 ? calculateDifference(artData?.DayofCycle, artData.bloodtest4) + 1 : artData.bloodtest4day,
        bloodtest5day: artData.bloodtest5 ? calculateDifference(artData?.DayofCycle, artData.bloodtest5) + 1 : artData.bloodtest5day,
        bloodtest1: getCorrectDateForInput(artData.bloodtest1),
        bloodtest2: getCorrectDateForInput(artData.bloodtest2),
        bloodtest3: getCorrectDateForInput(artData.bloodtest3),
        bloodtest4: getCorrectDateForInput(artData.bloodtest4),
        bloodtest5: getCorrectDateForInput(artData.bloodtest5)

    }
    // scandate1,scandate2,scandate3,scandate4,scandate5,bloodtest1,bloodtest2,bloodtest3,bloodtest4,bloodtest5,
    // from art a

    const [formObj, setFormObj] = useState(filterNonEmptyProperties(initObj));


    var minDay = 0, maxDay = 0;
    var LutealMin = 0, LutealMax = 0;
    const setMinMax = () => {


        LutealMax = updatedArtData?.PrintLutealMedication ? getMinMaxByKey(medication?.Luteal, 'dayoffset', 'max') : 0
        LutealMin = updatedArtData?.PrintLutealMedication ? getMinMaxByKey(medication?.Luteal, 'dayoffset', 'min') : 0
        var StimMax = getMinMaxByKey(medication?.Stim, 'dayoffset', 'max')
        var StimMin = getMinMaxByKey(medication?.Stim, 'dayoffset', 'min')
        var TriggerMax = getMinMaxByKey(medication?.Trigger, 'dayoffset', 'max')
        var TriggerMin = getMinMaxByKey(medication?.Trigger, 'dayoffset', 'min')



        var minValues = [
            // LutealMin,
            StimMin,
            TriggerMin,
            updatedArtData?.scandate1day,
            updatedArtData?.scandate2day,
            updatedArtData?.scandate3day,
            updatedArtData?.scandate4day,
            updatedArtData?.scandate5day,
            updatedArtData?.bloodtest1day,
            updatedArtData?.bloodtest2day,
            updatedArtData?.bloodtest3day,
            updatedArtData?.bloodtest4day,
            updatedArtData?.bloodtest5day
        ];
        var nonZeroMinValues = minValues.filter(value => value);

        minDay = Math.min(...nonZeroMinValues);
        maxDay = Math.max(
            //LutealMax,
            StimMax,
            TriggerMin,
            updatedArtData?.scandate1day,
            updatedArtData?.scandate2day,
            updatedArtData?.scandate3day,
            updatedArtData?.scandate4day,
            updatedArtData?.scandate5day,
            updatedArtData?.bloodtest1day,
            updatedArtData?.bloodtest2day,
            updatedArtData?.bloodtest3day,
            updatedArtData?.bloodtest4day,
            updatedArtData?.bloodtest5day);

    }

    setMinMax()

    const handleInputChange = (e, val, override) => {
        var { name, value } = e.target;

        if (override) { value = val }


        if (name.length == 0) {//to check the specific dates

        }
        else if (name.startsWith('scandate')) {
            var num = NumberExtractor(name);
            setFormObj({
                ...formObj,
                ['scandate' + num]: getDateByRange(artData?.DayofCycle, value - 1),
                [name]: value,
            });
            setUpdatedArtData({
                ...updatedArtData,
                ...formObj,
                ['scandate' + num]: getDateByRange(artData?.DayofCycle, value - 1),
                [name]: value,
            });
        }
        else if (name.startsWith('bloodtest')) {
            var num = NumberExtractor(name);
            setFormObj({
                ...formObj,
                ['bloodtest' + num]: getDateByRange(artData?.DayofCycle, value - 1),
                [name]: value,
            });
            setUpdatedArtData({
                ...updatedArtData,
                ...formObj,
                ['bloodtest' + num]: getDateByRange(artData?.DayofCycle, value - 1),
                [name]: value
            });
        }
        else {
            setFormObj({
                ...formObj,
                [name]: value,
            });
            setUpdatedArtData({
                ...updatedArtData,
                ...formObj,
                [name]: value
            });
        }


    };
    const savePrintData = () => {

    }

    return (

        <div className="fixed z-50 inset-0 overflow-y-auto " >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div onClick={() => setPopup(false)} style={{ backdropFilter: 'blur(7px)' }} className="fixed inset-0 transition-opacity filter" aria-hidden="true" >
                    <div className="absolute inset-0 bg-gray-700 opacity-70  " ></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl " aria-hidden="true" ></span>
                {/* <Draggable> */}
                <div className={`sm:my-8  w-5/6 h-90 filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`}
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className={`bg-white h-[70vh] px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative  `}>
                        <svg className={' absolute top-2 right-2 cursor-pointer shadow  font-semibold  '}
                            onClick={() => setPopup(false)}
                            width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545" />
                        </svg>
                        <h3 className={theme.txt_color + " text-xl text-center mb-6 leading-6 font-bold text-gray-900"} id="modal-headline" >Print Patient Medication Schedule Handout</h3>
                        <div className=' my-3 w-full items-center bg-gray-100 p-2   rounded-lg' onMouseDown={(e) => e.stopPropagation()}>

                            <div className='flex flex-wrap gap-x-2 '>
                                <div className='w-full shadow rounded  pb-2'>
                                    <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                                        <p className='text-sbase font-medium'>Important Dates</p>
                                    </div>

                                    <div className='p-2 flex flex-wrap justify-between '>
                                        <div className={'flex w-1/2  items-center mt-1'}>
                                            <label htmlFor="tridate" className={" w-60 text-sm"} >TRIGGER INJECTION Date</label>
                                            <input type='date' onChange={handleInputChange} name='tridate' value={formObj?.tridate} className={theme.cheqColor + ' cursor-pointer shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                            <input type='text' onChange={handleInputChange} name='tritime' placeholder='Time' value={formObj?.tritime} className={theme.cheqColor + ' ml-3  shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                        </div>
                                        <div className={'flex w-1/2  items-center mt-3'}>
                                            <label htmlFor="fastdate" className={" w-60 text-sm"} >FASTING Date</label>
                                            <input type='date' onChange={handleInputChange} name='fastdate' value={formObj?.fastdate} className={theme.cheqColor + ' cursor-pointer shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                            <input type='text' onChange={handleInputChange} name='fasttime' placeholder='Time' value={formObj?.fasttime} className={theme.cheqColor + ' ml-3  shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                        </div>
                                        <div className={'flex w-1/2  items-center mt-3'}>
                                            <label htmlFor="hospdate" className={" w-60 text-sm"} >HOSPITAL ADMISSION Date</label>
                                            <input type='date' onChange={handleInputChange} name='hospdate' value={formObj?.hospdate} className={theme.cheqColor + ' cursor-pointer shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                            <input type='text' onChange={handleInputChange} name='hosptime' placeholder='Time' value={formObj?.hosptime} className={theme.cheqColor + ' ml-3  shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                        </div>
                                        <div className={'flex w-1/2  items-center mt-3'}>
                                            <label htmlFor="semdel" className={" w-60 text-sm"} >SEMEN SAMPLE</label>
                                            <select onChange={handleInputChange} name='semdel' value={formObj?.semdel} className={theme.cheqColor + ' w-40 cursor-pointer shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '}>
                                                <option value="">Select Semen Sample</option>
                                                <option>At CFC</option>
                                                <option>Drop Off</option>
                                                <option>TESA - LA</option>
                                                <option>TESA - GA</option>
                                                <option>PESA - LA</option>
                                                <option>PESA - GA</option>
                                                <option>N/A</option>
                                            </select>
                                        </div>
                                        <div className={'flex w-1/2  items-center mt-3'}>
                                            <label htmlFor="semdate" className={" w-60 text-sm"} >SEMEN SAMPLE Date</label>
                                            <input type='date' onChange={handleInputChange} name='semdate' value={formObj?.semdate} className={theme.cheqColor + ' cursor-pointer shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                            <input type='text' onChange={handleInputChange} name='semtime' placeholder='Time' value={formObj?.semtime} className={theme.cheqColor + ' ml-3  shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                        </div>
                                        <div className={'flex w-1/2  items-center mt-3'}>
                                            <label htmlFor="embdate" className={" w-60 text-sm"} >EMBRYO TRANSFER Date</label>
                                            <input type='date' onChange={handleInputChange} name='embdate' value={formObj?.embdate} className={theme.cheqColor + ' cursor-pointer shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                            <input type='text' onChange={handleInputChange} name='embtime' placeholder='Time' value={formObj?.embtime} className={theme.cheqColor + ' ml-3  shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                        </div>

                                        <div className={'flex w-1/2 text-left mt-3'}>
                                            <input id="PrintLutealMedication"
                                                onChange={(e) => handleInputChange(e, e.target.checked, true)}
                                                type='checkbox' checked={formObj?.PrintLutealMedication} className={theme.cheqColor + ' border mr-3 focus:outline-none'} name="PrintLutealMedication" />
                                            <label className={" text-sm   "} htmlFor="PrintLutealMedication" >Print Luteal Medication</label>
                                        </div>
                                        <div className={'flex w-1/2 text-left mt-3 hidden'}>
                                            <input id="displaydates"
                                                onChange={(e) => { handleInputChange(e, e.target.checked, true); }}
                                                type='checkbox' checked={formObj?.displaydates} className={theme.cheqColor + ' border mr-3 focus:outline-none'} name={"displaydates"} />
                                            <label className={" text-sm  "} htmlFor="displaydates" > Display Dates on Handout</label>
                                        </div>
                                    </div>
                                </div>
                                <div className=' self-end rounded flex justify-end items-center py-2 w-full'>
                                    <ReactToPrint
                                        trigger={() => (
                                            <button onClick={savePrintData} className="bg-transparent flex justify-end gap-2 items-center ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded">
                                                <svg width="19" height="14" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19 5.33333V12C19 12.1768 18.9285 12.3464 18.8013 12.4714C18.674 12.5964 18.5014 12.6667 18.3214 12.6667H15.6071V15.3333C15.6071 15.5101 15.5357 15.6797 15.4084 15.8047C15.2811 15.9298 15.1085 16 14.9286 16H4.07143C3.89146 16 3.71886 15.9298 3.59161 15.8047C3.46435 15.6797 3.39286 15.5101 3.39286 15.3333V12.6667H0.678571C0.498603 12.6667 0.326006 12.5964 0.198749 12.4714C0.0714921 12.3464 0 12.1768 0 12V5.33333C0 4.23083 0.963572 3.33333 2.14853 3.33333H3.39286V0.666667C3.39286 0.489856 3.46435 0.320287 3.59161 0.195262C3.71886 0.070238 3.89146 0 4.07143 0H14.9286C15.1085 0 15.2811 0.070238 15.4084 0.195262C15.5357 0.320287 15.6071 0.489856 15.6071 0.666667V3.33333H16.8515C18.0364 3.33333 19 4.23083 19 5.33333ZM4.75 3.33333H14.25V1.33333H4.75V3.33333ZM14.25 10.6667H4.75V14.6667H14.25V10.6667ZM15.6071 7C15.6071 6.80222 15.5474 6.60888 15.4356 6.44443C15.3238 6.27998 15.1648 6.15181 14.9788 6.07612C14.7928 6.00043 14.5882 5.98063 14.3907 6.01921C14.1933 6.0578 14.0119 6.15304 13.8696 6.29289C13.7272 6.43275 13.6303 6.61093 13.591 6.80491C13.5517 6.99889 13.5719 7.19996 13.6489 7.38268C13.7259 7.56541 13.8564 7.72159 14.0238 7.83147C14.1912 7.94135 14.388 8 14.5893 8C14.8592 8 15.1181 7.89464 15.309 7.70711C15.4999 7.51957 15.6071 7.26522 15.6071 7Z" fill="currentColor" />
                                                </svg>
                                                <label className='text-sm pl-1 cursor-pointer'> Print</label>
                                            </button>
                                        )}
                                        pageStyle="@page {size: landscape }"
                                        content={() => componentRef.current}
                                        onBeforeGetContent={() => handlePrintTitle(true)}
                                        onAfterPrint={() => handlePrintTitle(false)}
                                    />

                                </div>
                                {/* <div className='w-1/2 gap-x-2  shadow rounded pb-2'>
                                    <div className='w-full gap-x-2  flex shadow rounded pb-2'>
                                        <div className='w-1/2 shadow rounded pb-2 '>
                                            <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                                                <p className='text-sbase font-medium'>Scan Dates</p>
                                            </div>
                                            <div className={'w-full flex text-left pr-3 pt-2 items-center '}>
                                                <label htmlFor="scandate1day" className={" text-sm mx-2 w-40"} >Scan Date #1</label>
                                                <select value={formObj?.scandate1day} disabled={true} onChange={(e) => { handleInputChange(e); }} name='scandate1day' className={theme.cheqColor + ' w-40 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} >
                                                    <option key={0} value={0}>~Select~</option>
                                                    {AppendDateWithDayNo(artData?.DayofCycle, true, formObj?.scandate1day)}
                                                </select>
                                            </div>
                                            <div className={'w-full flex text-left pr-3 pt-2 items-center '}>
                                                <label htmlFor="scandate2day" className={" text-sm mx-2 w-40"} >Scan Date #2</label>
                                                <select value={formObj?.scandate2day} disabled={true} onChange={(e) => { handleInputChange(e); }} name='scandate2day' className={theme.cheqColor + ' w-40 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} >
                                                    <option key={0} value={0}>~Select~</option>
                                                    {AppendDateWithDayNo(artData?.DayofCycle, true, formObj?.scandate2day)}
                                                </select>                                            </div>
                                            <div className={'w-full flex text-left pr-3 pt-2 items-center '}>
                                                <label htmlFor="scandate3day" className={" text-sm mx-2 w-40"} >Scan Date #3</label>
                                                <select value={formObj?.scandate3day} disabled={true} onChange={(e) => { handleInputChange(e); }} name='scandate3day' className={theme.cheqColor + ' w-40 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} >
                                                    <option key={0} value={0}>~Select~</option>
                                                    {AppendDateWithDayNo(artData?.DayofCycle, true, formObj?.scandate3day)}
                                                </select>                                            </div>
                                            <div className={'w-full flex text-left pr-3 pt-2 items-center '}>
                                                <label htmlFor="scandate4day" className={" text-sm mx-2 w-40"} >Scan Date #4</label>
                                                <select value={formObj?.scandate4day} disabled={true} onChange={(e) => { handleInputChange(e); }} name='scandate4day' className={theme.cheqColor + ' w-40 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} >
                                                    <option key={0} value={0}>~Select~</option>
                                                    {AppendDateWithDayNo(artData?.DayofCycle, true, formObj?.scandate4day)}
                                                </select>                                            </div>
                                            <div className={'w-full flex text-left pr-3 pt-2 items-center '}>
                                                <label htmlFor="scandate5day" className={" text-sm mx-2 w-40"} >Scan Date #5</label>
                                                <select value={formObj?.scandate5day} disabled={true} onChange={(e) => { handleInputChange(e); }} name='scandate5day' className={theme.cheqColor + ' w-40 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} >
                                                    <option key={0} value={0}>~Select~</option>
                                                    {AppendDateWithDayNo(artData?.DayofCycle, true, formObj?.scandate5day)}
                                                </select>
                                            </div>

                                        </div>
                                        <div className='w-1/2 shadow rounded pb-2 '>
                                            <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                                                <p className='text-sbase font-medium'>BLOOD TEST DATES</p>
                                            </div>
                                            <div className={'w-full flex text-left pr-3 pt-2 items-center '}>
                                                <label htmlFor="bloodtest1day" className={" text-sm mx-2 w-40"} >Blood Date #1</label>
                                                <select value={formObj?.bloodtest1day} disabled={true} onChange={(e) => { handleInputChange(e); }} name='bloodtest1day' className={theme.cheqColor + ' w-40 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} >
                                                    <option key={0} value={0}>~Select~</option>
                                                    {AppendDateWithDayNo(artData?.DayofCycle, true, formObj?.bloodtest1day)}
                                                </select>
                                            </div>
                                            <div className={'w-full flex text-left pr-3 pt-2 items-center '}>
                                                <label htmlFor="bloodtest1day" className={" text-sm mx-2 w-40"} >Blood Date #2</label>
                                                <select value={formObj?.bloodtest2day} disabled={true} onChange={(e) => { handleInputChange(e); }} name='bloodtest2day' className={theme.cheqColor + ' w-40 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} >
                                                    <option key={0} value={0}>~Select~</option>
                                                    {AppendDateWithDayNo(artData?.DayofCycle, true, formObj?.bloodtest2day)}
                                                </select>
                                            </div>
                                            <div className={'w-full flex text-left pr-3 pt-2 items-center '}>
                                                <label htmlFor="bloodtest3day" className={" text-sm mx-2 w-40"} >Blood Date #3</label>
                                                <select value={formObj?.bloodtest3day} disabled={true} onChange={(e) => { handleInputChange(e); }} name='bloodtest3day' className={theme.cheqColor + ' w-40 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} >
                                                    <option key={0} value={0}>~Select~</option>
                                                    {AppendDateWithDayNo(artData?.DayofCycle, true, formObj?.bloodtest3day)}
                                                </select>
                                            </div>
                                            <div className={'w-full flex text-left pr-3 pt-2 items-center '}>
                                                <label htmlFor="bloodtest4day" className={" text-sm mx-2 w-40"} >Blood Date #4</label>
                                                <select value={formObj?.bloodtest4day} disabled={true} onChange={(e) => { handleInputChange(e); }} name='bloodtest4day' className={theme.cheqColor + ' w-40 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} >
                                                    <option key={0} value={0}>~Select~</option>
                                                    {AppendDateWithDayNo(artData?.DayofCycle, true, formObj?.bloodtest4day)}
                                                </select>
                                            </div>
                                            <div className={'w-full flex text-left pr-3 pt-2 items-center '}>
                                                <label htmlFor="bloodtest5day" className={" text-sm mx-2 w-40"} >Blood Date #5</label>
                                                <select value={formObj?.bloodtest5day} disabled={true} onChange={(e) => { handleInputChange(e); }} name='bloodtest5day' className={theme.cheqColor + ' w-40 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} >
                                                    <option key={0} value={0}>~Select~</option>
                                                    {AppendDateWithDayNo(artData?.DayofCycle, true, formObj?.bloodtest5day)}
                                                </select>
                                            </div>

                                        </div >
                                    </div >
                                    <div className='full shadow rounded '>
                                        <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                                            <p className='text-sbase font-medium'>Information for Patient Options</p>
                                        </div>
                                        <div className={' flex text-left p-2 pb-1 items-center flex-wrap'}>
                                            <p className='text-sbase font-medium'>Additional NOTES / INSTRUCTIONS for the Patient</p>

                                            <textarea id="handnotes" rows="3" name='handnotes'
                                                onChange={handleInputChange} value={formObj?.handnotes}
                                                className="  w-full text-sm px-1 py-0.5 border rounded accent-green-700  text-gray-700 focus:border-teal-500 focus:outline-none" placeholder="Add comments ..." />
                                        </div>
                                    </div>
                                </div > */}
                            </div >
                            {/* <pre>{JSON.stringify(formObj, null, 2)}</pre> */}











                        </div >
                    </div >
                </div >
                {/* </Draggable> */}
            </div >

            <div className='hidden'>
                <div ref={componentRef}  >
                    <MiddlePrint artid={artid} minDay={minDay} maxDay={maxDay} artData={updatedArtData} setMedication={setMedication} NumberOfColums={NumberOfColums} medication={medication} dayOneOfCycle={artData?.DayofCycle} LutealMax={LutealMax} LutealMin={LutealMin} />
                </div>
            </div>
        </div >

    );
};

export default PrintPop;

