import React, { useState, forwardRef, useEffect } from 'react';
import logo from '../../../../Assets/images/white.png';
import moment from 'moment'
import { NumberExtractor, formatTime, getCorrectDate, getPatientInformation, getUserInformation } from '../../../../common';


const TreatmentPlanPrint = forwardRef(({ theme, artid, patientData, version, comments, ReferenceItemList, procedureTypeText, clinicianName, countComment, TreatmentPlanState, selectedIndex, clinicsList }, ref) => {

    const user = getUserInformation()
    const displaySummaryItem = (item) => {
        return (
            <p className='flex flex-wrap items-center text-sm text-gray-500 font-medium' >
                {ReferenceItemList?.filter(x => x.parentId === item.id).map((innerItem) => (
                    <p key={innerItem.id} className='flex text-sm'>
                        {((innerItem.dataTypeId === 1 && ReferenceItemList.filter(x => x.parentId === innerItem.id && x.value !== '' && x.value !== '0' && x.value !== null).length > 0) || ((innerItem.dataTypeId === 3 || innerItem.dataTypeId === 5) && innerItem.value !== '' && innerItem.value !== '0' && innerItem.value !== null) ?
                            (
                                <p className='mr-1 text-sm'>{innerItem.label + ' .'}</p>
                            )
                            :
                            (((innerItem.dataTypeId === 2 || innerItem.dataTypeId === 9 || innerItem.dataTypeId === 8) && innerItem.value !== '' && innerItem.value !== '0' && innerItem.value !== null ?
                                (<p className='mr-1 text-sm'>{innerItem.label + " : " + innerItem.value + '  .'}</p>)
                                : (innerItem.dataTypeId === 4 && innerItem.value !== '' && innerItem.value !== '0' && innerItem.value !== null ?
                                    (<p className='mr-1 text-sm'>{innerItem.id === 364 ? (innerItem.label + " : " + clinicsList?.filter(x => x.locationid === Number(innerItem?.value))[0]?.ClinicName + '  .') : innerItem.id === 354 ? (innerItem.label + " : " + clinicsList?.filter(x => x.hospitalid === Number(innerItem?.value))[0]?.name + '  .') : (NumberExtractor(innerItem.value) ? innerItem.label + " : Day-" + innerItem.value + '  .' : innerItem.label + " : " + innerItem.value + '  .') + '  .'}</p>)
                                    : '')
                            ))
                        )}

                        {displaySummaryItem(innerItem)}</p>
                ))}
            </p>
        )
    }

    function setDateFormat(givenDate) {
        if (givenDate) {
            var rtndate = givenDate.split(' ')[0];
            return rtndate;
        }
    }

    return (
        <div id="divPrint" className=" flex min-h-screen  w-full p-2 delay-900 duration-700 pagebreak ">
            {/* Header */}
            <div className="relative w-full  mx-auto bg-white p-3">
                <div className={` ${theme.navbar} ` + 'flex justify-between  items-center mb-2  p-2'} >

                    <div className='flex items-center justify-end '>
                        <img src={logo} className="logoCFC" alt="img" width='160px' />
                        <p className={`dot-${user?.BrandCode?.toUpperCase()} `} style={{ backgroundColor: `#${user?.ChartingColor}` }}></p>
                        <p className=' text-white  text-sm ml-1'>{user?.LocationName}</p>
                    </div>
                    <div className='flex justify-end text-white items-center '>
                        <label className='  text-sm'>{moment(new Date()).format('ddd DD-MMM-yyyy')}</label>
                    </div>

                </div>
                {/* <div className='header-space'></div> */}
                <main className='pt-2 '>
                    <label className='text-3xl  border-b-[3px] border-b-amber-500 '> Treatment Plan </label><label>v {version}</label>
                    {/* patient info */}
                    <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                        <p className={theme.tHedUpdte + '  text-sm font-medium text-white text-left p-1  rounded-t'}>Patient / Couple Details</p>
                        <div className='flex w-full'>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                                <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Patient </p>
                                <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500  '}>
                                        {patientData?.patientName}
                                    </p>
                                    <p className={'text-sm text-gray-500  '}>
                                        <b>DOB: </b>{patientData?.patientDOB}
                                    </p>
                                </div>
                            </div>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Partner
                                </p>
                                <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500  '}>
                                        {patientData?.partnerName}
                                    </p>
                                    <p className={'text-sm text-gray-500  '}>
                                        <b>DOB: </b> {patientData?.partnerDOB}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='flex w-full'>
                            <div className=' text-left  mx-2 flex items-center  py-1    w-1/2 '>
                                <p className=' text-sm font-medium   text-gray-700'>Clinician</p>
                                <p className={'pl-2 text-sm text-gray-500  '}> {patientData?.clinicianName}</p>

                            </div>
                            {/* <div className=' text-left  mx-2 flex items-center  py-1      w-1/2 '>
                                <p className=' text-sm font-medium   text-gray-700'>Couple Code</p>
                                <p className={'pl-2 text-sm text-gray-500  '}> {patientData?.coupleCode}</p>

                            </div> */}
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                <p className=' text-sm font-medium w-24  text-gray-700'>Couple Code</p>
                                <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500  '}>
                                        {patientData?.coupleCode}
                                    </p>
                                    <p className={'text-sm text-gray-500  '}>
                                        <b>{'ART' + artid}</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex mt-4'>
                        <div className='w-50 shadow mr-1 pb-2'>
                            <div className={theme.tHedUpdte + ' text-white text-left p-1  rounded-t'}>
                                <p className='text-sbase font-medium'>Treatment Procedures</p>
                            </div>
                            <div className=' text-left py-1 mx-2 flex flex-col  border-b-[1px]  border-b-gray-200   hover:bg-green-100'>
                                <p className='cursor-pointer text-sbase font-medium w-52 text-gray-700'>Procedure Type</p>
                                <p className={'text-sm text-gray-500 pl-3 '}> {procedureTypeText}</p>
                            </div>
                            {ReferenceItemList?.filter(x => x.objectTypeId === 1 && x.parentId === null && ReferenceItemList?.filter(y => y.objectTypeId === 1 && y.parentId === x.id && y.value !== '' && y.value !== null && y.value !== '0').length > 0)?.map((item) => (
                                <div className=' py-1  border-b-[1px]  border-b-gray-200  text-left  mx-2 flex flex-wrap  flex-col hover:bg-green-100'>
                                    <p className=' cursor-pointer text-sbase  font-medium w-52 text-gray-700'>{item.label}  </p>
                                    <p className='text-sm text-gray-500 pl-3'>  {displaySummaryItem(item)} </p>
                                </div>
                            ))}
                        </div>
                        <div className='w-50 shadow ml-1'>
                            <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                                <p className='text-sbase font-medium'>Treatment Medications</p>
                            </div>

                            {ReferenceItemList?.filter(x => x.objectTypeId === 2 && x.parentId === null && ReferenceItemList?.filter(y => y.parentId === x.id).length > 0)?.map((item) => (
                                <div className='  border-b-[1px] py-1 border-b-gray-200  text-left  mx-2 flex flex-wrap  flex-col hover:bg-green-100'>
                                    <p className=' cursor-pointer text-sbase  font-medium w-52 text-gray-700'>{item.label}  </p>
                                    <p className='text-sm text-gray-500 pl-3 '>  {displaySummaryItem(item)} </p>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* mt-[80vh] */}
                    <div className='w-full mt-4 '>
                        <div className={theme.tHedUpdte + ' text-white text-left p-1 px-2 rounded-t'}>
                            <p className='text-sbase font-medium '>Comments</p>
                        </div>
                        <div className='border-gray-200 border  shadow'>
                            <textarea style={{
                                overflowY: 'hidden', // Hide scrollbar
                                // height: 'fit-content', // Let the textarea adjust its height based on content
                                minHeight: comments?.split('\n').length + 1, // Minimum height to fit content
                                // resize: 'none', // Prevent manual resizing by the user
                            }} rows={comments && Math.max(comments?.split('\n').length + 1, 1)} className='text-xss text-left px-2 font-medium w-full  disabled ' disabled value={comments} />
                        </div>
                    </div>
                    <div className='w-full mt-2 '>
                        <div className={theme.tHedUpdte + ' text-white text-left p-1 px-2  rounded-t flex justify-between'}>
                            <label className='text-sbase font-medium'>Previous Comments</label>
                            <label className='text-xss font-medium'>Total Comments# {countComment()}</label>
                        </div>

                        {TreatmentPlanState && TreatmentPlanState.map((item, index) => (
                            item.comments && item.comments?.length && index > selectedIndex ?
                                <div className={' border-gray-200   border  shadow  rounded shadow border my-1 '}>
                                    <p className={' text-sm text-gray-800 font-medium round px-2 py-0.5 bg-gray-300'}>Comments from <b>Treatment Plan</b> v{item.version} ~ by <i><b>{item.commentBy}</b></i> {' ~ Added on: ' + getCorrectDate(item.addedon) + ' ' + formatTime(item.addedon)}</p>
                                    {/* <textarea rows={item.comments && Math.max(item.comments.split('\n').length, 1)} className='text-xss text-left  font-medium w-full p-1 disabled ' disabled value={comments} /> */}
                                    <textarea style={{
                                        overflowY: 'hidden', // Hide scrollbar
                                        // height: 'fit-content', // Let the textarea adjust its height based on content
                                        // minHeight: 'fit-content', // Minimum height to fit content
                                        resize: 'none', // Prevent manual resizing by the user
                                    }}
                                        rows={item.comments && Math.max(item.comments?.split('\n').length + 1, 1)} className='text-xss text-left px-2  font-medium w-full disabled ' disabled value={item.comments} />
                                </div>
                                : ''
                        ))}

                    </div>
                    <div className='flex mt-2 '>
                        <div className='w-full'>
                            <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                                <p className='text-sbase font-medium'>Indemnification</p>
                            </div>
                            <div className='border-gray-200  p-1  border border-gray-300 shadow'>
                                <div className='text-xss text-left text-gray-500 font-medium flex place-items-start '>
                                    <label htmlFor="IndemnityCheckBox">
                                        {/*  */}
                                        {clinicianName ?
                                            'Dr ' + clinicianName + ' has digitally signed regulatory requirements'
                                            : <p>Clinician <b>HAS NOT</b> digitally signed regulatory requirements.</p>
                                        }
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex mt-2 hidden'>
                        <div className='w-50 shadow ml-1'>
                            <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                                <p className='text-sbase font-medium'>Scan Results</p>
                            </div>
                            <div className=' border-b-gray-200  text-left p-1 flex flex-wrap flex-col'>
                                <p className='cursor-pointer text-xss text-gray-700'>No scan results have been entered yet.</p>

                            </div>
                        </div>
                        <div className='flex mt-2'>
                            <div className='w-50 shadow ml-1'>
                                <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                                    <p className='text-sbase font-medium'>Scan Results</p>
                                </div>
                                <div className=' border-b-gray-200  text-left p-1 flex flex-wrap flex-col'>
                                    <p className='cursor-pointer text-xss text-gray-700'>No scan results have been entered yet.</p>
                                </div>
                            </div>
                            <div className='w-50 shadow ml-1'>
                                <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                                    <p className='text-sbase font-medium'>Blood Test Results</p>
                                </div>
                                <div className=' border-b-gray-200  text-left p-1 flex flex-wrap flex-col'>
                                    <p className='cursor-pointer text-xss text-gray-700'>No blood test results have been entered yet.</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </main >
                <div className='header-space hidden'></div>
                <footer className='flex mt-3 p-2 hidden'>
                    <div className='w-full flex items-center'>
                        <img className='rounded-full  h-14' src={logo} alt={"logo"} />
                        <div className='border-gray-200  p-1 ml-2 '>
                            <p className='text-xss text-left  m-0 text-gray-700 font-medium'>
                                Document generated on 19-JUN-2023 16:10:05 AEST<br />
                                Do NOT rely solely on the information on this print-out. Always check the Current Treatment Plan in the FSF Database for changes.<br />
                                Form version 8.1 saved on 31-OCT-2022 09:41:04 AEST by Michelle Brown. </p>

                        </div>
                    </div>
                </footer>
            </div >
        </div >

    );
});

export default TreatmentPlanPrint;