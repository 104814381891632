import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../../../AppContextProvider ';
import { getSession } from '../../../../../../session';
import { ApiCall_GET } from '../../../../../../Redux/Generic/action'
import { formatTime, getCorrectDate, getCorrectDateForInput, handlePrintTitle, replaceEmptyObjectsWithNull } from '../../../../../../common';
import ReactToPrint from 'react-to-print';
import AndrologyResultViewTesaPesaPrint from './AndrologyResultViewTesaPesaPrint';

export const AndrologyResultViewTesaPesa = ({ setOpenForm, ApiCall_GET, andrologytypeid, andrologyid, ...otherProps }) => {
    const componentRef = useRef();
    const [couple, setCouple] = useState(null)
    const [andrology, setAndrology] = useState(null)

    useEffect(() => {
        setCouple(getSession('patient'))
        ApiCall_GET('Lab/GetAndrologyDetails/' + andrologyid + '/report', true).then(function (payload) {
                   setAndrology(replaceEmptyObjectsWithNull(payload));
        });
    }, [])

    const theme = useAppContext().themeDetails;

    return (
        <div className="fixed z-50 inset-0 overflow-y-auto " >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div style={{ backdropFilter: 'blur(7px)' }} className="fixed inset-0 transition-opacity filter" aria-hidden="true" >
                    <div className="absolute inset-0 bg-gray-700 opacity-70  " ></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl " aria-hidden="true" ></span>
                {/* <Draggable> */}
                <div className={`sm:my-8   w-2/3  filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`}
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className={`bg-white px-4 py-5 sm:p-6 sm:pb-4 relative  `}>

                        <div className='flex items-center justify-between mb-1 '>
                            <h3 className={theme.txt_color + " text-xl  leading-6 font-bold text-gray-900"} id="modal-headline" >Andrology Report : TESA / PESA</h3>
                            <div className="flex gap-2">
                                <ReactToPrint
                                    trigger={() => (
                                        <button id="printButton" className="bg-white flex mr-0.5 justify-center items-center mr-4  hover:bg-green-600 text-green-600 font-medium hover:text-white py-0.5 px-2 border-[1px] border-gray-300 hover:border-transparent rounded">
                                            <svg width="19" height="14" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19 5.33333V12C19 12.1768 18.9285 12.3464 18.8013 12.4714C18.674 12.5964 18.5014 12.6667 18.3214 12.6667H15.6071V15.3333C15.6071 15.5101 15.5357 15.6797 15.4084 15.8047C15.2811 15.9298 15.1085 16 14.9286 16H4.07143C3.89146 16 3.71886 15.9298 3.59161 15.8047C3.46435 15.6797 3.39286 15.5101 3.39286 15.3333V12.6667H0.678571C0.498603 12.6667 0.326006 12.5964 0.198749 12.4714C0.0714921 12.3464 0 12.1768 0 12V5.33333C0 4.23083 0.963572 3.33333 2.14853 3.33333H3.39286V0.666667C3.39286 0.489856 3.46435 0.320287 3.59161 0.195262C3.71886 0.070238 3.89146 0 4.07143 0H14.9286C15.1085 0 15.2811 0.070238 15.4084 0.195262C15.5357 0.320287 15.6071 0.489856 15.6071 0.666667V3.33333H16.8515C18.0364 3.33333 19 4.23083 19 5.33333ZM4.75 3.33333H14.25V1.33333H4.75V3.33333ZM14.25 10.6667H4.75V14.6667H14.25V10.6667ZM15.6071 7C15.6071 6.80222 15.5474 6.60888 15.4356 6.44443C15.3238 6.27998 15.1648 6.15181 14.9788 6.07612C14.7928 6.00043 14.5882 5.98063 14.3907 6.01921C14.1933 6.0578 14.0119 6.15304 13.8696 6.29289C13.7272 6.43275 13.6303 6.61093 13.591 6.80491C13.5517 6.99889 13.5719 7.19996 13.6489 7.38268C13.7259 7.56541 13.8564 7.72159 14.0238 7.83147C14.1912 7.94135 14.388 8 14.5893 8C14.8592 8 15.1181 7.89464 15.309 7.70711C15.4999 7.51957 15.6071 7.26522 15.6071 7Z" fill="currentColor" />
                                            </svg><label className='text-sm pl-1'>Print</label>
                                        </button>
                                    )}

                                    pageStyle="@page { size: 210 x 297 mm  }"
                                    content={() => componentRef.current}
                                    onBeforeGetContent={()=> handlePrintTitle(true)}
                                    onAfterPrint={()=>handlePrintTitle(false)}
                                />

                                <svg className={'  top-2 right-2 cursor-pointer shadow  font-semibold  '}
                                    onClick={() => setOpenForm(false)}
                                    width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545" />
                                </svg>
                            </div>
                        </div>

                        {/* ====== */}
                        <div className='flex items-center'>
                            <div className='w-8/12  shadow mr-1 mt-3 mb-2'>
                                <p className={theme.tHedUpdte + '  text-sm font-medium text-white text-left p-1  rounded-t'}>Patient / Couple Details</p>
                                <div className='flex w-full'>
                                    <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                                        <p className='cursor-pointer text-sbase font-medium   text-gray-700'>{andrologytypeid == 4 ? 'Partner' : 'Patient'} </p>
                                        <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                            <p className={'text-sm text-gray-500  '}>
                                                {couple?.partnerFullName}
                                            </p>
                                            <p className={'text-sm text-gray-500  '}>
                                                <b>DOB: </b>{couple?.partnerDOB}
                                            </p>
                                        </div>
                                    </div>
                                    <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                        <div className='flex pl-2 flex-wrap justify-between items-center w-full'>

                                            <p className={'text-sm text-gray-500  '}>
                                                <b>Andrology ID : </b> {andrologyid}
                                            </p>
                                            <p className={'text-sm text-gray-500  '}>
                                                <b>Sample # : </b> {andrology?.sample_no}
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className='flex w-full '>
                                    <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                        <p className=' text-sm font-medium   text-gray-700'>Address</p>
                                        <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                            <p className={'text-sm text-gray-500  '}>
                                                {couple?.partnerAddress}
                                            </p>
                                            <p className={'text-sm text-gray-500 flex gap-1 '}>
                                                <b>Phone (H): </b>{couple?.PartnerPhoneHome}
                                                <b>Phone (M): </b>{couple?.PartnerPhoneMobile}
                                            </p>

                                        </div>
                                    </div>
                                    <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                        <div className='flex pl-2 flex-wrap justify-between items-center w-full'>

                                            <p className={'text-sm text-gray-500  '}>
                                                <b>Couple Code: </b>{couple?.coupleCode}
                                            </p>
                                            <p className={'text-sm text-gray-500  '}>
                                                <b>Proc Date : </b> {getCorrectDate(andrology?.sampledate)}
                                            </p>
                                        </div>
                                    </div>



                                </div>
                            </div>
                            <div className='w-4/12  shadow mr-1 mt-3 mb-2'>
                                <p className={theme.tHedUpdte + '  text-sm font-medium text-white text-left p-1  rounded-t'}>Patient / Couple Details</p>
                                <div className='flex w-full'>
                                    <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-full  '>
                                        <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Clinician</p>
                                        <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                            <p className={'text-sm text-gray-500  '}>
                                                {couple?.clinicianName}
                                            </p>
                                            <p className={'text-sm text-gray-500  '}>
                                                <b>Clinician Ph : </b> {andrology?.clinicianphone}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex w-full'>
                                    <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-full  '>
                                        <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Anaesthetic</p>
                                        <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                            <p className={'text-sm text-gray-500  '}>
                                                {andrology?.tp_anaesthetic}
                                            </p>
                                            <p className={'text-sm text-gray-500  '}>
                                                <b>Scientist: </b>{andrology?.sciName}
                                            </p>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                        <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                            <p className={theme.tHedUpdte + ' text-sm font-medium text-white text-left p-1 rounded-t'}>
                                Media Type <span style={{ fontSize: '9px' }}>Tests performed at Room Temperature</span>
                            </p>
                            <div className='flex justify-between items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                <div className='flex py-1 w-60'>
                                    <p className='text-sm font-medium text-gray-700'>Procedure Type:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_type_collection}</div>
                                </div>
                                <div className='flex py-1 w-60'>
                                    <p className='text-sm font-medium text-gray-700'>Collected in:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.mediaTypeName}</div>
                                </div>
                                <div className='flex py-1 w-60'>
                                    <p className='text-sm font-medium text-gray-700'>Batch:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.batchName}</div>
                                </div>
                            </div>
                            <div className='flex justify-between items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                <div className='flex py-1 w-60'>
                                    <p className='text-sm font-medium text-gray-700'>Expire Date:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{getCorrectDate(andrology?.tp_type_expire)}</div>
                                </div>
                                <div className='flex py-1 w-60'>
                                    <p className='text-sm font-medium text-gray-700'>For ICSI Today:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_icsi ? "Yes" : "No"}</div>
                                </div>
                                <div className='flex py-1 w-60'>
                                    <p className='text-sm font-medium text-gray-700'>Freeze Consent Excess Material:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_fcem ? "Yes" : "No"}</div>
                                </div>
                            </div>
                            <div className='flex justify-between  w-full px-2 border-b-[1px] border-b-gray-200'>
                                <div className=' py-1 w-8/12'>
                                    <p className='text-sm font-medium text-gray-700'>Reason for Biopsy:</p>
                                    <div className='flex  py-1 w-full'>
                                        <textarea
                                            style={{
                                                // overflowY: 'hidden', // Hide scrollbar
                                                resize: 'none', // Prevent manual resizing by the user
                                            }}
                                            disabled
                                            rows={andrology?.comments && Math.max(andrology.comments.split('\n').length, +1)}
                                            className='text-xss border border-gray-300 text-left  font-medium w-full p-1 disabled  scrollbar-thin'
                                            value={andrology?.tp_biopsy_reason}
                                        />
                                    </div>
                                </div>
                                <div className='flex py-1 justify-end items-end  w-4/12'>
                                    <p className='text-sm font-medium text-gray-700'>Consent Form Checked:</p>
                                    <div className='pl-2 text-sm text-gray-500'>__________________________________</div>
                                </div>

                            </div>
                        </div>


                        <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                            <p className={theme.tHedUpdte + ' text-sm font-medium text-white text-left p-1 rounded-t'}>
                                Procedure
                            </p>
                            <div className='flex justify-between items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                <div className='flex py-1 w-1/2'>
                                    <p className='text-sm font-medium w-80 text-gray-700'>Needle Epididymal (PESA):</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_1 ? 'Yes' : 'No'}</div>
                                </div>
                                <div className='flex py-1 w-1/6'>
                                    <p className='text-sm font-medium text-gray-700'>Quality of Sample:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_1_qual}</div>
                                </div>
                                <div className='flex py-1 w-1/6'>
                                    <p className='text-sm font-medium text-gray-700'>Blood:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_1_blood}</div>
                                </div>
                            </div>
                            <div className='flex justify-between items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                <div className='flex py-1 w-1/2'>
                                    <p className='text-sm font-medium w-80 text-gray-700'>Needle Seminiferous Tubules (Micro TESA):</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_2 ? 'Yes' : 'No'}</div>
                                </div>
                                <div className='flex py-1 w-1/6'>
                                    <p className='text-sm font-medium text-gray-700'>Quality of Sample:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_2_qual}</div>
                                </div>
                                <div className='flex py-1 w-1/6'>
                                    <p className='text-sm font-medium text-gray-700'>Blood:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_2_blood}</div>
                                </div>
                            </div>
                            <div className='flex justify-between items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                <div className='flex py-1 w-1/2'>
                                    <p className='text-sm font-medium w-80 text-gray-700'>Open Biopsy Epididymal:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_3 ? 'Yes' : 'No'}</div>
                                </div>
                                <div className='flex py-1 w-1/6'>
                                    <p className='text-sm font-medium text-gray-700'>Quality of Sample:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_3_qual}</div>
                                </div>
                                <div className='flex py-1 w-1/6'>
                                    <p className='text-sm font-medium text-gray-700'>Blood:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_3_blood}</div>
                                </div>
                            </div>
                            <div className='flex justify-between items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                <div className='flex py-1 w-1/2'>
                                    <p className='text-sm font-medium w-80 text-gray-700'>Open Biopsy Seminiferous Tubules:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_4 ? 'Yes' : 'No'}</div>
                                </div>
                                <div className='flex py-1 w-1/6'>
                                    <p className='text-sm font-medium text-gray-700'>Quality of Sample:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_4_qual}</div>
                                </div>
                                <div className='flex py-1 w-1/6'>
                                    <p className='text-sm font-medium text-gray-700'>Blood:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_4_blood}</div>
                                </div>
                            </div>
                            <div className='flex justify-between items-center w-full px-2'>
                                <div className='flex py-1 w-1/2'>
                                    <p className='text-sm font-medium w-80 text-gray-700'>Micro Surgical Testicular Sperm Extraction (TESE):</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_5 ? 'Yes' : 'No'}</div>
                                </div>
                                <div className='flex py-1 w-1/6'>
                                    <p className='text-sm font-medium text-gray-700'>Quality of Sample:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_5_qual}</div>
                                </div>
                                <div className='flex py-1 w-1/6'>
                                    <p className='text-sm font-medium text-gray-700'>Blood:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_5_blood}</div>
                                </div>
                            </div>
                        </div>


                        <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                            <p className={theme.tHedUpdte + ' text-sm font-medium text-white text-left p-1 rounded-t'}>
                                Sample Analysis
                            </p>
                            <div className='flex justify-between items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                <div className='flex py-1 w-1/3'>
                                    <p className='text-sm w-40 font-medium text-gray-700'>Motile Sperm Detected:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_motile ? 'Yes' : 'No'}</div>
                                </div>
                                <div className='flex py-1 w-1/3'>
                                    <p className='text-sm font-medium text-gray-700'>Sperm Motility:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_Motility ? andrology?.tp_Motility.toFixed(0) : 0}</div>
                                    <p className='text-sm font-medium text-gray-700'>%</p>
                                </div>
                            </div>
                            <div className='flex justify-between items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                <div className='flex py-1 w-1/3'>
                                    <p className='text-sm w-40 font-medium text-gray-700'>Immotile Sperm Detected:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_immotile ? 'Yes' : 'No'}</div>

                                </div>
                                <div className='flex py-1 w-1/3'>
                                    <p className='text-sm font-medium text-gray-700'>Sperm Morphology:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_morphology ? andrology?.tp_morphology.toFixed(0) : 0}</div>
                                    <p className='text-sm font-medium text-gray-700'>%</p>
                                </div>
                            </div>
                            <div className='flex justify-between items-center w-full px-2'>
                                <div className='flex py-1 w-1/3'>
                                    <p className='text-sm w-40 font-medium text-gray-700'>No Sperm Detected:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_no_sperm ? 'Yes' : 'No'}</div>
                                </div>
                                <div className='flex py-1 w-1/3'>
                                    <p className='text-sm font-medium text-gray-700'>Suitable for ICSI:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_icsi_suitable ? 'Yes' : 'No'}</div>

                                </div>
                            </div>
                        </div>

                        <div className="w-full  shadow text-sm mr-1  my-2">
                            <p className={theme.tHedUpdte + '   font-medium text-white text-left p-1  rounded-t'}>Comments</p>
                            <textarea
                                style={{
                                    // overflowY: 'hidden', // Hide scrollbar
                                    resize: 'none', // Prevent manual resizing by the user
                                }}
                                disabled
                                // rows={andrology?.comments ? Math.max(andrology.comments.split('\n').length + 1, 2) : 2}
                                // rows={andrology?.comments && Math.max(andrology.comments.split('\n').length, +1)}
                                className='text-xss text-left  font-medium w-full p-1 disabled  scrollbar-thin'
                                value={andrology?.comments}
                            />
                        </div>

                        <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                            <p className={theme.tHedUpdte + ' text-sm font-medium text-white text-left p-1 rounded-t'}>
                                Cryopreservation
                            </p>
                            <div className='flex justify-between items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                <div className='flex py-1 w-1/10'>
                                    <p className={theme.tHedUpdte + ' text-white p-1 rounded-md text-sm font-medium text-gray-700'}>Freeze Details</p>
                                </div>
                                <div className='flex py-1  w-1/4 '>
                                    <p className='text-sm font-medium text-gray-700'>Excess Material for Cryopreservation:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_cryopreservation}</div>
                                </div>
                                <div className='flex py-1  w-1/4'>
                                    <p className='text-sm font-medium text-gray-700'>Freeze Method:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_method}</div>
                                </div>
                                <div className='flex py-1  w-1/4'>
                                    <p className='text-sm font-medium text-gray-700'>Number of Straws or Vials Frozen</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_vails_frozen}</div>
                                </div>
                            </div>
                            <div className='flex flex-col w-full px-2'>

                                <div className='flex flex-wrap justify-between items-center w-full border-b-[1px] border-b-gray-200'>
                                    <div className='flex py-1 w-1/4'>
                                        <p className='text-sm font-medium text-gray-700'>Goblet Colour:</p>
                                        <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_goblit_colour}</div>
                                    </div>
                                    <div className='flex py-1 w-1/4 '>
                                        <p className='text-sm font-medium text-gray-700'>Tank:</p>
                                        <div className='pl-2 text-sm text-gray-500'>{andrology?.storagetankname}</div>
                                    </div>
                                    <div className='flex py-1 w-1/4'>
                                        <p className='text-sm font-medium text-gray-700'>Position:</p>
                                        <div className='pl-2 text-sm text-gray-500'>{andrology?.storagelocationname}</div>
                                    </div>
                                    <div className='flex py-1 w-1/4'>
                                        <p className='text-sm font-medium text-gray-700'>Scientist:</p>
                                        <div className='pl-2 text-sm text-gray-500'>{andrology?.freezeSciName}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                            <p className={`${theme.tHedUpdte} text-sm font-medium text-white text-left p-1 rounded-t`}>
                                Witnessing
                            </p>
                            <div className='flex flex-wrap w-full px-2 border-b-[1px] border-b-gray-200'>
                                <div className='flex py-1 w-1/2 '>
                                    <p className={theme.tHedUpdte + ' text-white p-1 rounded-md text-sm font-medium text-gray-700'}>Patient Identification</p>
                                </div>
                                <div className='flex py-1 w-1/2'>
                                    <p className={theme.tHedUpdte + ' text-white p-1 rounded-md text-sm font-medium text-gray-700'}>Sample Cryopreservation (If Applicable)</p>
                                </div>
                            </div>
                            <div className='flex flex-wrap w-full px-2 border-b-[1px] border-b-gray-200'>
                                <div className='flex py-1 w-1/3'>
                                    <p className='text-sm font-medium text-gray-700'>Scientist:</p>
                                    <div className='pl-2 text-sm text-gray-500'> ________________</div>
                                </div>
                                <div className='flex py-1 w-1/6 '>
                                    <p className='text-sm font-medium text-gray-700'>Date:</p>
                                    <div className='pl-2 text-sm text-gray-500'> __________ </div>
                                </div>
                                <div className='flex py-1 w-1/3'>
                                    <p className='text-sm font-medium text-gray-700'>Scientist:</p>
                                    <div className='pl-2 text-sm text-gray-500'> ________________</div>
                                </div>
                                <div className='flex py-1 w-1/6'>
                                    <p className='text-sm font-medium text-gray-700'>Date:</p>
                                    <div className='pl-2 text-sm text-gray-500'> __________</div>
                                </div>
                            </div>
                            <div className='flex flex-wrap w-full px-2 border-b-[1px] border-b-gray-200'>
                                <div className='flex py-1 w-1/3'>
                                    <p className='text-sm font-medium text-gray-700'>Witness:</p>
                                    <div className='pl-2 text-sm text-gray-500'> ________________</div>
                                </div>
                                <div className='flex py-1 w-1/6 '>
                                    <p className='text-sm font-medium text-gray-700'>Date:</p>
                                    <div className='pl-2 text-sm text-gray-500'> __________ </div>
                                </div>
                                <div className='flex py-1 w-1/3'>
                                    <p className='text-sm font-medium text-gray-700'>Witness:</p>
                                    <div className='pl-2 text-sm text-gray-500'> ________________</div>
                                </div>
                                <div className='flex py-1 w-1/6'>
                                    <p className='text-sm font-medium text-gray-700'>Date:</p>
                                    <div className='pl-2 text-sm text-gray-500'> __________</div>
                                </div>
                            </div>
                            <div className='flex flex-wrap w-full px-2 border-b-[1px] border-b-gray-200'>
                                <div className='flex py-1 w-1/2 '>
                                    <p className={theme.tHedUpdte + ' text-white p-1 rounded-md text-sm font-medium text-gray-700'}>Sample Preparation for Microinjection</p>

                                </div>
                                <div className='flex py-1 w-1/2'>
                                    <p className={theme.tHedUpdte + ' text-white p-1 rounded-md text-sm font-medium text-gray-700'}>Scientific Report Completed</p>
                                </div>
                            </div>
                            <div className='flex flex-wrap w-full px-2 border-b-[1px] border-b-gray-200'>
                                <div className='flex py-1 w-1/3'>
                                    <p className='text-sm font-medium text-gray-700'>Scientist:</p>
                                    <div className='pl-2 text-sm text-gray-500'> ________________</div>
                                </div>
                                <div className='flex py-1 w-1/6 '>
                                    <p className='text-sm font-medium text-gray-700'>Date:</p>
                                    <div className='pl-2 text-sm text-gray-500'> __________ </div>
                                </div>
                                <div className='flex py-1 w-1/3'>
                                    <p className='text-sm font-medium text-gray-700'>Report By:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.reportBy}</div>
                                </div>
                                <div className='flex py-1 w-1/6'>
                                    <p className='text-sm font-medium text-gray-700'>Date:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.reportDate}</div>
                                </div>
                            </div>
                            <div className='flex flex-wrap w-full px-2 border-b-[1px] border-b-gray-200'>
                                <div className='flex py-1 w-1/3'>
                                    <p className='text-sm font-medium text-gray-700'>Witness:</p>
                                    <div className='pl-2 text-sm text-gray-500'> ________________</div>
                                </div>
                                <div className='flex py-1 w-1/6  '>
                                    <p className='text-sm font-medium text-gray-700'>Date:</p>
                                    <div className='pl-2 text-sm text-gray-500'> __________ </div>
                                </div>
                                <div className='flex py-1 w-1/3'>
                                    <p className='text-sm font-medium text-gray-700'>Report By:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.verifyBy}</div>
                                </div>
                                <div className='flex py-1 w-1/6'>
                                    <p className='text-sm font-medium text-gray-700'>Date:</p>
                                    <div className='pl-2 text-sm text-gray-500'>{andrology?.verifyDate}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='hidden'>
                <div ref={componentRef} >
                    <AndrologyResultViewTesaPesaPrint couple={couple} andrology={andrology} andrologytypeid={andrologytypeid} />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_GET }

export default connect(mapStateToProps, mapDispatchToProps)(AndrologyResultViewTesaPesa)