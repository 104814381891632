import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiCall_POST, ApiCall_GET } from '../../../../../Redux/Generic/action'
import { formatTime, generateScanInput, getCorrectDate, getScanResults, getUniqueObjectsByKey, handlePrintTitle, replaceEmptyObjectsWithNull, returnNullIfEmpty } from '../../../../../common';
import DocterSheetPrint from './DocterSheetPrint';
import ReactToPrint from 'react-to-print';
import { useAppContext } from '../../../../../AppContextProvider ';
import ScreeningTestsList from '../../ScreeningTestsList';

export const DoctorSheet = ({ ApiCall_POST, ApiCall_GET }) => {
    let navigate = useNavigate();
    let location = useLocation();
    var coupleid = location.state.coupleid;
    // var artData = location.state.artData;
    var artid = location.state.artid;
    var coupleDetails = location.state.coupleDetails;
    const componentRef = useRef();
    const [doctorView, setDoctorView] = useState(null);
    const theme = useAppContext().themeDetails;
    const [comments, setComments] = useState();
    const [commentsShow, setCommentsShow] = useState(true);
    // const [TreatmentPlanState, setTreatmentPlanState] = useState([]);
    var isEmptyART = location.state ? location.state.isEmptyART : false;
    const [bfcomments, setBfComments] = useState('');
    const [commetPop, setcommetPop] = useState(null)
    const [isScreeningOpen, setIsScreeningOpen] = useState(false);




    const createTreatmentPlanList = (List) => {

        var returnObj;
        var returnObjList = [];

        const uniqueIds = [...new Set(List.map(item => item.treatmentPlanID))];
        uniqueIds.map((id) => {

            var FirstTpObject = List.filter(x => x.treatmentPlanID === id)[0];

            returnObj = {
                'treatmentPlanID': id,
                'isCurrent': FirstTpObject.currentPlan,
                'version': FirstTpObject.formVersionMajor + '.' + FirstTpObject.formVersionMinor,
                'artid': FirstTpObject.artid,
                'procedureTypeName': FirstTpObject.procedureTypeName,
                'procedureTypeId': FirstTpObject.procedureTypeId,
                'addedon': FirstTpObject.addedon,
                'comments': FirstTpObject.comments,
                'isIndemnitySigned': FirstTpObject.indemnitySigned,
                'clinicianname': FirstTpObject.clinicianname,
                'items': List.filter(x => x.treatmentPlanID === id)
            }

            returnObjList.push(returnObj);
        })


        return returnObjList;
    }

    useEffect(() => {

        ApiCall_GET('Art/GetDoctorDetailsByArtId/' + artid, true).then(function (payload) {
            payload.ScanDetails.ScanDetailList = payload.ScanDetails?.ScanDetailList?.sort((a, b) => a.scanresultid - b.scanresultid).reverse();
            payload.BloodTestDetails = payload.BloodTestDetails?.sort((a, b) => a.testdayofcycle - b.testdayofcycle).reverse();
            // payload.?.reverse();
            setDoctorView(payload);
        });
        ApiCall_GET('TreatmentPlan/GetTreatmentPlan?coupleId=' + coupleid + '&treatmentPlanId=0&artId=' + (isEmptyART ? 0 : artid), true).then(function (payload) {
            setComments(payload);
            // var tpl = createTreatmentPlanList(payload);
        });
        ApiCall_GET('Art/GetTreatmentBookingList?coupleId=' + coupleid + '&artId=' + artid, true).then(function (payload) {
            setBfComments(payload)

        });
    }, []);

    const handleMedication = (str, DisplayName) => {
        if (!str) { return ''; }
        const substrings = str.split(',')//.map(substring => substring.trim());

        return (
            <div className={' border-b-[2px]  border-b-gray-200  text-left py-0.5 p-1 flex   cursor-pointer ' + theme.hoverBg}>
                <label className=' text-sbase color-green font-bold w-28 text-gray-700'> {DisplayName} </label>
                <div className='py-1'>
                    {substrings[0].length > 0 ? substrings.map((substring, index) => (
                        <div className={'  mr-1 text-sbase text-gray-500 font-medium'} key={index}>
                            <label className={" text-sbase w-2/6"} >{substring?.split('=>')[0] ? substring?.split('=>')[0] : '--'}</label>
                            {<label className={" text-sbase w-1/6 px-2"} >
                                {substring?.split('Dosage=>')[1]?.split('=>')[0] ?
                                    ' - ' + substring?.split('Dosage=>')[1]?.split('=>')[0] + ' - ' :
                                    (
                                        substring?.split('Dose=>')[1]?.split('=>')[0] ?
                                            ' - ' + substring?.split('Dose=>')[1]?.split('=>')[0] :
                                            (
                                                substring?.split('=>')[1]?.split('=>')[0] ?
                                                    ' - ' + substring?.split('=>')[1] + (substring?.split('=>')[2] ? ' - ' + substring?.split('=>')[2] : '') :
                                                    ''
                                            )
                                    )
                                }
                            </label>}
                            <label className={" text-sm w-2/6 capitalize"} >{
                                substring.toLowerCase()?.split('when')[1] ?
                                    substring?.toLowerCase()?.split('when')[1]?.replaceAll('=>', '')?.replaceAll(':', '') :
                                    (substring.toLowerCase()?.split('start')[1] ?
                                        'Start Day ' + substring?.toLowerCase()?.split('start')[1]?.replaceAll('=>', ' - ').replaceAll('- finish', '/ Finish Day')
                                        : '')}
                            </label>
                            {/* <label className={" text-sm w-1/6"} >{DisplayName}</label> */}
                        </div>
                    )) : 'N/A'}
                </div>
            </div>


        );
    };
    const CommentPopup = (comments, index) => {
        setcommetPop({ comments, index })
    }

    return (
        <div className={theme.thumb + ' ' + theme.track + ' w-full p-2 bg-white  overflow-auto scrollbar  scrollbar-thin'} style={{ height: "100%" }} >
            <div className={' flex items-between p-0.5  pl-2 rounded  '}>
                <div className='w-2/5 text-left'>
                    <label className={' pl-2text-base font-medium '}> Cycle Name : </label>
                    <label className={theme.txt_color + ' ml-2 text-base font-medium '}> {doctorView?.DoctorDetails?.ProcedureTypeName}</label>
                </div>
                <div className='w-3/5 text-right flex justify-end '>

                    <button
                        onClick={() => setIsScreeningOpen(!isScreeningOpen)}
                        className="flex items-center cursor-pointer justify-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:text-black hover:bg-blue-400 focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2"
                    >
                        <label className='cursor-pointer'> AMH </label>

                    </button>


                    <button
                        onClick={() => navigate('/coupledetails', { state: { id: coupleid } })}
                        className="flex items-center cursor-pointer justify-center text-gray-500 bg-white border border-gray-300 focus:outline-nonehover:text-black hover:bg-yellow-400 focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2"
                    >
                        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.15716 0.607836C5.26072 0.711393 5.3125 0.834005 5.3125 0.975672C5.3125 1.11734 5.26072 1.23981 5.15716 1.34309L2.12293 4.37732L5.16752 7.42191C5.26417 7.51856 5.3125 7.63938 5.3125 7.78436C5.3125 7.92934 5.26072 8.05361 5.15716 8.15716C5.05361 8.26072 4.93099 8.3125 4.78933 8.3125C4.64766 8.3125 4.52519 8.26072 4.42191 8.15716L0.942381 4.66728C0.900958 4.62586 0.871548 4.58098 0.85415 4.53266C0.836753 4.48433 0.828192 4.43255 0.828468 4.37732C0.828468 4.32209 0.837167 4.27031 0.854564 4.22199C0.871962 4.17366 0.901234 4.12878 0.942381 4.08736L4.43226 0.59748C4.52892 0.500827 4.64808 0.4525 4.78974 0.4525C4.93141 0.4525 5.05388 0.504279 5.15716 0.607836Z" fill="#52575C" />
                        </svg>
                        <label className='pl-2 cursor-pointer'> Back to ART</label>

                    </button>

                    {doctorView &&
                        //  (doctorView?.ScanDetails.ScanDetailList.some(x => x.scanresultid > 0) || doctorView?.BloodTestDetails.some(x => x.bloodtestid > 0)) &&
                        < ReactToPrint
                            trigger={() => (
                                <button id="printButton" className="bg-white flex  justify-center items-center  hover:bg-green-600 text-green-600 font-medium hover:text-white py-1 px-2 border-[1px] border-gray-300 hover:border-transparent rounded"

                                ><svg width="19" height="14" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 5.33333V12C19 12.1768 18.9285 12.3464 18.8013 12.4714C18.674 12.5964 18.5014 12.6667 18.3214 12.6667H15.6071V15.3333C15.6071 15.5101 15.5357 15.6797 15.4084 15.8047C15.2811 15.9298 15.1085 16 14.9286 16H4.07143C3.89146 16 3.71886 15.9298 3.59161 15.8047C3.46435 15.6797 3.39286 15.5101 3.39286 15.3333V12.6667H0.678571C0.498603 12.6667 0.326006 12.5964 0.198749 12.4714C0.0714921 12.3464 0 12.1768 0 12V5.33333C0 4.23083 0.963572 3.33333 2.14853 3.33333H3.39286V0.666667C3.39286 0.489856 3.46435 0.320287 3.59161 0.195262C3.71886 0.070238 3.89146 0 4.07143 0H14.9286C15.1085 0 15.2811 0.070238 15.4084 0.195262C15.5357 0.320287 15.6071 0.489856 15.6071 0.666667V3.33333H16.8515C18.0364 3.33333 19 4.23083 19 5.33333ZM4.75 3.33333H14.25V1.33333H4.75V3.33333ZM14.25 10.6667H4.75V14.6667H14.25V10.6667ZM15.6071 7C15.6071 6.80222 15.5474 6.60888 15.4356 6.44443C15.3238 6.27998 15.1648 6.15181 14.9788 6.07612C14.7928 6.00043 14.5882 5.98063 14.3907 6.01921C14.1933 6.0578 14.0119 6.15304 13.8696 6.29289C13.7272 6.43275 13.6303 6.61093 13.591 6.80491C13.5517 6.99889 13.5719 7.19996 13.6489 7.38268C13.7259 7.56541 13.8564 7.72159 14.0238 7.83147C14.1912 7.94135 14.388 8 14.5893 8C14.8592 8 15.1181 7.89464 15.309 7.70711C15.4999 7.51957 15.6071 7.26522 15.6071 7Z" fill="currentColor" />
                                    </svg><label className='text-sm pl-1'> Print</label></button>
                            )}
                            pageStyle="@page {size: landscape }"
                            content={() => componentRef.current}
                            onBeforeGetContent={() => handlePrintTitle(true)}
                            onAfterPrint={() => handlePrintTitle(false)}
                        />
                    }
                </div>
            </div>
            <div className={' p-2  flex mb-2 justify-between items-center w-full mt-1 rounded'}>
                <div className={theme.bg_Light + ' p-2 w-full flex justify-between rounded  shadow-md'}>
                    <div className='w-3/6 mr-2'>
                        <div className='w-full mr-2'>
                            <label className={'mr-4 font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500'}>Cycle information</label>
                            <div className='w-full  mt-2 bg-white rounded p-2 shadow'>
                                <div className='w-full flex '>
                                    <div className='w-full mt-1 flex justify-between pr-3'>
                                        <label className='  text-sm font-medium'>Day 1 Date :</label>
                                        <label className='  text-sm underline pr-3'>{getCorrectDate(doctorView?.DoctorDetails?.DayOneOfCycle)}</label>
                                    </div>
                                    <div className='w-full mt-1 flex justify-between pr-3'>
                                        <label className='  text-sm font-medium'>Trigger Date :</label>
                                        <label className='  text-sm underline pr-3'>{getCorrectDate(doctorView?.DoctorDetails?.TriggerDate)}</label>
                                    </div>

                                </div>
                                <div className='w-full flex '>
                                    <div className='w-full mt-1 flex justify-between pr-3'>
                                        <label className='  text-sm font-medium'>EPU Date: :</label>
                                        <label className='  text-sm underline pr-3'>{getCorrectDate(doctorView?.DoctorDetails?.DateOfEPU)}</label>
                                    </div>
                                    <div className='w-full mt-1 flex justify-between pr-3'>
                                        <label className='  text-sm font-medium'>Transfer Date :</label>
                                        <label className='  text-sm underline pr-3'>{getCorrectDate(doctorView?.DoctorDetails?.TransferDate)}</label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='w-full mr-2'>
                            <label className={'font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500'}>Doctor Summary</label>
                            <div className='w-full  mt-2 bg-white rounded p-2 shadow'>
                                <div className='w-full  flex items-center justify-between '>
                                    <div className='w-1/2  flex items-center cursor-pointer '>
                                        <div className='w-full flex justify-between pr-3'>
                                            <label className='  text-sm font-medium'>Embryoscope :</label>
                                            <label className='  text-sm underline pr-3'>{doctorView?.DoctorDetails?.EmbryoScope}</label>
                                        </div>
                                    </div>
                                    <div className='w-1/2  flex items-center  '>
                                        <div className='w-full  flex justify-between pr-3'>
                                            <label className='  text-sm font-medium'>Consents given: :</label>
                                            <label className='  text-sm underline pr-3'></label>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full  flex items-center justify-between '>
                                    <div className='w-1/2  flex items-center  '>
                                        <div className='w-full flex justify-between pr-3'>
                                            <label className='  text-sm font-medium'>AMH :</label>
                                            <label className='  text-sm underline pr-3'></label>
                                        </div>
                                    </div>
                                    <div className='w-1/2  flex items-center  '>
                                        <div className='w-full  flex justify-between pr-3'>
                                            <label className='  text-sm font-medium'>Consent received:</label>
                                            <label className='  text-sm underline pr-3'></label>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full  flex items-center justify-between '>
                                    <div className='w-1/2  flex items-center  '>
                                        <div className='w-full flex justify-between pr-3'>
                                            <label className='  text-sm font-medium'>IVF / ICSI :</label>
                                            <label className='  text-sm underline pr-3'>{doctorView?.DoctorDetails?.IVFICSI} {doctorView?.DoctorDetails?.IVFICSIDetails ? <i className='rounded-full bg-gray-300 px-1.5 cursor-pointer' title={doctorView?.DoctorDetails?.IVFICSIDetails}>i</i> : ''} </label>
                                        </div>
                                    </div>
                                    <div className='w-1/2  flex items-center  '>
                                        <div className='w-full  flex justify-between pr-3'>
                                            <label className='  text-sm font-medium '>Fresh / Frozen :</label>
                                            <label className='  text-sm  underline pr-3'>{doctorView?.DoctorDetails?.FreshFrozen}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full  flex items-center justify-between '>
                                    <div className='w-1/2  flex items-center  '>
                                        <div className='w-full flex justify-between pr-3'>
                                            <label className='  text-sm font-medium'>Partner / Donor :</label>
                                            <label className='  text-sm underline pr-3'>{doctorView?.DoctorDetails?.DonorPartner}</label>
                                        </div>
                                    </div>
                                    <div className='w-1/2  flex items-center  '>
                                        <div className='w-full  flex justify-between pr-3'>
                                            <label className='  text-sm font-medium'>Blast / Day 3 :</label>
                                            <label className='  text-sm underline pr-3'>{doctorView?.DoctorDetails?.BlastocystDay3}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full  flex items-center justify-between '>
                                    <div className='w-1/2  flex items-center  '>
                                        <div className='w-full flex justify-between pr-3'>
                                            <label className='  text-sm font-medium'>Clotting Disorder Y/N :</label>
                                            <label className='  text-sm underline pr-3'></label>
                                        </div>
                                    </div>
                                    <div className='w-1/2  flex items-center  '>
                                        <div className='w-full  flex justify-between pr-3'>
                                            <label className='  text-sm font-medium'># Embryos in store :</label>
                                            <label className='  text-sm underline pr-3'>{doctorView?.DoctorDetails?.NoOfEmbryo}</label>
                                        </div>
                                    </div>
                                </div>
                                {/* <label className='  text-sm font-medium'>Booking form Comment</label>
                            <textarea className='text-xss text-left text-gray-700 font-medium w-full p-1 ' disabled value={treatmentPlanObj?.comments} /> */}
                            </div>

                        </div>
                    </div>
                    <div className='w-3/6 flex '>
                        <div className='w-full'>
                            <div className={' flex text-white text-left  rounded-t justify-between items-center'}>
                                {/* <label className={' mr-4 font-bold text- xl ' + (commentsShow ? (theme.txt_color + ' border-b-[3px] border-b-amber-500') : 'text-gray-400  border-b-[3px]  border-b-gray-400')} onClick={() => setCommentsShow(true)}>Treatment Plan Comments</label> */}
                                {/* <label className={theme.txt_color + ' mr-4 font-bold text- xl ' + (commentsShow ? 'text-gray-400 border-b-[3px] border-b-gray-400' : (theme.txt_color + ' text-white  border-b-[3px] border-b-amber-500'))} onClick={() => setCommentsShow(false)}>Booking form Comments</label> */}

                                <div className='flex'>
                                    <button type="button" className={(commentsShow ? (theme.txt_color + ' bg-white shadow ') : ' text-gray-500  text-sm   ') + " flex items-center justify-between p-1 font-medium text-gray-400  rounded-t-xl  hover:bg-white px-3 mr-1"} onClick={() => setCommentsShow(true)}>
                                        <span>Treatment Plan Comments</span>
                                    </button>
                                    <button type="button" className={(commentsShow ? ' text-gray-500 text-sm ' : (theme.txt_color + ' bg-white shadow ')) + " flex items-center justify-between p-1 font-medium rtl:text-right  rounded-t-xl  hover:bg-white px-3 "} onClick={() => setCommentsShow(false)}>
                                        <span>Booking Form Comments</span>
                                    </button>
                                </div>

                                {/* <label className='text-xss font-medium'>Previous Comments</label> */}
                                {commentsShow ?
                                    <label className='text-xss text-gray-600 font-medium'>Total Comments# {getUniqueObjectsByKey(getUniqueObjectsByKey(comments, 'treatmentPlanID'), 'comments').filter(x => x.comments.length > 0).length > 0 ? getUniqueObjectsByKey(getUniqueObjectsByKey(comments, 'treatmentPlanID'), 'comments').filter(x => x.comments.length > 0).length : "0"} </label>
                                    :
                                    <label className='text-xss text-gray-600 font-medium'>Total Comments# {getUniqueObjectsByKey(bfcomments?.BFDetails, 'comments')?.filter(x => x.comments.length > 0).length > 0 ? getUniqueObjectsByKey(bfcomments?.BFDetails, 'comments')?.filter(x => x.comments.length > 0).length : '0'}   {/* {countComment()} */}  </label>
                                }
                            </div>
                            {commentsShow ?
                                <div className={theme.thumb + ' ' + theme.track + ' overflow-y-scroll scrollbar scrollbar-thin w-full   bg-white rounded p-0.5 shadow h-[198px]'}>
                                    {comments &&
                                        getUniqueObjectsByKey(getUniqueObjectsByKey(comments, 'treatmentPlanID'), 'comments').filter(x => x.comments.length > 0).length > 0 ?
                                        getUniqueObjectsByKey(getUniqueObjectsByKey(comments, 'treatmentPlanID'), 'comments').filter(x => x.comments.length > 0).map((item, index) => (
                                            <div key={index} className={theme.hoverBg + ' cursor-pointer border-gray-200  p-2 mx-1 border border-gray-300 shadow  rounded shadow border mt-1 '}>
                                                <textarea
                                                    style={{
                                                        height: 'fit-content', // Let the textarea adjust its height based on content
                                                        minHeight: 'fit-content', // Minimum height to fit content
                                                        resize: 'none', // Prevent manual resizing by the user
                                                    }}
                                                    rows={item.comments && Math.max(item.comments.split('\n').length, +1)} className='text-xss cursor-pointer text-left  font-medium w-full p-1 disabled  scrollbar-thin' disabled value={item.comments} />
                                                <p className={' text-xss text-gray-800 font-medium  '}><b>TP</b> v {item.formVersionMajor + '.' + item.formVersionMinor} ~ by <i><b>{item.clinicianname}</b></i> {' ~ Added on: ' + getCorrectDate(item.addedon) + ' ' + formatTime(item.addedon)}</p>
                                            </div>
                                        ))
                                        : <p className={' text-xss text-red-600 font-medium text-center '}><i><b>~ No Comments ~</b></i></p>
                                    }

                                </div>
                                :
                                <div className={theme.thumb + ' ' + theme.track + ' overflow-y-scroll scrollbar scrollbar-thin w-full   bg-white rounded p-0.5 shadow h-[198px]'}>
                                    {bfcomments && getUniqueObjectsByKey(bfcomments?.BFDetails, 'comments').filter(x => x.comments.length > 0).length > 0 ? replaceEmptyObjectsWithNull(getUniqueObjectsByKey(bfcomments?.BFDetails, 'comments')?.filter(x => x.comments.length > 0)).map((item, index) => (
                                        <div key={index} className={theme.hoverBg + ' cursor-pointer border-gray-200  p-2 mx-1 border border-gray-300 shadow  rounded shadow border mt-1 '}>
                                            < textarea
                                                style={{
                                                    // overflowY: 'hidden', // Hide scrollbar
                                                    height: 'fit-content', // Let the textarea adjust its height based on content
                                                    minHeight: 'fit-content', // Minimum height to fit content
                                                    resize: 'none', // Prevent manual resizing by the user
                                                }}
                                                rows={item?.comments && Math.max(item?.comments?.split('\n').length + 1, 1)} className='text-xss cursor-pointer text-left  font-medium w-full p-1 disabled  scrollbar-thin' disabled value={item?.comments} />
                                            <p className={' text-xss text-gray-800 font-medium  '}><b>BF</b> v {item.BFVersion} ~ by <i><b>{item.clinicianname}</b></i> {' ~ Added on: ' + getCorrectDate(item.addedon) + ' ' + formatTime(item.addedon)}</p>
                                        </div>
                                    ))
                                        : <p className={' text-xss text-red-600 font-medium  text-center'}><i><b>~ No Comments ~</b></i></p>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex my-2  mt-2 p-2'>
                <div style={{ width: '39%', }} className='shadow  '>
                    <div className={' bg-[#5599ae] text-white text-left p-1 flex rounded-t '}>
                        <p className='text-sbase font-medium '>Medications </p>
                    </div>
                    <div className=' border-[1px]  border-gray-200  text-left  mb-2  '>

                        {doctorView?.ScanDetails?.MedicationDetails ?
                            <>
                                {handleMedication(doctorView?.ScanDetails.MedicationDetails.StimMedication, 'Stimulation')}
                                {handleMedication(doctorView?.ScanDetails.MedicationDetails.AdjuvantMedication, 'Adjuvant')}
                                {handleMedication(doctorView?.ScanDetails.MedicationDetails.TriggerMedication, 'Trigger')}
                                {handleMedication(doctorView?.ScanDetails.MedicationDetails.LutealMedication, 'Luteal')}
                                {handleMedication(doctorView?.ScanDetails.MedicationDetails.ProcedureMedication, 'Procedure')}
                            </>
                            :
                            <p className='px-2 pt-1 pb-4 text-sm text-gray-400 text-center font-bold mt-5'>~No medications found~</p>
                        }

                    </div>

                    <div className={'bg-[#5599ae] text-white text-left p-1 flex justify-between rounded-t  px-2'}>
                        <p className='text-sbase font-medium w-1/6'>LMP </p>
                        <p className='text-sbase font-medium w-2/6'>Date </p>
                        <p className='text-sbase font-medium text-center w-2/6'>E2 pmol/L</p>
                        <p className='text-sbase font-medium text-center w-2/6'>P4 nmol/L</p>
                        <p className='text-sbase font-medium text-center w-2/6'>LH IU/L</p>
                        <p className='text-sbase font-medium text-center w-2/6'>FSH IU/L</p>
                        <p className='text-sbase text-center font-medium w-2/6 '>HCG IU/L</p>
                    </div>

                    {doctorView?.BloodTestDetails[0].bloodtestid > 0 ?
                        doctorView?.BloodTestDetails.map((item, index) => (
                            <div key={index} className={theme.tableBg + ' shadow rounded border  text-left p-2 flex justify-between rounded-t mt-2'}>
                                <p className='text-sm  w-1/6 '>{item.testdayofcycle} </p>
                                <p className='text-sm w-2/6'>{getCorrectDate(item.testdate) ? getCorrectDate(item.testdate) : ''}</p>
                                <p className='text-sm  text-center w-2/6'>{item.e2_dose}</p>
                                <p className='text-sm  text-center w-2/6'>{item.p4_dose}</p>
                                <p className='text-sm text-center w-2/6'>{item.lh_dose}</p>
                                <p className='text-sm text-center w-2/6'>{item.fsh_dose}</p>
                                <p className='text-sm  text-center w-2/6'>{item.hcg_dose}</p>
                            </div>
                        ))
                        :
                        <p className='px-2 py-1 text-sm text-gray-400 text-center font-bold mt-5'>~No previous blood test found~</p>}
                </div>

                {/* ======================= */}
                <div style={{ width: '70%', height: '750px' }} className='ml-1 shadow'>
                    <div className={'bg-[#5599ae] flex text-white text-left p-1  rounded-t'}>
                        <p className='text-sbase font-medium w-52'>Scan Results</p>
                    </div>
                    <div style={{ height: '95%' }} className={'flex h-full justify-between '}>

                        <div className={'w-full relative  '}>
                            {doctorView?.ScanDetails?.ScanDetailList[0]?.scanresultid > 0 ?
                                <div style={{ width: '100%', height: '100%' }} className={' flex overflow-x-auto absolute bg-white   rounded-b rounded-r scrollbar scrollbar-thin  ' + theme.thumb + ' ' + theme.track}>
                                    {doctorView?.ScanDetails.ScanDetailList.map((item, index) => (
                                        // item.result_l || item.result_r ?
                                        <div key={index} className={theme.panelBg + " rounded  px-2 mr-0.5 shadow border"}>
                                            {/* =================== */}
                                            <label className={"p-0  text-sm  font-bold text-center"} >Day : {item.scandayofcycle ? item.scandayofcycle : <span className='text-sm text-red-400 font-medium '>--</span>} </label>
                                            <p className={" text-sm  font-bold "} >Endometrial Thicknes :  <span className='pl-3 text-sm text-gray-400 font-medium '>{item?.result_endo}</span></p>
                                            <p className={" text-sm  font-bold "} >Uterus :  <span className='pl-3 text-sm text-gray-400 font-medium '>{item?.result_uterus}</span></p>
                                            {/* <p className={" text-sm  font-bold "} >Performed By :  <span className='pl-3 text-sm text-gray-400 font-medium '>{item?.clinicianname}</span></p> */}
                                            {/* <p className={" text-sm  font-bold mb-1 truncate-ellipsis w-48  whitespace-nowrap  text-ellipsis overflow-hidden "} >Notes/Comments :  <span className='pl-3 text-sm text-gray-400 font-medium  ' title={item?.comments}>{item?.comments}</span></p> */}
                                            <p className={" text-sm  font-bold "} >Clinic :<span className='pl-1 text-sm text-gray-400 font-medium '>{item?.ClinicName}</span></p>
                                            <p className={" text-sm  font-bold "} >Procedure Location :<span className='pl-1 text-sm text-gray-400 font-medium '>{item?.HospitalName}</span></p>

                                            <p className='text-sm  font-bold underline'>Scan Result</p>
                                            <div className={'flex Items-center '}>
                                                <label className={" text-sm  w-6 "} >Size</label>
                                                <label className={" text-sm ml-3 w-16  "} >L</label>
                                                <label className={" text-sm ml-1"} >R</label>
                                            </div>
                                            <div className='flex'>

                                                {/* L */}
                                                <div className={' flex flex-col items-center '}>
                                                    {Array.from({ length: 16 }).map((_, i) => (
                                                        <div key={i} className='flex items-center m-1'>
                                                            <label className='text-sm pr-2 w-6 flex flex-col items-center'> {(24 - i) === 24 ? ('>23') : (24 - i) === 9 ? ('<10') : (24 - i).toString()}</label>
                                                            <input
                                                                type='text'
                                                                disabled
                                                                // id={`l_${23 - i}`}
                                                                min={0} // Set minimum value to 11
                                                                max={24} // Set maximum value to 23
                                                                value={generateScanInput(item.result_l).split(',').length == 16 ? generateScanInput(item.result_l)?.split(',')[i] : ''}
                                                                className={'w-24 shadow overflow-hidden border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>

                                                {/* R */}
                                                <div className='flex flex-col items-center'>
                                                    {Array.from({ length: 16 }).map((_, i) => (
                                                        <div key={i} className='flex items-center  m-1'>
                                                            <input
                                                                disabled
                                                                type='text'
                                                                // id={`r_${23 - i}`}
                                                                min={0} // Set minimum value to 11
                                                                max={24} // Set maximum value to 23
                                                                value={generateScanInput(item.result_r).split(',').length == 16 ? generateScanInput(item.result_r)?.split(',')[i] : ''}
                                                                className={'w-24 shadow border overflow-hidden rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className='relative text-left '>
                                                <div style={{ width: '98%' }} className={' text-left  px-1 pt-2 scrollbar scrollbar-thin overflow-x-auto absolute ' + theme.thumb + ' ' + theme.track}>
                                                    <div className='flex'>
                                                        <label className={" text-sm   font-bold mr-1"} >L : </label>
                                                        <label style={{ width: '90%' }} className={" text-sm   "} >{item.result_l && item?.result_l?.trim()?.split(',')?.reverse().filter(Boolean).join(',')}</label>
                                                    </div>
                                                    <div className='flex'>
                                                        <label className={" text-sm   font-bold mr-1"} >R : </label>
                                                        <label style={{ width: '90%' }} className={" text-sm   "} >{item.result_r && item?.result_r?.trim()?.split(',')?.reverse().filter(Boolean).join(',')}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <p className={" text-sm  font-bold "} >Performed By :  <span className='pl-3 text-sm text-gray-400 font-medium '>{item?.clinicianname}</span></p> */}
                                            {/* <p className={" text-sm  font-bold mb-1 truncate-ellipsis w-48  whitespace-nowrap  text-ellipsis overflow-hidden "} >Notes/Comments :  <span className='pl-3 text-sm text-gray-400 font-medium  ' title={item?.comments}>{item?.comments}</span></p> */}

                                            <p onClick={() => CommentPopup(item?.comments, index)}
                                                className={" text-sm pt-3 mt-10 font-bold truncate-ellipsis w-56 pb-0.5  whitespace-nowrap  text-ellipsis overflow-hidden cursor-pointer hover:shadow"} >Notes/Comments :  <span className='pl-3 text-sm text-gray-400 font-medium  ' title={item?.comments}>{item?.comments}</span></p>
                                            <p className={' text-sm text-gray-800 font-medium  '}>  by <i><b> {item.clinicianname}</b></i> ~ On :  <i><b>{getCorrectDate(item.scandate)}</b></i></p>
                                            {commetPop && commetPop?.index >= 0 &&
                                                // {commetPop !== '' &&
                                                <div key={commetPop.index} style={{ backdropFilter: 'blur(7px)' }} className=" inset-0 transition-opacity absolute inset-0 z-10 overflow-y-auto delay-500 transition duration-1000 ease-in-out">
                                                    <div className="absolute   inset-0 w-full h-full bg-black opacity-60 backdrop-blur-sm "
                                                        onClick={() => setcommetPop()}
                                                    ></div>
                                                    <div className="flex  h-4/6 px-4 pt-16 pt-6 delay-900 duration-700">
                                                        <div className="relative w-2/6 h-auto p-4 mx-auto bg-gray-100 rounded-md shadow-lg">
                                                            <label className={'mr-4 font-bold text- xl  ' + theme.txt_color + ' border-b-[3px] border-b-amber-500'}> Notes/Comments</label>
                                                            <div className='  rounded flex  border mt-2'>
                                                                <textarea id="message" rows="8" disabled value={commetPop.comments} name="comments" className=" w-full text-sm px-1 py-0.5 border rounded accent-green-700  text-gray-700 focus:border-teal-500 focus:outline-none" placeholder="No comments ..." />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        // : <p className='px-2 py-1 text-sm text-gray-400 text-center font-bold mt-5'>~No previous scans found~</p>
                                    ))}
                                </div>
                                :
                                <p className='px-2 py-1 text-sm text-gray-400 text-center font-bold mt-5'>~No previous scans found~</p>}
                        </div>
                    </div>
                </div>
            </div>

            <ScreeningTestsList isScreeningOpen={isScreeningOpen} setIsScreeningOpen={setIsScreeningOpen} coupleid={coupleid} />
            <div className='hidden'>
                <div ref={componentRef} >
                    <DocterSheetPrint theme={theme} doctorView={doctorView} artid={artid} coupleDetails={coupleDetails} bfcomments={bfcomments} comments={comments} />
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_GET, ApiCall_POST }

export default connect(mapStateToProps, mapDispatchToProps)(DoctorSheet)

