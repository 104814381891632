import React, { useEffect, useState } from "react";
import { ApiCall_POST, ApiCall_GET } from "../../../Redux/Generic/action";
import { connect } from "react-redux";
import {
  ActionTypes,
  formatTime,
  formatTimeDifference,
  getCorrectDate,
  getCycleTypeColor,
  getEnumValueById,
  getTextColor,
  replaceEmptyObjectsWithNull,
} from "../../../common";
import { useAppContext } from "../../../AppContextProvider ";
import TreatmentPlanSummary from "../Nurse/NurseMagnet/StimPlan/TreatmentPlanSummary";
import TreatmentPlan from "../Magnet/TreatmentPlan/TreatmentPlan";
import Scans from "../Magnet/Scans/Scans";
import BookingForm from "../Magnet/BookingForm/BookingForm";
import BloodResult from "../Magnet/BloodResults/BloodResult";
import Acknowledgement from "../../Components/Acknowledgement/Acknowledgement";

function ActionDailog({
  nursingActionItem,
  setOpenNursingActionModal,
  coupleid,
  openNursingActionModal,
  nurseActiveStep,
}) {
  const theme = useAppContext().themeDetails;
  const [actionTypes, setActionTypes] = useState([]);
  const [showActions, setshowActions] = useState(true);

  useEffect(() => {}, []);

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto w-full ">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          onClick={() => setOpenNursingActionModal(0)}
          style={{ backdropFilter: "blur(7px)" }}
          className="fixed inset-0 transition-opacity filter"
          aria-hidden="true"
        >
          <div className="absolute inset-0 bg-gray-700 opacity-70  "></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl "
          aria-hidden="true"
        ></span>
        {/* <Draggable> */}
        <div
          className={`sm:my-8  w-4/5 filter inline-block align-bottom bg-yellow-300 sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative  `}>
            <svg
              className={
                " absolute top-2 right-2 cursor-pointer shadow  font-semibold  "
              }
              onClick={() => setOpenNursingActionModal(0)}
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="hover:shadow "
                d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z"
                fill="#ed4545"
              />
            </svg>

            <div>
              {(() => {
                switch (nursingActionItem.actionTypeId) {
                  case ActionTypes.TREATMENT_PLAN:
                    return (
                      <TreatmentPlan
                        nursingActionItem={nursingActionItem}
                        artid={nursingActionItem.artid}
                        coupleid={nursingActionItem.coupleId}
                        callFromAction={true}
                        setOpenNursingActionModal={setOpenNursingActionModal}
                      />
                    );
                  case ActionTypes.ADMIN_ACTION:
                    return (
                      <Acknowledgement
                        nursingActionItem={nursingActionItem}
                        setOpenNursingActionModal={setOpenNursingActionModal}
                        fromActionDailog={true}
                      />
                    );
                  case ActionTypes.LAB_ACTION:
                    return (
                      <Acknowledgement
                        nursingActionItem={nursingActionItem}
                        setOpenNursingActionModal={setOpenNursingActionModal}
                        fromActionDailog={true}
                      />
                    );
                  case ActionTypes.BOOKING_FORM:
                    // return <p>Details for Booking Form</p>;
                    return (
                      <BookingForm
                        nursingActionItem={nursingActionItem}
                        artid={nursingActionItem.artid}
                        coupleid={nursingActionItem.coupleId}
                        callFromAction={true}
                        setOpenNursingActionModal={setOpenNursingActionModal}
                      />
                    );
                  case ActionTypes.SCAN_RESULT:
                    // return <p>Details for Scan Result</p>;
                    return (
                      <Scans
                        nursingActionItem={nursingActionItem}
                        artid={nursingActionItem.artid}
                        coupleid={coupleid}
                        callFromAction={"InCycle"}
                        setOpenNursingActionModal={setOpenNursingActionModal}
                      />
                    );
                  case ActionTypes.BLOOD_WORKS:
                    // return <p>Details for Blood Works</p>;
                    return (
                      <BloodResult
                        nursingActionItem={nursingActionItem}
                        artid={nursingActionItem.artid}
                        coupleid={nursingActionItem.coupleId}
                        callFromAction={true}
                        setOpenNursingActionModal={setOpenNursingActionModal}
                      />
                    );
                  case ActionTypes.CLINICIAN_REQUESTED_CANCEL_CYCLE:
                    return (
                      <Acknowledgement
                        nursingActionItem={nursingActionItem}
                        setOpenNursingActionModal={setOpenNursingActionModal}
                        fromActionDailog={true}
                      />
                    );
                  case ActionTypes.PREGNANCY_SCAN_RESULT:
                    // return <p>Details for Pregnancy Scan Result</p>;
                    return (
                      <Scans
                        nursingActionItem={nursingActionItem}
                        artid={nursingActionItem.artid}
                        coupleid={nursingActionItem.coupleId}
                        callFromAction={"Pregnancy"}
                        setOpenNursingActionModal={setOpenNursingActionModal}
                      />
                    );
                  case ActionTypes.PREGNANCY_SCAN:
                    // return <p>Details for Pregnancy Scan</p>;
                    return (
                      <Scans
                        nursingActionItem={nursingActionItem}
                        artid={nursingActionItem.artid}
                        coupleid={nursingActionItem.coupleId}
                        callFromAction={"Pregnancy"}
                        setOpenNursingActionModal={setOpenNursingActionModal}
                      />
                    );
                  case ActionTypes.TREATMENT_PLAN_FINAL:
                    // return <p>Details for Final Treatment Plan</p>;
                    return (
                      <TreatmentPlan
                        artid={nursingActionItem.artid}
                        coupleid={nursingActionItem.coupleId}
                        callFromAction={true}
                        setOpenNursingActionModal={setOpenNursingActionModal}
                        nursingActionItem={nursingActionItem}
                      />
                    );
                  default:
                    return (
                      <Acknowledgement
                        nursingActionItem={nursingActionItem}
                        setOpenNursingActionModal={setOpenNursingActionModal}
                        fromActionDailog={true}
                      />
                    );
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ActionDailog);
