import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useAppContext } from "../../../AppContextProvider ";
import {
  capitalizeName,
  formatTime,
  formatTimeDifference,
  getCorrectDate,
  getCycleTypeColor,
  getEnumValueById,
  getSubstringByLength,
  getTextColor,
  getUserInformation,
  priority,
  replaceEmptyObjectsWithNull,
} from "../../../common";
import { ApiCall_GET, ApiCall_POST } from "../../../Redux/Generic/action";
import AddNurseAction from "./AddAction";
import ActionDailog from "./ActionDailog";
import AddIcon from "@mui/icons-material/Add";
import { svgHandler } from "../../../Utils";
import Swal from "sweetalert2";

export const ActionsItem = ({
  actionsInProgress,
  coupleid,
}) => {
  const [openNursingActionModal, setOpenNursingActionModal] = useState(0);
  const [sendItem, setSendItem] = useState("");
  const theme = useAppContext().themeDetails;
  const user = getUserInformation();

  useEffect(() => { }, []);
  const handelAction = (x) => {
    const data = x.actionTypeDept === "*" ? "admin" : x.actionTypeDept;
    let userType = user.userdepartment === "*" ? "admin" : user.userdepartment;
    if (
      (data === "nursing" && userType === "admin") ||
      (data === "admin" && userType === "nursing") ||
      (data === "all" && userType === "admin")
    ) {
      setOpenNursingActionModal(1);
      setSendItem(x);
    } else if (data === userType) {
      setOpenNursingActionModal(1);
      setSendItem(x);
    } else {
      swalAlert();
    }
    //  setOpenNursingActionModal(1);
    //   setSendItem(x);
  };

  const swalAlert = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton:
          "border p-2 m-1 border-red-200 rounded-md hover:bg-red-200 hover:text-red-900 text-red-500",
        cancelButton:
          "border p-2 m-1 border-green-200 rounded-md hover:bg-green-200 hover:text-green-900 text-green-500",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons.fire({
      text: "You do not have permission to acknowledge this action.",
      icon: "warning",
      showCancelButton: false,
      confirmButtonText: "Ok",
      reverseButtons: true,
    });
  };

  return (
    <div className="w-full  h-[98%]">
      {/* <div className={`${theme.thumb} ${theme.track}   w-full  scrollbar overflow-y-auto h-full`}      > */}
      <div className={`${theme.thumb} ${theme.track} w-full bg-white  rounded-md shadow  overflow-y-auto scroll scrollbar-thin h-full pb-12`}        >
        <div className={theme.navbar + " p-2 sticky top-0 text-white text-left shadow rounded-t"}          >
          <p className=" text-sbase font-medium ">Actions for Progress</p>
        </div>
        {actionsInProgress.length > 0 ?
          actionsInProgress.map((item) => (
            // <div onClick={() => { setOpenNursingActionModal(1); setNursingActionItem(item) }} style={{ background: priority(item.priority)?.color }}
            <div onClick={() => { handelAction(item) }}
              style={{ background: priority(item.priority)?.color }}
              className={item.itemtype + `  ${theme.hoverBg}  cursor-pointer w-full  mt-1 items-center rounded  border  m-auto  bg-[${priority(item.priority)?.color
                }] border-[${priority(item.priority)?.iconcolor}] `}>
              <div className=" p-2 rounded shadow hover:shadow-lg cursor-pointer w-full">
                <div className={`items-center px-2  flex  gap-x-3 `}>
                  <svg
                    className="w-7 "
                    width="19"
                    height="20"
                    viewBox="0 0 31 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.9631 18.3515L20.6398 3.1386C19.416 1.14243 17.5441 0 15.5003 0C13.4565 0 11.5846 1.14243 10.3608 3.13551L1.03753 18.3515C-0.202001 20.3723 -0.337624 22.5275 0.666934 24.2643C1.66991 26.0027 3.62857 27 6.03824 27H24.9624C27.3721 27 29.3307 26.0027 30.3337 24.2659C31.3367 22.5291 31.2026 20.3739 29.9631 18.3515ZM15.5003 21.6723C14.1536 21.6723 13.056 20.5993 13.056 19.2809C13.056 17.9609 14.152 16.8864 15.5003 16.8864C16.8487 16.8864 17.9447 17.9609 17.9447 19.2809C17.9447 20.5993 16.8471 21.6723 15.5003 21.6723ZM18.0756 10.2109C18.0582 10.2587 15.8662 15.5649 15.8662 15.5649C15.8063 15.71 15.6612 15.8057 15.5019 15.8057C15.3426 15.8057 15.1975 15.71 15.1376 15.5649L12.944 10.2572C12.8124 9.93549 12.7434 9.59252 12.7406 9.24598C12.7406 7.75619 13.9785 6.54429 15.5003 6.54429C15.9473 6.54541 16.3873 6.65266 16.7826 6.85685C17.1779 7.06103 17.5167 7.35606 17.7699 7.7166C18.0232 8.07715 18.1833 8.49246 18.2365 8.92689C18.2897 9.36133 18.2345 9.80192 18.0756 10.2109Z"
                      fill={priority(item.priority)?.iconcolor}
                    />
                  </svg>

                  <div className=" w-[95%] pl-1">
                    <p className="text-sm text-gray-700 font-semibold ">
                      <span
                        className={`${item.itemtype === "action" ? "text-red-700" : " "
                          }  `}
                      >
                        {capitalizeName(item.actionTypeDept)}{" ~ "}
                      </span>
                      <span className="text-gray-700 font-semibold">
                        {" "}
                        {item.actionTypeName}{" "}
                      </span>
                      <label className="text-xss">
                        ~ requested by{" "}
                        {item.AddedByName ? (
                          <i>
                            <span className="text-gray-700 font-bold">
                              {item.AddedByName}
                            </span>
                          </i>
                        ) : (
                          " "
                        )}
                        on ~{" "}
                        {item.addedDate && (
                          <>
                            <i><span className="text-gray-700 font-bold">{getCorrectDate(item.addedDate)}{" "}</span></i>
                            at{" "}
                            <i><span className="text-gray-700 font-bold">{formatTime(item.addedDate, true)}</span></i>{" "}
                          </>
                        )}{" "}
                        {item.addedDate
                          ? " ( " + formatTimeDifference(item.addedDate) + " ) "
                          : ""}
                      </label>
                    </p>
                    <p className="text-xss  font-medium">
                      {/* {item.actionTypeName}: */}
                      {item.actionDesc}
                      {/* - for{" "}                      {item.PatientSurname}, {item.PartnerFirstname}11 */}
                    </p>
                  </div>
                </div>
                {item.actionTypeName !== "General Action" ? (
                  <div className={" pt-2 w-full px-2 flex items-center gap-x-4 rounded-md shadow" + theme.hoverBg}>
                    {item?.actionActivityName == "Treatment Plan" ? (
                      <div className=" text-sbase  text-gray-700 flex items-center">
                        {getEnumValueById(item?.ProcedureTypeId) === "---" ? (
                          ""
                        ) : (
                          <div
                            className={`flex flex-col h-7 w-7 shadow rounded items-center justify-center  `}
                            style={{ backgroundColor: getCycleTypeColor(getEnumValueById(item?.ProcedureTypeId)?.split(" ")[0]?.trim()) || "gray", }}>
                            <label
                              className={` text-xss text-center font-semibold  w-6 overflow-hidden  text-${getTextColor(getCycleTypeColor(item?.ProcedureTypeId))}`}                            >
                              {getEnumValueById(item?.ProcedureTypeId)}
                            </label>
                            <div className="rounded bg-white flex   items-center justify-center h-3 w-5 my-0.5">
                              {item?.version && (
                                <label className={`  text-smm font-bold   text-gray-600`}>
                                  v{item?.version}{" "}
                                </label>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    <div className={"px-2 "}>
                      {item.PatientSurname && <p className="text-xss text-gray-700 font-semibold ">
                        Patient: {item.PatientFirstname}, {item.PatientSurname}
                      </p>}
                      {item.PatientDOB && <p className="text-xss text-gray-600  font-medium">
                        DOB : {getCorrectDate(item.PatientDOB)}
                      </p>}
                    </div>
                    <div className={"px-2 "}>
                      {item.PartnerFirstname && <p className="text-xss text-gray-700 font-semibold ">
                        Partner: {item.PartnerFirstname},  {item.PartnerSurname}
                      </p>}
                      {item.PartnerDOB && <p className="text-xss text-gray-600 font-medium">
                        DOB : {getCorrectDate(item.PartnerDOB)}
                      </p>}
                    </div>
                    <div className={"px-2 "}>
                      <p className="text-xss  font-semibold ">
                        {item.couplecode}
                      </p>
                      <p className="text-xss text-gray-600 font-medium">
                        {item?.clinicianName}
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))
          :
          <div className="flex justify-center w-[95%] self-center ml-6 shadow p-2 mt-4 mb-2 mr-0 text-[#664d03] border rounded-lg bg-yellow-100  " role="alert">
            <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#664d03" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only">Info</span>
            <div className="ms-3 text-sm  font-medium text-[#664d03]">
              <i> ~ There Are Currently No Available Actions. ~</i>
            </div>
          </div>
        }
      </div>


      {
        openNursingActionModal === 1 && (
          <ActionDailog
            nursingActionItem={sendItem}
            setOpenNursingActionModal={setOpenNursingActionModal}
            coupleid={coupleid}
            openNursingActionModal={openNursingActionModal} />
        )
      }
    </div >
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsItem);
