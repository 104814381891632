import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../../../AppContextProvider ';
import { getSession } from '../../../../../../session';
import { ApiCall_GET } from '../../../../../../Redux/Generic/action'
import { getCorrectDate, handlePrintTitle, replaceEmptyObjectsWithNull } from '../../../../../../common';
import ReactToPrint from 'react-to-print';
import AndrologyRequestViewPrint from './AndrologyRequestViewPrint';

export const AndrologyRequestView = ({ setOpenForm, ApiCall_GET, andrologytypeid, andrologyid, ...otherProps }) => {
    const componentRef = useRef();
    const [couple, setCouple] = useState(null)
    const [andrology, setAndrology] = useState(null)

    function DBNbr(objectToEncode) {
        if (!isNaN(objectToEncode) && objectToEncode !== null && objectToEncode !== "") {
            return String(objectToEncode);
        }
        return "0";
    }

    useEffect(() => {
        setCouple(getSession('patient'))
        ApiCall_GET('Lab/GetAndrologyDetails/' + andrologyid + '/request', true).then(function (payload) {
            setAndrology(replaceEmptyObjectsWithNull(payload));
        });
    }, [])
    const theme = useAppContext().themeDetails;

    return (
        <div className="fixed z-50 inset-0 overflow-y-auto " >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div style={{ backdropFilter: 'blur(7px)' }} className="fixed inset-0 transition-opacity filter" aria-hidden="true" ><div className="absolute inset-0 bg-gray-700 opacity-70  " ></div></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl " aria-hidden="true" ></span>
                <div className={`sm:my-8   w-2/3  filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`} role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className={`bg-white px-4 py-5 sm:p-6 sm:pb-4 relative  `}>
                        <div className='flex items-center justify-between mb-1 '>
                            <h3 className={theme.txt_color + " text-xl  leading-6 font-bold text-gray-900"} id="modal-headline" >Andrology Request</h3>
                            <div className="flex gap-2">
                                <ReactToPrint
                                    trigger={() => (
                                        <button id="printButton" className="bg-white flex mr-0.5 justify-center items-center mr-4  hover:bg-green-600 text-green-600 font-medium hover:text-white py-0.5 px-2 border-[1px] border-gray-300 hover:border-transparent rounded">
                                            <svg width="19" height="14" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19 5.33333V12C19 12.1768 18.9285 12.3464 18.8013 12.4714C18.674 12.5964 18.5014 12.6667 18.3214 12.6667H15.6071V15.3333C15.6071 15.5101 15.5357 15.6797 15.4084 15.8047C15.2811 15.9298 15.1085 16 14.9286 16H4.07143C3.89146 16 3.71886 15.9298 3.59161 15.8047C3.46435 15.6797 3.39286 15.5101 3.39286 15.3333V12.6667H0.678571C0.498603 12.6667 0.326006 12.5964 0.198749 12.4714C0.0714921 12.3464 0 12.1768 0 12V5.33333C0 4.23083 0.963572 3.33333 2.14853 3.33333H3.39286V0.666667C3.39286 0.489856 3.46435 0.320287 3.59161 0.195262C3.71886 0.070238 3.89146 0 4.07143 0H14.9286C15.1085 0 15.2811 0.070238 15.4084 0.195262C15.5357 0.320287 15.6071 0.489856 15.6071 0.666667V3.33333H16.8515C18.0364 3.33333 19 4.23083 19 5.33333ZM4.75 3.33333H14.25V1.33333H4.75V3.33333ZM14.25 10.6667H4.75V14.6667H14.25V10.6667ZM15.6071 7C15.6071 6.80222 15.5474 6.60888 15.4356 6.44443C15.3238 6.27998 15.1648 6.15181 14.9788 6.07612C14.7928 6.00043 14.5882 5.98063 14.3907 6.01921C14.1933 6.0578 14.0119 6.15304 13.8696 6.29289C13.7272 6.43275 13.6303 6.61093 13.591 6.80491C13.5517 6.99889 13.5719 7.19996 13.6489 7.38268C13.7259 7.56541 13.8564 7.72159 14.0238 7.83147C14.1912 7.94135 14.388 8 14.5893 8C14.8592 8 15.1181 7.89464 15.309 7.70711C15.4999 7.51957 15.6071 7.26522 15.6071 7Z" fill="currentColor" />
                                            </svg><label className='text-sm pl-1'>Print</label>
                                        </button>
                                    )}
                                    pageStyle="@page { size: 210 x 297 mm  }"
                                    content={() => componentRef.current}
                                    onBeforeGetContent={() => handlePrintTitle(true)}
                                    onAfterPrint={() => handlePrintTitle(false)}
                                />
                                <svg className={'  top-2 right-2 cursor-pointer shadow  font-semibold  '}
                                    onClick={() => setOpenForm(false)}
                                    width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545" />
                                </svg>
                            </div>
                        </div>

                        <div className='flex gap-x-2  mt-3 mb-2 '>
                            <div className='w-8/12  shadow'>
                                <p className={theme.tHedUpdte + '  text-sm font-medium text-white text-left p-1  rounded-t'}>Patient / Couple Details</p>
                                <div className='flex w-full text-left px-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                                    <p className='cursor-pointer text-sbase font-medium   text-gray-700'>{'Patient'} </p>
                                    <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                        <p className={'text-sm text-gray-500 flex items-center gap-1 '}>
                                            {couple?.patientFullName}
                                            {couple?.patientGender === 'F' ?
                                                <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="#FE9696" />
                                                </svg>
                                                :
                                                <svg width="15" height="13" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="#A9C6FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            }
                                        </p>
                                        <p className={'text-sm text-gray-500  '}><b>DOB: </b>{couple?.patientDOB}   <b>({couple?.patientResAge.split(',')[0]}) </b></p>
                                    </div>

                                </div>
                                <div className='flex w-full text-left  px-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                                    <p className='cursor-pointer text-sbase font-medium   text-gray-700'>{'Partner'} </p>
                                    <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                        <p className={'text-sm text-gray-500 flex items-center gap-1 '}>
                                            {couple?.partnerFullName}
                                            {couple?.partnerGender === 'F' ?
                                                <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="#FE9696" />
                                                </svg>
                                                :
                                                <svg width="15" height="13" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="#A9C6FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            }
                                        </p>
                                        <p className={'text-sm text-gray-500  '}>
                                            <b>DOB: </b>{couple?.partnerDOB}   <b>({couple?.partnerAge.split(',')[0]}) </b></p>
                                    </div>
                                </div>

                                <div className='flex w-full'>
                                    <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-full  '>
                                        <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Clinician</p>
                                        <div className='flex pl-2 flex-wrap justify-between items-center w-full'><p className={'text-sm text-gray-500  '}>{couple?.clinicianName}</p></div>
                                    </div>
                                </div>

                                <div className='flex w-full text-left  px-2 flex  py-2  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                    <p className=' text-sm font-medium   text-gray-700'>Clinic</p>
                                    <div className=' pl-2  items-center w-full'>
                                        <p className={'text-sm text-gray-500  '}>{andrology?.locationname}</p>
                                        <p className={'text-sm text-gray-500  '}>
                                            ~ {andrology?.add1} {andrology?.add2}  {andrology?.add3} {andrology?.add4} {andrology?.city} {andrology?.state} {andrology?.postcode}
                                        </p>
                                    </div>
                                    <div className='   items-center text-right w-full'>
                                        <p className={'text-sm text-gray-500  '}>
                                            <span className=' text-sm font-medium   text-gray-700 pr-3'>Phone:</span>
                                            {andrology?.phone}
                                        </p>
                                        <p className={'text-sm text-gray-500  '}>
                                            <span className=' text-sm font-medium   text-gray-700 pr-3'>Fax:</span>
                                            {andrology?.fax}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='w-4/12  shadow '>
                                <div className='w-full'>
                                    <p className={theme.tHedUpdte + '  text-sm font-medium text-white text-left p-1  rounded-t'}>Couple Code</p>
                                    <div className='flex justify-center bg-yellow-100  text-center w-full py-1  border-[1px] text-xl border-gray-200'>{couple?.coupleCode}</div>
                                </div>
                                <div className='w-full mt-1'>
                                    <p className={theme.tHedUpdte + '  text-sm font-medium text-white text-left p-1  rounded-t'}>Request</p>
                                    {andrology?.nonurgentRequest ?
                                        <div className='flex justify-center bg-green-100 text-center w-full py-1 text-green-500 font-medium border-b-[1px] text-xl border-b-gray-200'>Non Urgent</div>
                                        :
                                        andrology?.urgentRequest ?
                                            <div className='flex justify-center bg-red-100 text-center w-full py-1  text-red-500 font-medium border-b-[1px] text-xl border-b-gray-200'>Urgent</div>
                                            : ''
                                    }

                                    {/* {andrology?.urgentRequest ? */}
                                    <div className='flex justify-center text-center w-full py-1  border-b-[1px] text-sm border-b-gray-200'>
                                        <span className='text-gray-700 mr-2'>Required by:</span> {getCorrectDate(andrology?.preferredBookingDate)}
                                    </div>
                                    {/* : ''} */}

                                </div>
                            </div>
                        </div>

                        <div className='flex gap-x-2 '>
                            <div className='w-1/2 '>
                                <div className='w-full shadow'>
                                    <p className={theme.tHedUpdte + ' text-sm font-medium text-white text-left p-1 rounded-t'}>
                                        Procedure Details
                                    </p>

                                    <div className='flex justify-center py-2 items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                        <p className='text-sm text-gray-700'>
                                            {andrology?.semenAnalysisOnly && <span className='block'>Semen Analysis Only</span>}
                                            {andrology?.semenAnalysisAntibodies && <span className='block'>Semen Analysis & Antibody test</span>}
                                            {andrology?.semenAnalysisDNAFrag && <span className='block'>Semen Analysis & DNA Fragmentation test</span>}
                                            {andrology?.semenAnalysisDNAFragRepeat && <span className='block'>• Repeat DNA Frag Test (within 4 weeks)</span>}
                                            {andrology?.semenAnalysisAntibodiesDNAFrag && <span className='block'>• Semen Analysis & Antibody Testing & DNA Fragmentation Test</span>}
                                            {andrology?.semenAnalysisARTSuitability && <span className='block'>Semen Analysis & Suitability for ART</span>}
                                            {andrology?.semenAnalysisARTSuitabilityAntibodies && <span className='block'>Semen Analysis & Suitability for ART<br />+ Antibody test</span>}
                                            {andrology?.semenAnalysisIUIPrep && <span className='block'>IUI Preparation</span>}
                                            {andrology?.donorPreparation && <span className='block'>IUI Donor Preparation</span>}
                                            {andrology?.spermFreeze && <span className='block'>Sperm Freeze</span>}
                                            {andrology?.spermFreezeSDA && andrology?.spermFreeze && <span className='block'>• SDA (Sperm Donor)</span>}
                                        </p>
                                    </div>
                                    {andrology?.numberOfFollicles ? (
                                        <div className='flex text-center justify-center items-center w-full px-2'>
                                            <p className='text-sm font-medium  text-gray-700'>No. of Follicles:</p>
                                            <p className='pl-2 text-sm text-gray-500'>{andrology?.numberOfFollicles}</p>
                                        </div>) : ''}


                                </div>
                                <div className='w-full mt-2 shadow'>
                                    <p className={theme.tHedUpdte + ' x text-sm font-medium text-white text-left p-1 rounded-t'}>
                                        Pathology Status
                                    </p>
                                    <div className='flex items-center w-full p-2'>
                                        <div className='flex justify-center items-center w-1/2'>
                                            <p className='text-xl font-medium  text-gray-700'>Patient:</p>
                                            <p className={'pl-2 text-xl ' + (andrology?.patientPathResult?.toLowerCase() == 'negative' ? ' text-green-500 ' : andrology?.patientPathResult?.toLowerCase() == 'negative' ? ' text-red-500 ' : 'text-gray-500')}>{andrology?.patientPathResult}</p>
                                        </div>
                                        <div className='flex justify-center items-center w-1/2 '>
                                            <p className='text-xl font-medium  text-gray-700'>Partner:</p>
                                            <p className={'pl-2 text-xl ' + (andrology?.partnerPathResult?.toLowerCase() == 'negative' ? ' text-green-500 ' : andrology?.partnerPathResult?.toLowerCase() == 'negative' ? ' text-red-500 ' : 'text-gray-500')}>{andrology?.partnerPathResult}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-1/2 '>
                                <p className={theme.tHedUpdte + ' text-sm font-medium text-white text-left p-1 rounded-t'}>
                                    Clinical History
                                </p>
                                <div className='w-full shadow mr-1 '>
                                    <p className={theme.panelBg + ' flex justify-center text-sm font-medium  text-left p-1 rounded-t'}>Duration of Infertility</p>
                                    <div className='flex justify-center py-2 items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                        <p className='text-sm text-gray-700'>{andrology?.durationOfInfertilityYears} (years)</p>
                                    </div>

                                    <p className={theme.panelBg + ' text-sm font-medium  text-left p-1 rounded-t'}>
                                        Aetiology
                                    </p>

                                    {andrology?.aetiologyMaternalAge ? (
                                        <div className='flex  py-2 items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                            <p className='text-sm font-medium text-gray-700'>Maternal Age:</p>
                                            <p className='pl-2 text-sm text-gray-500'>{andrology?.aetiologyMaternalAgeComment}</p>
                                        </div>
                                    ) : ''}
                                    {andrology?.aetiologyIdiopathic ? (
                                        <div className='flex  py-2 items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                            <p className='text-sm font-medium text-gray-700'>Idiopathic:</p>
                                            <p className='pl-2 text-sm text-gray-500'>{andrology?.aetiologyIdiopathicComment}</p>
                                        </div>
                                    ) : ''}
                                    {andrology?.aetiologyOvulationDisorder ? (
                                        <div className='flex  py-2 items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                            <p className='text-sm font-medium text-gray-700'>Ovulation Disorder:</p>
                                            <p className='pl-2 text-sm text-gray-500'>{andrology?.aetiologyOvulationDisorderComment}</p>
                                        </div>
                                    ) : ''}

                                    {andrology?.aetiologyPolycysticOvaries ? (
                                        <div className='flex  py-2 items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                            <p className='text-sm font-medium text-gray-700'>Polycystic Ovaries:</p>
                                            <p className='pl-2 text-sm text-gray-500'>{andrology?.aetiologyPolycysticOvariesComment}</p>
                                        </div>
                                    ) : ''}
                                    {andrology?.aetiologyTubal ? (
                                        <div className='flex  py-2 items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                            <p className='text-sm font-medium text-gray-700'>Tubal:</p>
                                            <p className='pl-2 text-sm text-gray-500'>{andrology?.aetiologyTubalComment}</p>
                                        </div>
                                    ) : ''}
                                    {andrology?.aetiologyEndometriosis ? (
                                        <div className='flex  py-2 items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                            <p className='text-sm font-medium text-gray-700'>Endometriosis:</p>
                                            <p className='pl-2 text-sm text-gray-500'>{andrology?.aetiologyEndometriosisComment}</p>
                                        </div>
                                    ) : ''}
                                    {andrology?.aetiologyMaleFactor ? (
                                        <div className='flex  py-2 items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                            <p className='text-sm font-medium text-gray-700'>Male Factor:</p>
                                            <p className='pl-2 text-sm text-gray-500'>{andrology?.aetiologyMaleFactorComment}</p>
                                        </div>
                                    ) : ''}

                                    {andrology?.aetiologySocial ? (
                                        <div className='flex  py-2 items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                            <p className='text-sm font-medium text-gray-700'>Social:</p>
                                            <p className='pl-2 text-sm text-gray-500'>{andrology?.aetiologySocialComment}</p>
                                        </div>
                                    ) : ''}

                                    {andrology?.aetiologyOther ? (
                                        <div className='flex  py-2 items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                            <p className='text-sm font-medium text-gray-700'>Other:</p>
                                            <p className='pl-2 text-sm text-gray-500'>{andrology?.aetiologyOtherComment}</p>
                                        </div>
                                    ) : ''}

                                    {andrology?.comments !== "" && (
                                        <>
                                            <p className={theme.panelBg + ' text-sm font-medium  text-left p-1 rounded-t'}>
                                                Comments
                                            </p>
                                            <div className='flex  py-2 items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                                                <p className='pl-2 text-sm text-gray-500' style={{ padding: '5px', fontSize: '10px' }}>{andrology?.comments}</p>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="w-full  shadow text-sm mr-1  my-2">
                            <p className={theme.tHedUpdte + '   font-medium text-white text-left p-1  rounded-t'}>Additional Comments</p>
                            <textarea
                                style={{ resize: 'none' }} disabled
                                rows={3}
                                className='text-xss text-left  font-medium w-full p-1 disabled  scrollbar-thin'
                                value={andrology?.txtComments}
                            />
                        </div>

                        <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                            <p className={`${theme.tHedUpdte} text-sm font-medium text-white text-left p-1 rounded-t`}>Information for the Patient</p>
                            <div className='flex flex-col w-full px-2 border-b-[1px] border-b-gray-200'>
                                <div className='py-1 w-full'>
                                    <p className='text-sm text-gray-900'>
                                        <b>PLEASE CONTACT CITY FERTILITY CENTRE TO ORGANISE AN APPOINTMENT</b>
                                        <br />(Monday to Friday, excluding public holidays)
                                        <br />
                                        <br />Facilities are available at the clinic to produce your semen sample. You may produce your semen sample at home if you prefer, provided the sample is received at our laboratory within <b>one hour</b> of production (an appointment is still required). It is preferable that you have not ejaculated for <b>at least two full days and not more than five days</b> prior to producing your semen sample. Please note that when producing your sample, you must not use any lubricants as these may affect the quality of the sample. The sample must also not come into contact with any water.
                                        <br />
                                        {andrology?.spermFreezeSDA || andrology?.spermFreeze ? (
                                            <span>Please Note: Multiple sperm freeze procedures may be required to obtain a viable frozen sample. </span>
                                        ) : (
                                            <span>Please Note: This request form is valid for up to five sperm freeze procedures to obtain valid donor sperm samples. </span>
                                        )}
                                        {andrology?.spermFreezeSDA || andrology?.spermFreeze ? (
                                            <span>
                                                <b>If you are having a PESA/TESA, freeze will only go ahead if signed consent forms and screening results have been returned to the clinic first.</b>
                                                <br />
                                            </span>
                                        ) : null}
                                        <br />Feel free to contact our scientists to discuss any issue relating to the production of your sample.
                                        <br />
                                        <br />PLEASE BRING THIS FORM TO THE APPOINTMENT with valid PHOTO I.D. (eg. Drivers Licence)
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='flex justify-between w-full border-t border-black px-2 mt-5'>
                            <div className='flex py-1 w-1/2 '>
                                <p className={' p-1 rounded-md text-sm font-medium text-gray-700'}><b>Signature : </b> Digitally Signed by <b>{andrology?.SigningClinicianName}</b> (authorized)</p>
                            </div>

                            <div className='flex py-1 w-1/6 '>
                                <p className='text-sm font-medium text-gray-700'>Date:</p>
                                <div className='pl-2 text-sm text-gray-500'> {getCorrectDate(andrology?.addedon)} </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='hidden'>
                    <div ref={componentRef} >
                        <AndrologyRequestViewPrint andrologytypeid={andrologytypeid} andrology={andrology} couple={couple} DBNbr={DBNbr} />
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_GET }

export default connect(mapStateToProps, mapDispatchToProps)(AndrologyRequestView)