//Variables


export const getActionTypeName = (actionTypeId) => {
  const actionTypeName = Object.keys(TPActionType).find(
    (key) => TPActionType[key] === actionTypeId
  );
  return actionTypeName || " ";
};

export const TPActionType = {
  GeneralAction: 1,
  TreatmentPlan: 2,
  AdminAction: 5,
  NursingAction: 6,
  LabAction: 7,
  ClinicianAction: 8,
  ClinicManagerAction: 11,
  PregnancyScanResult: 37,
}


export const generateTitle = (currentPath) => {
  switch (currentPath) {
    case '/':
      return 'CHAART - Home';
    case '/home':
      return 'CHAART - Home';
    case '/coupledetails':
      return 'Couple Details';
    case '/addreferenceitems':
      return 'Add Reference Items';
    case '/treatmentplan':
      return 'Treatment Plan';
    case '/updateTreatmentPlan':
      return 'Update Treatment Plan';
    case '/addArt':
      return 'Add Art';
    case '/magnet':
      return 'Magnet';
    case '/runningSheet':
      return 'Running Sheet';
    case '/scans':
      return 'Scans';
    case '/bloodResult':
      return 'Blood';
    case '/doctorSheet':
      return 'Doctor Sheet';
    case '/login':
      return 'Login';
    case '/permission':
      return 'Permission';
    case '/landing':
      return 'Landing';
    case '/Embrology':
      return 'embrology';
    case '/editCouple':
      return 'editCouple';
    case '/coupleInfo':
      return 'coupleInfo';
    case '/createCouple':
      return 'CreateCouple';
    case '/createPerson':
      return 'CreatePerson';
    case '/calendar':
      return 'Calendar';
    case '/whiteBoard':
      return 'whiteBoard';
    case '/pretreatmentWorkup':
      return 'pretreatmentWorkup';
    case '/patientPortal':
      return 'patientPortal';
    case "/screening":
      return 'screening';
    case "/generateProcedureBooking":
      return 'generateProcedureBooking';
    case "/generateTheatreBooking":
      return 'generateTheatreBooking';
    case "/electronicForm":
      return 'electronicForm';
    case "/patientWaitingRoom":
      return 'patientWaitingRoom';
    default:
      return 'CHAART';
  }
}