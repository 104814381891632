import React, { useEffect, useState } from "react";
import { ApiCall_POST, ApiCall_GET } from "../../../Redux/Generic/action";
import { connect } from "react-redux";
import { useAppContext } from "../../../AppContextProvider ";


export const DailyReport = ({ selectedPage, popUpDailyReport, setPopUpDailyReport }) => {
    const theme = useAppContext().themeDetails;

    const handleInputChange = (e) => {

        const { name, value, checked, type } = e.target;


    }
    return (
        <div className="fixed z-50 inset-0 overflow-y-auto ">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div onClick={() => setPopUpDailyReport(0)}
                    style={{ backdropFilter: "blur(7px)" }} className="fixed inset-0 transition-opacity filter" aria-hidden="true"                >
                    <div className="absolute inset-0 bg-gray-700 opacity-70  "></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl " aria-hidden="true"></span>
                {/* <Draggable> */}
                {/* <div className={`sm:my-8  w-full md:w-1/3 filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`} */}
                <div className={`sm:my-8   sm:max-w-lg sm:w-full filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`}

                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"                >
                    <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative  `}>
                        <svg className={" absolute top-2 right-2 cursor-pointer shadow  font-semibold  "}
                            onClick={() => setPopUpDailyReport(0)}
                            width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"                       >
                            <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z"
                                fill="#ed4545" />
                        </svg>

                        {/* < h3 className={theme.txt_color + " text-xl text-center mb-4 mt-2 leading-6 font-bold text-gray-900"} id="modal-headline">Daily{selectedPage == 1 ? ' Phone Call ' : ' Meeting '}Report</h3> */}
                        <h3 className={`${theme.txt_color} text-xl text-center mb-4 mt-2 leading-6 font-bold text-gray-900`}
                            id="modal-headline">
                            {selectedPage === 2
                                ? `${popUpDailyReport === 3 ? 'Patient Seen' : 'Daily Meeting Report'} `
                                : 'Daily Phone Call Report'}
                        </h3>
                        <div className=" my-2 w-full border rounded-md shadow items-center bg-gray-100 p-4  rounded-lg"
                            onMouseDown={(e) => e.stopPropagation()}>
                            {popUpDailyReport === 3 ?


                                <div className={`w-full flex-col  gap-2 flex items-center font-normal my-0.5 `}>
                                    <div className={`w-full gap-x-2 text-left flex items-center font-normal  px-2 `}>
                                        <p className='w-1/2  text-sm font-normal  text-gray-700'>Patient Seen By</p>
                                        <select name="PatientPronouns" value={''}
                                            className={` focus:bg-white border border-transparent focus:bg-white  w-1/2  rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500 `}>
                                            <option value={''}>~ Select ~</option>
                                            {/* <option key={item.value} value={item.Label}>{item.Label}</option> */}
                                            <option >test</option>
                                        </select>
                                    </div>
                                    <div className={`w-full gap-x-2 text-left flex items-center font-normal  px-2 `}>
                                        <p className='w-1/2  text-sm font-normal  text-gray-700'>Meeting Date</p>
                                        <select name="PatientPronouns" value={''}
                                            className={` focus:bg-white border border-transparent focus:bg-white  w-1/2  rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500 `}>
                                            <option value={''}>~ Select ~</option>
                                            {/* <option key={item.value} value={item.Label}>{item.Label}</option> */}
                                            <option >test</option>
                                        </select>
                                    </div>
                                    <div className={`w-full gap-x-2 text-left flex items-center font-normal  px-2 `}>
                                        <p className='w-1/2  text-sm font-normal  text-gray-700'>Meeting Date</p>
                                        <select name="PatientPronouns" value={''}
                                            className={` focus:bg-white border border-transparent focus:bg-white  w-1/2  rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500 `}>
                                            <option value={''}>~ Select ~</option>
                                            {/* <option key={item.value} value={item.Label}>{item.Label}</option> */}
                                            <option >test</option>
                                        </select>
                                    </div>
                                </div>



                                :
                                <div className="mb-4">
                                    <div className="flex flex-col w-full ">
                                        <div className={`w-full gap-x-2 flex items-center font-normal my-0.5 `}>

                                            <div className={`w-full gap-x-2 text-left flex items-center font-normal  px-2 `}>
                                                <p className='w-1/2  text-sm font-normal  text-gray-700'>Enter a Date Range</p>
                                                <select name="PatientPronouns" value={''}
                                                    className={` focus:bg-white border border-transparent focus:bg-white  w-1/2  rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500 `}>
                                                    <option value={''}>~ Select ~</option>
                                                    {/* <option key={item.value} value={item.Label}>{item.Label}</option> */}
                                                    <option >test</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>}

                            <div className="border py-2 rounded flex justify-end items-center  px-6">
                                <button onClick={() => setPopUpDailyReport(0)} className="bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded">Cancel</button>
                                <button
                                    //  onClick={() => { showAddNewlist == 1 }}
                                    type="submit" className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {showModal && (
                <PinModal
                    title="Saving Couple Details"
                    setShowModal={setShowModal}
                    successFunction={(e) => handleSubmit(e)}
                />
            )} */}
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DailyReport)
