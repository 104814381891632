import React, { useEffect, useState } from "react";
import { ApiCall_POST, ApiCall_GET } from "../../../Redux/Generic/action";
import { connect } from "react-redux";
import { useAppContext } from "../../../AppContextProvider ";
import PinModal from "../../Components/PinModal";
import EventEmitter from "../../../Common/EventEmitter";
import ServiceUrls from "../../../Common/ServiceUrls";

function AddAction({ ApiCall_POST, ApiCall_GET, setOpenNursingActionModal }) {
  const theme = useAppContext().themeDetails;
  const [formData, setFormData] = useState({
    priority: "0",
    duedate: new Date().toISOString().substr(0, 10),
    actionTypeId: "",
    actionDesc: "",
  });
  const [actionTypes, setActionTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [globalActionValue, setGlobalActionValue] = useState("all");

  useEffect(() => {
    getAllScreenTestMaster();
  }, []);

  const getAllScreenTestMaster = () => {
    ApiCall_GET(ServiceUrls.GetAllCycleActionType).then((payload) => {
      setActionTypes(payload);
      formData.actionTypeId = payload[0].actionTypeId;
    });
  };

  const handleChange = (e) => {
    const { name, value, type, selectedOptions } = e.target;
    // If the target is a select dropdown, handle the text-to-value mapping
    if (e.currentTarget.name == "actionTypeId") {
      const text = selectedOptions[0].text; // Get the text of the selected option
      const mappedValue = actionMap[text] || "all"; // Map to the corresponding value or default to "all"
      setGlobalActionValue(mappedValue); // Update the global variable with the mapped value
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Use the value of the select option directly
    }));
    // Validate form or perform other checks
    if (formData.actionDesc !== "") {
      setIsValid(true);
    }
  };

  const validateForm = () => {
    if (formData.actionDesc === "") {
      setIsValid(false);
      return;
    }
    setShowModal(true);
  };

  const handleSubmit = async (e) => {
    ApiCall_POST(ServiceUrls.SaveCycleAction, formData, true).then(function (
      payload
    ) {
      setOpenNursingActionModal(0);
      EventEmitter.emit("AddNurseAction", globalActionValue);
    });
  };

  const actionMap = {
    "Admin Action": "admin",
    "Nursing Action": "nursing",
    "Lab Action": "lab",
    "Action for Clinician": "clinician",
    "General Action": "all",
  };

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          onClick={() => setOpenNursingActionModal(0)}
          style={{ backdropFilter: "blur(7px)" }}
          className="fixed inset-0 transition-opacity filter"
          aria-hidden="true"
        >
          <div className="absolute inset-0 bg-gray-700 opacity-70  "></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl "
          aria-hidden="true"
        ></span>
        <div
          className={`sm:my-8   sm:max-w-lg sm:w-full filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative  `}>
            <svg
              className={
                " absolute top-2 right-2 cursor-pointer shadow  font-semibold  "
              }
              onClick={() => setOpenNursingActionModal(0)}
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="hover:shadow   "
                d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z"
                fill="#ed4545"
              />
            </svg>
            <h3
              className={
                theme.txt_color +
                " text-xl text-center mb-6 mt-2 leading-6 font-bold text-gray-900"
              }
              id="modal-headline"
            >
              Add a General Action
            </h3>
            <div
              className=" my-3 w-full border rounded-md shadow items-center bg-gray-100 p-4  rounded-lg"
              onMouseDown={(e) => e.stopPropagation()}
            >
              <div className="mb-4">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="priority"
                >
                  Priority
                </label>
                <select
                  name="priority"
                  value={formData.priority || ""}
                  onChange={(e) => handleChange(e)}
                  className={
                    " mt-1 w-full backdrop-opacity-50  items-center text-gray-700  rounded-md  p-2  text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 outline-none"
                  }
                >
                  <option value="0">Normal</option>
                  <option value="3">Medium</option>
                  <option value="2">High</option>
                  <option value="1">Critical</option>
                </select>
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="duedate"
                >
                  Due Date
                </label>
                <input
                  type="date"
                  name="duedate"
                  value={formData.duedate || ""}
                  onChange={(e) => handleChange(e)}
                  className={
                    " mt-1 w-full backdrop-opacity-50  items-center text-gray-700  rounded-md  p-2  text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 outline-none"
                  }
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="actionTypeId"
                >
                  Action Type
                </label>
                <select
                  name="actionTypeId"
                  value={formData.actionTypeId || ""}
                  onChange={(e) => handleChange(e)}
                  className={
                    " mt-1 w-full backdrop-opacity-50  items-center text-gray-700  rounded-md  p-2  text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 outline-none"
                  }
                >
                  <option value="" disabled>
                    Select an action type
                  </option>
                  {actionTypes.map((type) => (
                    <option key={type.actionTypeId} value={type.actionTypeId}>
                      {type.actionTypeName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="actionDesc"
                >
                  Action Notes / Required
                </label>
                <textarea
                  name="actionDesc"
                  value={formData.actionDesc || ""}
                  onChange={(e) => handleChange(e)}
                  rows="3"
                  className={`${isValid ? "border-gray-300" : "border-red-500"
                    } focus:bg-white border  focus:bg-white m-0 w-full  rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500`}
                  placeholder="Enter notes here..."
                ></textarea>
              </div>
              <div className="border py-2 rounded flex justify-end items-center  px-6">
                <button
                  className="bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded"
                  onClick={() => setOpenNursingActionModal(0)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded"
                  onClick={validateForm}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <PinModal
          title="Saving Couple Details"
          setShowModal={setShowModal}
          successFunction={(e) => handleSubmit(e)}
        />
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = { ApiCall_POST, ApiCall_GET };

export default connect(mapStateToProps, mapDispatchToProps)(AddAction);
