import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ApiCall_GET, ApiCall_POST } from '../../../../../Redux/Generic/action';
import { useAppContext } from '../../../../../AppContextProvider ';
import { Screening } from './Screening';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { replaceEmptyObjectsWithNull, getCorrectDate } from '../../../../../common';
import Swal from 'sweetalert2';

export const ScreeningList = ({ ApiCall_GET, ApiCall_POST, coupleId, patientState }) => {
    const [screenListTestData, setScreenListTestData] = useState([]);
    const theme = useAppContext().themeDetails;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cplTestData, setcplTestData] = useState([]);

    useEffect(() => {
        getAllScreenTestByCoupleId(coupleId);
    }, []);

    const handleDataFromScreening = (data) => {

        if (data)
            getAllScreenTestByCoupleId(coupleId);
    };

    const getAllScreenTestByCoupleId = () => {
        ApiCall_GET('Screening/GetScreenTestByCoupleId/' + coupleId, true).then(function (payload) {
            setScreenListTestData(replaceEmptyObjectsWithNull(payload))
        });
    }

    const handleOpenModal = (tab, selectedData) => {
        setIsModalOpen(true);
        let personId = tab === 'Patient'
            ? patientState?.couple_File_People.PatientId
            : patientState?.couple_File_People.PartnerId;
        setcplTestData({ personId, coupleId, selectedData });
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleDelete = (screentestid) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'border p-2 m-1 border-red-200 rounded-md hover:bg-red-200 hover:text-red-900 text-red-500',
                cancelButton: 'border p-2 m-1 border-green-200 rounded-md hover:bg-green-200 hover:text-green-900 text-green-500'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            text: "Are you sure?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                ApiCall_GET('Screening/Delete/' + screentestid, null, true)
                    .then(function (payload) {
                        getAllScreenTestByCoupleId(coupleId);
                    });
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Cancelled',
                    'Reference Item is not updated',
                    'error'
                )
            }
        })
    }

    const groupedData = Array.isArray(screenListTestData)
        ? screenListTestData.reduce(
            (acc, item) => {
                const role = item.person_role === 'Patient' ? 'Patient' : 'Partner';
                acc[role].push(item);
                return acc;
            },
            { Patient: [], Partner: [] }
        )
        : { Patient: [], Partner: [] };

    return (
        <>
            <div className="flex gap-x-2">
                {/* Patient and Partner Columns */}
                {['Patient', 'Partner'].map((role) => (
                    <div key={role} className={`${theme.thumb} ${theme.track} w-1/2 h-[84vh] bg-white overflow-y-auto scrollbar scroll scrollbar  scrollbar-thin`}>
                        {/* Header */}
                        <div className={`w-full sticky top-0 bg-white`}>
                            <div className="pl-2 py-2 rounded flex items-center justify-between">
                                <label
                                    className={`mr-4 font-bold cursor-pointer theme.txt_color border-b-[3px] border-b-amber-500`}
                                >
                                    {role} Screening Tests
                                </label>
                                <button onClick={() => handleOpenModal(role, null)} className="flex items-center cursor-pointer justify-center text-green-800 bg-white border border-green-600 focus:outline-none hover:text-black hover:bg-green-600 hover:text-white focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2">
                                    <FontAwesomeIcon icon={faPlus} />
                                    <label className="pl-2 cursor-pointer">Add Test</label>
                                </button>
                            </div>

                            {/* Table Header */}
                            <div className={`w-full flex py-1  text-white rounded ${theme.navbar}`}>
                                <div
                                    style={{ width: "10%" }}
                                    className={"flex item-center justify-center py-1"} >
                                    <p className="text-sm text-center font-bold">#</p>
                                </div>
                                <div style={{ width: "20%" }} className={"py-1"}>
                                    <p className="text-sm text-center font-bold">Date</p>
                                </div>
                                <div style={{ width: "20%" }} className={"py-1"}>
                                    <p className="text-sm text-center font-bold">Test Type</p>
                                </div>
                                <div style={{ width: "20%" }} className={"py-1"}>
                                    <p className="text-sm text-center font-bold">Result</p>
                                </div>
                                <div style={{ width: "15%" }} className={"py-1"}>
                                    <p className="text-sm text-center font-bold">Level</p>
                                </div>
                                <div style={{ width: "15%" }} className={"py-1 "}>
                                    <p className="text-sm text-center font-bold">Action</p>
                                </div>
                            </div>
                        </div>

                        {/* Table Rows */}
                        {groupedData[role]?.length > 0 ? (
                            groupedData[role]?.map((item, index) => (
                                <div key={index} className={` ${theme.hoverBg} w-full flex mb-0.5 py-1 cursor-pointer rounded shadow border items-center`}>
                                    <div style={{ width: "10%" }} className={"flex item-center justify-center py-1"} >
                                        <p className="text-sm text-center font-medium text-gray-600">{index + 1 || 'N/A'}</p>
                                    </div>
                                    <div style={{ width: "20%" }} className={"py-1"}>
                                        <p className="text-sm text-center font-medium text-gray-600">{item.date || ''}</p>
                                    </div>
                                    <div style={{ width: "20%" }} className={"py-1"}>
                                        <p className="text-sm text-center font-medium text-gray-600">{item.testname || ''}</p>
                                    </div>
                                    <div style={{ width: "20%" }} className={"py-1"}>
                                        <p className="text-sm text-center  font-medium text-gray-600">
                                            {
                                                item.result === 'Positive' ? (
                                                    <span className="p-2 py-0.5 rounded bg-red-600 text-white">
                                                        {`+ ${item.result}`}
                                                    </span>
                                                ) : (
                                                    item.result || ' '
                                                )
                                            }
                                        </p>
                                    </div>
                                    <div style={{ width: "15%" }} className={"py-1"}>
                                        <p className="text-sm text-center font-medium text-gray-600">{item?.level || ''}</p>
                                    </div>
                                    <div style={{ width: "15%" }} className={"py-1 flex  items-center justify-center gap-x-4 "}>
                                        <svg className={" cursor-pointer hover:scale-125 "} onClick={() => handleOpenModal(role, item)} width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.11164 13.601H6.74756C6.86041 13.6016 6.97229 13.5802 7.07677 13.5379C7.18125 13.4956 7.27627 13.4333 7.3564 13.3546L13.2905 7.46576L15.7259 5.10345C15.8062 5.02445 15.87 4.93047 15.9136 4.82692C15.9571 4.72337 15.9795 4.6123 15.9795 4.50012C15.9795 4.38795 15.9571 4.27688 15.9136 4.17333C15.87 4.06978 15.8062 3.97579 15.7259 3.8968L12.0899 0.251356C12.0102 0.17171 11.9154 0.108493 11.8109 0.0653519C11.7064 0.0222111 11.5943 0 11.4811 0C11.3679 0 11.2558 0.0222111 11.1513 0.0653519C11.0468 0.108493 10.952 0.17171 10.8723 0.251356L8.45403 2.65616L2.5028 8.54495C2.42332 8.62435 2.36044 8.71852 2.31777 8.82205C2.27509 8.92558 2.25346 9.03644 2.25412 9.14828V12.7512C2.25412 12.9766 2.34446 13.1927 2.50528 13.3521C2.6661 13.5115 2.88421 13.601 3.11164 13.601ZM11.4811 2.05283L13.9079 4.45764L12.6902 5.66429L10.2634 3.25948L11.4811 2.05283ZM3.96917 9.49668L9.0543 4.45764L11.4811 6.86244L6.39597 11.9015H3.96917V9.49668ZM16.8321 15.3005H1.39659C1.16916 15.3005 0.951044 15.39 0.790226 15.5494C0.629409 15.7087 0.539063 15.9249 0.539062 16.1502C0.539063 16.3756 0.629409 16.5918 0.790226 16.7511C0.951044 16.9105 1.16916 17 1.39659 17H16.8321C17.0595 17 17.2776 16.9105 17.4384 16.7511C17.5993 16.5918 17.6896 16.3756 17.6896 16.1502C17.6896 15.9249 17.5993 15.7087 17.4384 15.5494C17.2776 15.39 17.0595 15.3005 16.8321 15.3005Z" fill="#3A7555" />
                                        </svg>
                                        <svg className={" cursor-pointer hover:scale-125 "} onClick={() => handleDelete(item.screentestid)} width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.8275 3.95898C17.0393 3.95898 17.2425 4.04239 17.3923 4.19086C17.542 4.33932 17.6262 4.54069 17.6262 4.75065C17.6262 4.96061 17.542 5.16198 17.3923 5.31044C17.2425 5.45891 17.0393 5.54232 16.8275 5.54232H16.0288L16.0264 5.59853L15.2813 15.9464C15.2526 16.3459 15.0723 16.7197 14.7766 16.9926C14.4809 17.2656 14.0919 17.4173 13.6879 17.4173H7.18748C6.78346 17.4173 6.39444 17.2656 6.09879 16.9926C5.80313 16.7197 5.6228 16.3459 5.59412 15.9464L4.84895 5.59932C4.84774 5.58034 4.84721 5.56133 4.84735 5.54232H4.04868C3.83685 5.54232 3.63371 5.45891 3.48393 5.31044C3.33415 5.16198 3.25 4.96061 3.25 4.75065C3.25 4.54069 3.33415 4.33932 3.48393 4.19086C3.63371 4.04239 3.83685 3.95898 4.04868 3.95898H16.8275ZM12.0354 1.58398C12.2473 1.58398 12.4504 1.66739 12.6002 1.81586C12.75 1.96432 12.8341 2.16569 12.8341 2.37565C12.8341 2.58561 12.75 2.78698 12.6002 2.93544C12.4504 3.08391 12.2473 3.16732 12.0354 3.16732H8.84074C8.62892 3.16732 8.42577 3.08391 8.27599 2.93544C8.12621 2.78698 8.04206 2.58561 8.04206 2.37565C8.04206 2.16569 8.12621 1.96432 8.27599 1.81586C8.42577 1.66739 8.62892 1.58398 8.84074 1.58398H12.0354Z" fill="#FE1010" fillOpacity="0.76" />
                                        </svg>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="flex justify-center   shadow p-2  m-2 mr-0 text-[#664d03] border border-red-300 rounded-lg bg-red-100  dark:text-yellow-300">
                                <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#9b1c1c" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                </svg>
                                <span className="sr-only">Info</span>
                                <div className="ms-3 text-sm font-semibold text-red-800">
                                    <i><b> ~ No data available ~</b></i>
                                </div>
                            </div>
                        )}
                    </div>
                ))}

                {isModalOpen && <Screening onSendData={handleDataFromScreening} cplTestData={cplTestData} setIsModalOpen={setIsModalOpen} ApiCall_GET={ApiCall_GET} ApiCall_POST={ApiCall_POST}></Screening>}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { ApiCall_GET, ApiCall_POST };

export default connect(mapStateToProps, mapDispatchToProps)(ScreeningList);